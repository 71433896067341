import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp, CwtSchema, CwtSubCrudContract } from "@cawita/core-front/api";
import { Address } from "../common";
import { Company } from "../company";
import { Customer } from "./customer.model";

@CwtSchema()
export class CustomerSiteInfo extends Address {
    @CwtProp() phone: string;
    @CwtProp() fax: string;
}

@CwtModel({ name: 'customer-site' })
@CwtSubCrudContract({ parent: Customer, parentPath: 'company/:companyId/customer/', subPath: 'site' })
@CwtCrudContract({ path: '/company/:companyId/site' })
export class CustomerSite extends CwtApiEntity {
    @CwtProp({ ref: Company }) company: Company;
    @CwtProp() name: string;
    @CwtProp({ ref: Customer }) customer: Customer;
    @CwtProp() isMain: boolean;
    @CwtProp({ ref: CustomerSiteInfo }) info: CustomerSiteInfo;
}
