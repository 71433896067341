import { getId, isset, sameId } from "@cawita/core-front";
import { arrayUniqueValue, createDictionary, findByAddress } from '@hints/utils/data';
import { sameIds } from "@hints/utils/mongo";
import { QuoteItem } from "@shared/models";

export enum SelectionStatus {
    Unavailable = 'unavailable',
    Available = 'available',
    Selected = 'selected'
}

export type SelectionInfo = {
    option: QuoteItem;
    editable: boolean;
    status: SelectionStatus;
};

export type OptionDictionaryValue = {
    requesteds: QuoteItem[];
    requesters: QuoteItem[];
    directRequested: QuoteItem[];
    itemInfos: SelectionInfo[]
}

export function getOptionsDictionary(quoteItems: QuoteItem[]) {
    if (!quoteItems?.length) return {};
    const options = quoteItems?.filter(qI => qI?.optional?.isOption);
    if (!options?.length) return {};
    const dictionary = createDictionary(quoteItems, (i) => getId(i));
    const optionsDico = createDictionary<QuoteItem, OptionDictionaryValue>(options, i => getId(i), (_, i) => {
        const directRequested = _getDirectRequestedOptions(dictionary, i);
        const requesteds = _getRequestedOptions(dictionary, i);
        const requesters = _getRequesterOptions(dictionary, i);
        return {
            directRequested,
            requesteds,
            requesters,
            itemInfos: options.filter(item => !sameIds(item, i)).map<SelectionInfo>((option) => {
                if (_isInArray(directRequested, option)) return { option, editable: true, status: SelectionStatus.Selected };
                if (_isInArray(requesteds, option)) return { option, editable: false, status: SelectionStatus.Unavailable };
                if (_isInArray(requesters, option)) return { option, editable: false, status: SelectionStatus.Unavailable };
                return { option, editable: true, status: SelectionStatus.Available };
            })
        };
    }, () => null);
    return optionsDico;
}

function _isInArray(arr: QuoteItem[], qi: QuoteItem) { return arr.some(q => sameIds(qi, q)); }

/**
 * Gets all the quoteItems requested by the param
 * @param dictionary 
 * @param quoteItem
 * @returns 
 */
function _getDirectRequestedOptions(dictionary: Record<string, QuoteItem>, quoteItem: QuoteItem) {
    const requestedItems = findByAddress<QuoteItem, QuoteItem[]>(quoteItem, 'optional.requestedItems');
    const fullRequestedItems = requestedItems.map(i => dictionary[getId(i)]).filter(isset);
    return arrayUniqueValue(fullRequestedItems, sameIds);
}


/**
 * Recursively gets all the quoteItems requested by the param
 * @param dictionary 
 * @param quoteItem
 * @returns 
 */
function _getRequestedOptions(dictionary: Record<string, QuoteItem>, quoteItem: QuoteItem) {
    const fullRequestedItems = _getDirectRequestedOptions(dictionary, quoteItem);
    const dependencies = fullRequestedItems.map(i => _getRequestedOptions(dictionary, i));
    return arrayUniqueValue(fullRequestedItems.concat(...dependencies), sameIds);
}

/**
 * Recursively gets all the quoteItems that requests the param
 * @param dictionary 
 * @param quoteItem 
 * @returns 
 */
function _getRequesterOptions(dictionary: Record<string, QuoteItem>, quoteItem: QuoteItem) {
    const fullRequesterItems = Object.values(dictionary).filter(item => {
        const requestedItems = findByAddress(item, 'optional.requestedItems') || [];
        return requestedItems.map(getId).includes(getId(quoteItem));
    });
    const dependencies = fullRequesterItems.map(i => _getRequesterOptions(dictionary, i));
    return arrayUniqueValue(fullRequesterItems.concat(...dependencies), sameIds);
}