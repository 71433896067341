import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CustomerStore } from './customer.store';
import { getId, isset } from '@cawita/core-front';

@Injectable()
export class CustomerTokenInterceptor implements HttpInterceptor {
    constructor(private company: CustomerStore) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const { token, trackingEnabled } = this.company.getValue();
        const isCustomerTokenRequest = req.url.includes(':customerToken');
        if (!isset(token) || !isCustomerTokenRequest) return next.handle(req);
        const url = req.url.replace(':customerToken', token);
        if (trackingEnabled) return next.handle(req.clone({ url }));
        return next.handle(req.clone({ url: url, params: req.params.set('doNotTrack', true) }));
    }
}