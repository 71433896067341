import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomFieldInput } from '../custom-field-input.directive';
import { SharedFormModule } from '@shared/modules';

@Component({
  selector: 'cwt-custom-field-enum-input',
  standalone: true,
  imports: [
    CommonModule,
    SharedFormModule,
  ],
  templateUrl: './custom-field-enum-input.component.html',
  styleUrls: ['./custom-field-enum-input.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomFieldEnumInputComponent extends CustomFieldInput<string> {

}
