import { Accounting } from "@server-shared";
import { Quote, QuoteItem } from "@shared/models";

export function getUpdatedQuoteOnQuoteItemUpdate(quote: Quote, itemBefore: QuoteItem, itemAfter: QuoteItem) {
    quote = new Quote(quote);
    removeQuoteItemValue(quote, itemBefore);
    addQuoteItemValue(quote, itemAfter);
    return quote;
}

export function getUpdatedQuoteOnQuoteItemCreated(quote: Quote, itemAfter: QuoteItem) {
    quote = new Quote(quote);
    addQuoteItemValue(quote, itemAfter);
    return quote;
}

export function getUpdatedQuoteOnQuoteItemDeleted(quote: Quote, itemBefore: QuoteItem) {
    quote = new Quote(quote);
    removeQuoteItemValue(quote, itemBefore);
    return quote;
}


function removeQuoteItemValue(quote: Quote, quoteItem: QuoteItem) {
    const { amount, vatAmount } = Accounting.getTotalsForBillingItem(quoteItem);
    if (isQuoteItemSelectedItem(quoteItem)) {
        quote.amount = (quote.amount ?? 0) - (amount ?? 0);
        quote.vatAmount = (quote.vatAmount ?? 0) - (vatAmount ?? 0);
    } else {
        quote.optionsAmount = (quote.optionsAmount ?? 0) - (amount ?? 0);
        quote.optionsVatAmount = (quote.optionsVatAmount ?? 0) - (vatAmount ?? 0);
    }
}

function addQuoteItemValue(quote: Quote, quoteItem: QuoteItem) {
    const { amount, vatAmount } = Accounting.getTotalsForBillingItem(quoteItem);
    if (isQuoteItemSelectedItem(quoteItem)) {
        quote.amount = (quote.amount ?? 0) + (amount ?? 0);
        quote.vatAmount = (quote.vatAmount ?? 0) + (vatAmount ?? 0);
    } else {
        quote.optionsAmount = (quote.optionsAmount ?? 0) + (amount ?? 0);
        quote.optionsVatAmount = (quote.optionsVatAmount ?? 0) + (vatAmount ?? 0);
    }
}

function isQuoteItemSelectedItem(quoteItem: QuoteItem) {
    return !quoteItem.optional?.isOption || quoteItem?.optional?.isSelected
}