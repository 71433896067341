import { NgModule } from '@angular/core';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { SharedCommonModule } from '../common/common.module';
import { PageHeaderActionsDirective, PageHeaderAvatarDirective, PageHeaderContentDirective, PageHeaderSubtitleDirective, PageHeaderTagsDirective, PageHeaderTitleDirective } from './page-header/page-header-cells.directive';
import { PageHeaderComponent } from './page-header/page-header.component';
import { PageFooterComponent } from './page-footer/page-footer.component';
import { TabsModule } from '@shared/widgets/features/common/tabs/tabs.module';
import { NzTagModule } from 'ng-zorro-antd/tag';

@NgModule({
  imports: [
    SharedCommonModule,
    NzLayoutModule,
    NzMenuModule,
    NzBreadCrumbModule,
    NzPageHeaderModule,
  ],
  exports: [
    SharedCommonModule,
    NzLayoutModule,
    NzMenuModule,
    NzTabsModule,
    NzTagModule,
    TabsModule,
    NzBreadCrumbModule,
    NzPageHeaderModule,
    PageHeaderComponent,
    PageFooterComponent,
    PageHeaderTitleDirective,
    PageHeaderSubtitleDirective,
    PageHeaderActionsDirective,
    PageHeaderTagsDirective,
    PageHeaderContentDirective,
    PageHeaderAvatarDirective,
  ],
  declarations: [
    PageHeaderComponent,
    PageFooterComponent,
    PageHeaderTitleDirective,
    PageHeaderSubtitleDirective,
    PageHeaderActionsDirective,
    PageHeaderTagsDirective,
    PageHeaderContentDirective,
    PageHeaderAvatarDirective,
  ],
  providers: [],
})
export class SharedLayoutModule { }
