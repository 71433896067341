import { Injectable, inject } from '@angular/core';
import { Billing } from '@shared/models';
import { ConstraintOptions, MailConstraintHandler, MailParams } from '@shared/modules/mailing';
import { InvoiceApi } from '@shared/services/apis/invoice.api';
import { QuoteApi } from '@shared/services/apis/quote.api';
import { map, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class IsVisibleConstraintHandler extends MailConstraintHandler {
    protected invoiceApi = inject(InvoiceApi);
    protected quoteApi = inject(QuoteApi);

    public check(params: MailParams, options: ConstraintOptions) {
        if (params.invoice) return !!params.invoice.isVisibleToCustomer;
        if (params.quote) return !!params.quote.isVisibleToCustomer;
        return false;
    }
    public resolve(params: MailParams, options: ConstraintOptions) {
        if (params.invoice) return this.invoiceApi.setVisibleToCustomer(params.invoice, true).pipe(tap(r => this._setIVTC(params.invoice, r)), map(r => r.isVisibleToCustomer));
        if (params.quote) return this.quoteApi.setVisibleToCustomer(params.quote, true).pipe(tap(r => this._setIVTC(params.quote, r)), map(r => r.isVisibleToCustomer));
        return false;
    }

    private _setIVTC = (b1: Billing, b2: Billing) => {
        b1.isVisibleToCustomer = !!b2.isVisibleToCustomer;
    }
}