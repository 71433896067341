import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs';

export function initLocation() {
    return (loc: LocationService) => () => loc.init();
}

@Injectable({ providedIn: 'root' })
export class LocationService {
    private _currentUrl: any;
    private _previousUrl: any;

    constructor(
        private location: Location,
        private router: Router
    ) { }

    public init() { this._listenForRouteChanges(); }

    private _listenForRouteChanges() {
        this.router.events.pipe(
            filter(e => e instanceof RoutesRecognized),
            pairwise(),
        ).subscribe(([previous, current]: [RoutesRecognized, RoutesRecognized]) => {
            this._currentUrl = current.urlAfterRedirects;
            this._previousUrl = previous.urlAfterRedirects;
        });
    }

    public goToPrevious(fallback: string[] = ['/']) {
        if (this._previousUrl) this.location.back();
        else this.router.navigate(fallback);
    }
}