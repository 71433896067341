import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { hasSomeChanges, isset } from '@cawita/core-front';
import { Action, ActionStatus } from '@shared/models';
import { isBefore, isSameDay } from 'date-fns';

@Directive({
  selector: '[cwtHighlightLateAction]',
  host: { '[class.text-danger]': 'highlight' },
  standalone: true
})
export class HighlightLateActionDirective implements OnChanges {
  private _now = new Date();

  @Input('cwtHighlightLateAction') action: Action;

  public highlight = false;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (hasSomeChanges(changes, ['action'], true)) this.highlight = this._shouldHighlight();
  }

  private _shouldHighlight(): boolean {
    if (this?.action?.status !== ActionStatus.Planned) return false;
    const date = this.action?.actionDate ?? null;
    if (!isset(date)) return false;
    return !isSameDay(date, this._now) && isBefore(date, this._now);
  }

}
