const { isset } = require('@hints/utils/data');
const { isNumber, isString } = require('@hints/utils/types');
const { round_to_decimal_place, parse_formatted_number } = require('./intl-utils');

/**
 * Decimal constant to round values
 */
const DECIMAL_PLACES = 2;

/**
 * Rounds a currency value
 * @param {number | null | undefined} value value to round
 * @return {number} rounded value
 */
function round_currency(value) {
    if (!isset(value)) return 0;
    if (!isNumber(value)) return 0;
    return round_to_decimal_place(value, DECIMAL_PLACES);
}

/**
 * Formats a currency value
 * @param {number | null | undefined} value value to format
 * @param {string} locale locale in which to format the value (defaults to fr-FR)
 * @param {string} currency currency in which to format the value (defaults to EUR)
 * @return {string} formatted currency
 */
function format_currency(value, locale = 'fr-FR', currency = 'EUR') {
    const formatter = create_currency_formatter(locale, currency);
    return formatter.format(round_currency(value));
}

function parse_currency(value, locale = 'fr-FR', currency = 'EUR') {
    if (!isset(value)) return undefined;
    if (isNumber(value)) return value;
    if (!isString(value)) return undefined;
    const formatter = create_currency_formatter(locale, currency);
    const parsed = parse_formatted_number(formatter, value, 1111.1111, {
        group: '',
        currency: '',
        minusSign: '-',
        plusSign: '',
        unit: '',
        symbol: '',
        percentSign: '',
        decimal: '.'
    });

    return round_currency(parsed);
}

module.exports = {
    round: round_currency,
    format: format_currency,
    parse: parse_currency
};

function create_currency_formatter(locale = 'fr-FR', currency = 'EUR') {
    return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
        maximumFractionDigits: DECIMAL_PLACES,
        minimumFractionDigits: DECIMAL_PLACES,
        minimumIntegerDigits: 1,
        signDisplay: 'auto',
    });
}