import { CwtApiEntity, CwtApiSchema, CwtModel, CwtProp, CwtSchema, CwtSubCrudContract } from "@cawita/core-front/api";
import { Invoice, Quote, QuoteItem } from "../billing";
import { Email, User } from "../common";
import { Company } from "../company";
import { CustomerAccessToken, Customer } from "../customer";

@CwtSchema()
export class ActivityLogIdentity extends CwtApiSchema {
    @CwtProp({ ref: CustomerAccessToken }) accessToken: CustomerAccessToken;
    @CwtProp({ ref: User }) user: User;
}

@CwtSchema()
export class ActivityLogOrigin extends CwtApiSchema {
    @CwtProp({ ref: Date }) date: Date;
    @CwtProp() ip: string;
    @CwtProp({ ref: ActivityLogIdentity }) originator: ActivityLogIdentity;
}

@CwtModel({ name: 'activity-log' })
@CwtSubCrudContract({ parent: Quote, parentPath: 'company/:companyId/quote', subPath: 'activity-log' })
@CwtSubCrudContract({ parent: Invoice, parentPath: 'company/:companyId/invoice', subPath: 'activity-log' })
@CwtSubCrudContract({ parent: QuoteItem, parentPath: 'company/:companyId/quote-item', subPath: 'activity-log' })
// @CwtSubCrudContract({ parent: Payment, parentPath: 'company/:companyId/payment', subPath: 'activity-log' })
// @CwtSubCrudContract({ parent: CommercialOffer, parentPath: 'company/:companyId/commercial-offer', subPath: 'activity-log' })
export class ActivityLog extends CwtApiEntity {
    @CwtProp({ ref: Company }) company: Company;
    @CwtProp({ ref: Customer }) customer: Customer;

    @CwtProp({ ref: Quote }) quote: Quote;
    @CwtProp({ ref: Invoice }) invoice: Invoice;
    @CwtProp({ ref: QuoteItem }) quoteItem: QuoteItem;
    // @CwtProp({ ref: Payment }) invoice: Payment;
    // @CwtProp({ ref: CommercialOffer }) commercial: CommercialOffer;

    @CwtProp({ ref: Email }) email: Email;
    @CwtProp({ ref: Date }) date: Date;
    @CwtProp() type: string;
    @CwtProp({ ref: ActivityLogIdentity }) doer: ActivityLogIdentity;
    @CwtProp() additional: any;
}