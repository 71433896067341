import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CWT_AUTH_LISTENER } from '@cawita/core-front/auth';
import { IsLoggedUserDirective } from './is-logged-user.directive';
import { IsNotLoggedUserDirective } from './is-not-logged-user.directive';
import { LoggedUserDirective } from './logged-user.directive';
import { UserAuthListener } from './user-auth-listener';
import { UserIdInterceptor } from './user-id-interceptor';
import { UserLockedDirective } from './user-locked.directive';

@NgModule({
    imports: [],
    exports: [
        LoggedUserDirective,
        UserLockedDirective,
        IsNotLoggedUserDirective,
        IsLoggedUserDirective,
    ],
    declarations: [
        LoggedUserDirective,
        UserLockedDirective,
        IsLoggedUserDirective,
        IsNotLoggedUserDirective
    ],
    providers: [],
})
export class SharedUserModule {
    static forRoot(): ModuleWithProviders<SharedUserModule> {
        return {
            ngModule: SharedUserModule,
            providers: [
                { provide: HTTP_INTERCEPTORS, useClass: UserIdInterceptor, multi: true },
                { provide: CWT_AUTH_LISTENER, useClass: UserAuthListener, multi: true }
            ]
        };
    }
}
