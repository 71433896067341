import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CompanyStore } from './company.store';
import { getId, isset } from '@cawita/core-front';

@Injectable()
export class CompanyIdInterceptor implements HttpInterceptor {
    constructor(private company: CompanyStore) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const { activeCompany } = this.company.getValue();
        const isCompanyRequest = req.url.includes(':companyId');

        if (!isset(activeCompany) || !isCompanyRequest) return next.handle(req);
        return next.handle(req.clone({
            url: req.url.replace(':companyId', getId(activeCompany))
        }));
    }
}