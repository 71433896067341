import { CwtApiSchema, CwtCrudContract, CwtModel, CwtProp, CwtSchema } from "@cawita/core-front/api";
import { UploadFile, RecurringConfig } from "../common";
import { Company } from "../company";
import { CustomFieldValues } from "../custom-field";
import { Purchase } from "./purchase.model";
import { Supplier } from "./supplier.model";

@CwtSchema()
export class RecurringPurchaseSchema extends CwtApiSchema {
    @CwtProp() name: string;
    @CwtProp() reason: string;
    @CwtProp() amount: number;
    @CwtProp() vatRate: number;
    @CwtProp({ ref: Supplier }) supplier: Supplier;
    @CwtProp({ ref: UploadFile }) file: UploadFile;
    @CwtProp() customFieldValues: CustomFieldValues;
}

@CwtModel({ name: 'recurring-purchase' })
@CwtCrudContract({ path: 'company/:companyId/recurring-purchase', searchMapper: s => ({ 'purchaseSchema.name': { regexp: s } }) })
export class RecurringPurchase extends RecurringConfig {
    @CwtProp({ ref: Company }) company: Company;
    @CwtProp({ ref: RecurringPurchaseSchema }) purchaseSchema: RecurringPurchaseSchema;
    @CwtProp({ ref: Purchase }) generatedInvoices: Purchase[];
}