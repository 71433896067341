import { CwtApiEntity, CwtApiSchema, CwtCrudContract, CwtModel, CwtProp, CwtSchema } from "@cawita/core-front/api";
import { Address, LegalForm, UploadFile, User } from "../common";
import { Company } from "../company";
import { CustomFieldValues } from "../custom-field";
import type { CustomerSite } from "./customer-site.model";

export function CustomerSearchMapper(search: string) {
    return ({
        or: [
            { customerRef: { regexp: search } },
            { name: { regexp: search } },
            { siren: { regexp: search } }
        ]
    })
}

export enum CustomerStatus {
    Prospect = 'prospect',
    Client = 'client'
}

export enum CustomerStatusColor {
    prospect = 'orange',
    client = 'green'
}

@CwtSchema()
export class ForcedStatus extends CwtApiSchema {
    @CwtProp() isActive: boolean;
    @CwtProp({ ref: Date }) date: Date;
    @CwtProp({ ref: User }) user: User;
}

@CwtModel({ name: 'customer' })
@CwtCrudContract({ path: '/company/:companyId/customer', searchMapper: CustomerSearchMapper })
export class Customer extends CwtApiEntity {
    @CwtProp() name: string;
    @CwtProp() siren: string;
    @CwtProp() artisCode: string;
    @CwtProp() status: CustomerStatus;
    @CwtProp({ ref: ForcedStatus }) forcedStatus: ForcedStatus;
    @CwtProp({ ref: User }) owners: User[];
    @CwtProp({ ref: Company }) company: Company;
    @CwtProp() customerRef: string;
    @CwtProp({ ref: UploadFile }) logo: UploadFile;
    @CwtProp({ ref: Address }) address: Address;
    @CwtProp() legalForm: LegalForm;
    @CwtProp() vatNumber: string;
    @CwtProp() observation: string;
    @CwtProp() customFieldValues: CustomFieldValues;

    @CwtProp({ ref: 'customer-site' }) sites: CustomerSite[];
}