import { Directive, Host, Input, OnDestroy } from "@angular/core";
import { Params, RouterLink } from "@angular/router";
import { CwtStopPropagationDirective } from "@cawita/core-front";
import { Subscription } from "rxjs";
import { BillingRouter } from "../services";

@Directive({
  selector: '[cwtBillingRouterLink]',
  host: { class: 'clickable' },
  hostDirectives: [RouterLink, CwtStopPropagationDirective]
})
export class BillingRouterLinkDirective implements OnDestroy {
  private subscription?: Subscription;

  constructor(
    @Host() private _routerLink: RouterLink,
    private svc: BillingRouter
  ) { }

  @Input('cwtBillingRouterLink')
  set routerLink(commands: any[] | string | null | undefined) {
    this.subscription?.unsubscribe();
    this.subscription = null;
    this._watchCompanyAndUpdateRouterLink(commands)
  }

  @Input('queryParams')
  set queryParams(params: Params) { this._routerLink.queryParams = params; }

  private _watchCompanyAndUpdateRouterLink(commands?: string | string[]) {
    this.svc.createBillingRouterWatcher(commands, (path) => {
      this._routerLink.routerLink = path;
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}