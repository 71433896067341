import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { getId } from '@cawita/core-front';
import { CwtAuthStore } from '@cawita/core-front/auth';
import { firstValueFrom } from 'rxjs';
import { CompanyStore } from '../company.store';

export function CompanyRedirectGuard(): CanActivateFn {
    return async (route, state) => {
        const router = inject(Router);
        const auth = inject(CwtAuthStore);
        if (!auth.isLoggedIn) return router.createUrlTree(['/']);

        const companyStore = inject(CompanyStore);
        const { preferred } = await firstValueFrom(companyStore.stableState$);
        if (preferred) return router.createUrlTree(['/company', getId(preferred)]);
        return router.createUrlTree(['/company', 'new']);
    }
}