import { ChangeDetectorRef, inject } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot } from "@angular/router";
import { asArray, isset } from "@cawita/core-front";
import { findByAddress, isEmpty, isEmptyOrNotSet } from "@hints/utils/data";
import { Subscription } from "rxjs";

export function getRouteData<T>(path: string): T {
    const route = inject(ActivatedRoute);
    return findByAddress(route.snapshot.data, path);
}

export function getRouteQuery<T>(path: string): T {
    const route = inject(ActivatedRoute);
    return findByAddress(route.snapshot.queryParams, path);
}

export function watchRouteData<T>(path: string, callback: (value: T) => void): Subscription {
    const route = inject(ActivatedRoute);
    const cdRef = inject(ChangeDetectorRef);
    return route.data.subscribe((data) => {
        callback(findByAddress(data, path));
        cdRef.markForCheck();
    });
}

export function watchRouteQuery<T>(path: string, callback: (value: T) => void): Subscription {
    const route = inject(ActivatedRoute);
    const cdRef = inject(ChangeDetectorRef);
    return route.queryParams.subscribe((queryParams) => {
        callback(findByAddress(queryParams, path));
        cdRef.markForCheck();
    });
}

export function getMergedRouteData(route: ActivatedRouteSnapshot) {
    const data = route.data ?? {};
    if (!route.firstChild) return data;
    const childData = getMergedRouteData(route.firstChild);
    return { ...data, ...childData };
}

export function formatCommands(commands: string | string[]) {
    if (!isset(commands)) return null;
    const saneCommands = asArray(commands).map(_formatCommand).filter(s => !isEmpty(s));
    if (isEmpty(saneCommands)) return null;
    return saneCommands;
}

function _formatCommand(command: any) {
    if (isEmptyOrNotSet(command)) return '';
    const strCommand = String(command);
    if (strCommand.startsWith('/')) return strCommand.replace('/', '');
    return strCommand;
}