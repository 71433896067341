import { ChangeDetectorRef, inject } from "@angular/core";
import { asArray } from "@cawita/core-front";
import { distinctInArray } from "@hints/utils/data";
import { sameIds } from "@hints/utils/mongo";

export class ResourceTracker<T> {
    protected ref = inject(ChangeDetectorRef);

    private _known: T[] = [];
    private _created: T[] = [];
    private _all: T[] = [];

    public get created(): T[] { return this._known ?? []; }
    public get known(): T[] { return this._known ?? []; }
    public get resources(): T[] { return this._all ?? []; }

    constructor() { }

    public add(items?: T | T[]) {
        this._created = distinctInArray([...asArray(items) ?? [], ...this._created], sameIds);
        this._all = distinctInArray(this._created.concat(this._known ?? []), sameIds);
        this.ref.markForCheck();
    }

    public init(items?: T | T[]) {
        this._known = asArray(items) ?? [];
        this._all = distinctInArray(this._created.concat(this._known ?? []), sameIds);
        this.ref.markForCheck();
    }

    public clear() {
        this._known = [];
        this._created = [];
        this._all = [];
        this.ref.markForCheck();
    }
}