import { SimpleChanges } from "@angular/core";
import { hasSomeChanges, isset } from "@cawita/core-front";
import { sameIds } from "@hints/utils/mongo";
import { ValueKeyIteratee, omitBy, isObject, isArray, mapValues, PartialObject, NumericDictionary, Dictionary } from "lodash";
import { BehaviorSubject, Observable, Subscription, debounce, distinctUntilChanged, filter, finalize, forkJoin, take, tap, timer } from "rxjs";

export type AutoSaveObserverOptions<P> = {
    debounce: number;
    save: (params: P) => Observable<any>;
    paramsObservable: () => Observable<P>;
    saveStarted?: () => void;
    saveEnded?: () => void;
    comparator?: (previous: P, current: P) => boolean;
}

export class AutoSaveObserver<P> {
    private _autoSaveSub: Subscription;
    private _isSaving = new BehaviorSubject<boolean>(false);
    private _isSaved = this._isSaving.pipe(filter(isSaving => isSaving === false));

    constructor(protected options: AutoSaveObserverOptions<P>) { }

    public start() {
        this.stop();
        this._autoSaveSub = this.options.paramsObservable().pipe(
            distinctUntilChanged((a, b) => {
                if (this.options.comparator) return this.options.comparator(a, b);
                return a === b;
            }),
            debounce(() => forkJoin([
                timer(this.options.debounce).pipe(take(1)),
                this._isSaved.pipe(take(1))
            ])),
        ).subscribe(params => this.save(params));
    }

    public stop() {
        this._autoSaveSub?.unsubscribe();
        this._autoSaveSub = null;
    }

    public save(params: P) {
        this.options.saveStarted?.();
        this._isSaving.next(true);
        this.options.save(params).pipe(
            finalize(() => {
                this._isSaving.next(false);
                this.options.saveEnded?.();
            })
        ).subscribe();
    }
}


export function formsAreEqual<T>(previous: T, current: T) {
    if (isset(previous) !== isset(current)) return false; // one is null, the other is not
    if (!isset(previous) && !isset(current)) return true; // both null/undefined
    if (isObject(previous) !== isObject(current)) return false; // type not the same
    if (!isObject(previous) && !isObject(current)) return previous === current;

    const keysInA = Object.keys(previous);
    return keysInA.every(key => formsAreEqual(previous[key], current[key]));
}

export function hasSomeChangesInIds(changes: SimpleChanges, inputs: string[], includeFirstChanges?: boolean) {
    if (!hasSomeChanges(changes, inputs, includeFirstChanges)) return false;
    return inputs.some(input => {
        if (!isset(changes[input])) return false;
        return !sameIds(changes[input].previousValue, changes[input].currentValue);
    });
}


export function omitByRecursively<T>(object: Dictionary<T> | null | undefined, predicate?: ValueKeyIteratee<T>): Dictionary<T>;
export function omitByRecursively<T>(object: NumericDictionary<T> | null | undefined, predicate?: ValueKeyIteratee<T>): NumericDictionary<T>;
export function omitByRecursively<T extends object>(object: T | null | undefined, predicate: ValueKeyIteratee<T[keyof T]>): PartialObject<T>;
export function omitByRecursively<T>(object: any, predicate: ValueKeyIteratee<any>) {
    var callback = (value: any) => omitByRecursively(value, predicate);
    if (isArray(object)) return object.map(callback);
    if (isObject(object)) return omitBy(mapValues(object, callback), predicate);
    return object;
}
