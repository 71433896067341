import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp, CwtSubCrudContract } from "@cawita/core-front/api";
import { Company } from "../company";
import { Customer } from "./customer.model";
import { CustomerSite } from "./customer-site.model";


@CwtModel({ name: 'customer-contact' })
@CwtSubCrudContract({ parent: Customer, parentPath: '/company/:companyId/customer', subPath: '/customer-contact', searchMapper: (search) => ({ firstName: { regexp: search } }) })
@CwtCrudContract({ path: '/company/:companyId/customer-contact' })
export class CustomerContact extends CwtApiEntity {
    @CwtProp({ ref: Company }) company: Company;
    @CwtProp() firstName: string;
    @CwtProp() lastName: string;
    @CwtProp() email: string;
    @CwtProp() phone: string;
    @CwtProp() mobile: string;
    @CwtProp() position: string;
    @CwtProp() isDeleted: boolean;
    @CwtProp({ ref: Customer }) customer: Customer;
    @CwtProp({ ref: CustomerSite }) sites: CustomerSite[];
    @CwtProp() observation: string;
}