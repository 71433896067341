import { Directive, ElementRef, HostListener } from '@angular/core';
import { isset } from '@cawita/core-front';
import { Formatting } from '@server-shared';
import { SimpleValueAccessor, provideValueAccessor } from '@shared/widgets/features/common/simple-value-accessor/simple-value-accessor.directive';

@Directive({
  selector: 'input[cwt-percent-input]',
  standalone: true,
  providers: [provideValueAccessor(PercentInputComponent)],
})
export class PercentInputComponent extends SimpleValueAccessor<number> {

  public formattedValue: string;
  public displayValue: string;
  public inFocus = false;

  constructor(private elem: ElementRef) { super(); }

  writeValue(obj: number): void {
    if (isset(obj)) this._applyValue(obj);
    else this._applyValue(null);
    this._updateViewValue();
    this.cdRef.markForCheck();
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    this._applyInput(value);
    this.onChange(this.value);
    this.onTouched();
    this._updateViewValue();
    this.cdRef.markForCheck();
  }

  @HostListener('focus')
  onFocus() {
    this.inFocus = true;
    this._updateViewValue();
    this.cdRef.markForCheck();
  }

  @HostListener('blur')
  onBlur() {
    this.inFocus = false;
    this._applyValue(this.value);
    this._updateViewValue();
    this.onTouched();
    this.cdRef.markForCheck();
  }

  private _applyValue(value: number) {
    const parsedValue = Formatting.percent.parse(value ?? 0);
    this.formattedValue = Formatting.percent.format(parsedValue);
    this.value = parsedValue;
    this.displayValue = String(parsedValue * 100);
  }

  private _applyInput(input: string) {
    const parsedValue = Formatting.percent.parse(input ?? 0);
    this.formattedValue = Formatting.percent.format(parsedValue);
    this.value = parsedValue;
    this.displayValue = input;
  }

  private _updateViewValue() {
    if (this.inFocus) this.elem.nativeElement.value = this.displayValue ?? '';
    else this.elem.nativeElement.value = this.formattedValue ?? '';
  }
}
