import { CwtApiEntity, CwtProp, CwtModel, CwtSubCrudContract, CwtCrudContract } from '@cawita/core-front/api';
import { User } from './user.model';



@CwtModel({ name: 'user-lock' })
@CwtSubCrudContract({ parent: User, parentPath: 'user', subPath: 'user-lock', })
export class UserLock extends CwtApiEntity {
    @CwtProp({ ref: User }) user: User;
    @CwtProp() type: { type: String, enum: ['admin', 'tooManyPasswordAttemps', 'abuseOfUse'] };
    @CwtProp({ ref: Date }) lockUntilDate: Date;
    @CwtProp() reason: String
}