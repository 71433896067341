import { CwtApiEntity, CwtApiSchema, CwtModel, CwtProp, CwtSchema } from "@cawita/core-front/api";
import { User } from "./user.model";

@CwtSchema()
export class UserNotificationQuoteEmailSetting extends CwtApiSchema {
    @CwtProp() signed: 'none' | 'mine' | 'all';
    @CwtProp() refused: 'none' | 'mine' | 'all';
}

@CwtSchema()
export class UserNotificationEmailSetting extends CwtApiSchema {
    @CwtProp({ ref: UserNotificationQuoteEmailSetting }) quotes: UserNotificationQuoteEmailSetting;
}

@CwtModel({ name: 'user-notification-setting' })
export class UserNotificationSetting extends CwtApiEntity {
    @CwtProp({ ref: User }) user: User;
    @CwtProp({ ref: UserNotificationEmailSetting }) email: UserNotificationEmailSetting;
}