import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CWT_AUTH_LISTENER } from '@cawita/core-front/auth';
import { CompanyAuthListener } from './company-auth-listener';
import { CompanyIdInterceptor } from './company-id-interceptor';
import { ActiveCompanyDirective } from './directives/active-company.directive';
import { AllCompaniesDirective } from './directives/all-companies.directive';
import { CompanyRouterLinkDirective } from './directives/company-router-link.directive';
import { CompanyVatRatesDirective } from './directives/company-vat-rates.directive';
import { HasCompanyPermissionDirective } from './directives/has-company-permission.directive';
import { IfCompanyFeatureDirective } from './directives/if-company-feature.directive';
import { IfCompanyPermissionDirective } from './directives/if-company-permission.directive';
@NgModule({
    imports: [],
    exports: [
        ActiveCompanyDirective,
        AllCompaniesDirective,
        IfCompanyFeatureDirective,
        IfCompanyPermissionDirective,
        HasCompanyPermissionDirective,
        CompanyRouterLinkDirective,
        CompanyVatRatesDirective
    ],
    declarations: [
        ActiveCompanyDirective,
        AllCompaniesDirective,
        IfCompanyFeatureDirective,
        IfCompanyPermissionDirective,
        HasCompanyPermissionDirective,
        CompanyRouterLinkDirective,
        CompanyVatRatesDirective
    ],
    providers: [],
})
export class SharedCompanyModule {
    static forRoot(): ModuleWithProviders<SharedCompanyModule> {
        return {
            ngModule: SharedCompanyModule,
            providers: [
                { provide: HTTP_INTERCEPTORS, useClass: CompanyIdInterceptor, multi: true },
                { provide: CWT_AUTH_LISTENER, useClass: CompanyAuthListener, multi: true }
            ]
        }
    }
}
