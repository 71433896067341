import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { User } from '@shared/models';
import { MailRecipient } from '../../mailing-types';

@Component({
  selector: 'cwt-user-recipient',
  template: `
    <cwt-image [src]="recipient?.data?.picture | cwtAsset" height="20px" ratio="1" shape="circle">
      <cwt-media-avatar-addon *cwtImageAddon="'placeholder'" [text]="recipient?.data?.name" [length]="2"></cwt-media-avatar-addon>
      <cwt-media-loading-addon *cwtImageAddon="'loading'"></cwt-media-loading-addon>
    </cwt-image>
    <span>{{recipient?.data?.firstName}} {{recipient?.data?.lastName}}</span>
  `,
  styles: [
    `:host { display: flex; gap: 4px }`,
    `cwt-image { margin-inline-start: -4px; }`
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserRecipientComponent {
  @Input() recipient: MailRecipient<User>;
}
