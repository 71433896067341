const { isset, asArray, isEmptyOrNotSet } = require('@hints/utils/data');
const { arrayToString, stringToArray, getSchedule } = require('cron-converter');
const { compareAsc } = require('date-fns');
const { isGenerationDateBetween, isGenerationDateAfterStart, isGenerationDateBeforeEnd } = require('./recurring-manager-utils');


function _generateArrayOfNumber(length, start) {
    return Array.from({ length }, (_, i) => i + start);
}

function validateRecurringFrequencies(recurringObj) {
    if (isEmptyOrNotSet(recurringObj.startDate)) return false;
    if (isEmptyOrNotSet(recurringObj.frequencies)) return false;
    return recurringObj.frequencies.every(freq => (
        !isEmptyOrNotSet(freq.type) &&
        ['daysOfWeek', 'daysOfMonth', 'dayOfYear', 'expression'].includes(freq.type) &&
        !isEmptyOrNotSet(freq[freq.type])
    ));
}

function sanitizeRecurringForFrequencies(recurringObj) {
    delete recurringObj.dates;
    delete recurringObj.rules;
    const frequencies = recurringObj.frequencies || [];
    recurringObj.frequencies = frequencies.map(f => ({
        type: f.type,
        hour: f.hour || 0,
        [f.type]: f[f.type], // keep only key relative to type of frequency
        expression: getExpression(f)
    }));
}

function getExpression(frequency) {
    switch (frequency.type) {
        case 'daysOfWeek': return arrayToString([[0], [frequency.hour || 0], _generateArrayOfNumber(31, 1), _generateArrayOfNumber(12, 1), frequency.daysOfWeek]);
        case 'daysOfMonth': return arrayToString([[0], [frequency.hour || 0], frequency.daysOfMonth, _generateArrayOfNumber(12, 1), _generateArrayOfNumber(7, 0)]);
        case 'dayOfYear': return arrayToString([[0], [frequency.hour || 0], [frequency.dayOfYear.day], [frequency.dayOfYear.month], _generateArrayOfNumber(7, 0)]);
        case 'expression': {
            // eslint-disable-next-line no-unused-vars
            const [_year, _month, dayOfMonth, month, dayOfWeek] = stringToArray(frequency.expression);
            return arrayToString([0], [frequency.hour], dayOfMonth, month, dayOfWeek);
        }
    }
}


function _getFrequencyNextRunDatesBetween(frequency, datesToGenerate = 1, startDate, endDate, minDate, maxDate) {
    const expression = getExpression(frequency);
    const schedule = getSchedule(stringToArray(expression), startDate);
    const nextRunDates = [];
    while (nextRunDates.length < datesToGenerate) {
        const next = schedule.next();
        if (!isset(next)) break;
        const date = new Date(next);
        if (!isset(date)) break;
        if (!isGenerationDateAfterStart(date, startDate, minDate)) continue;
        if (!isGenerationDateBeforeEnd(date, endDate, maxDate)) break;
        nextRunDates.push(date);
    }

    return nextRunDates;
}

function getFrequenciesNextRunDatesBetween(frequencies, datesToGenerate = 1, startDate, endDate, minDate, maxDate) {
    const allDates = asArray(frequencies || []).reduce((dates, frequency) => dates.concat(_getFrequencyNextRunDatesBetween(frequency, datesToGenerate, startDate, endDate, minDate, maxDate)), []);
    const sorted = asArray(allDates || []).sort(compareAsc);
    const validDates = sorted.filter(date => isGenerationDateBetween(date, startDate, endDate, minDate, maxDate));
    return validDates.slice(0, datesToGenerate);
}

module.exports = {
    getExpression,
    validateRecurringFrequencies,
    sanitizeRecurringForFrequencies,
    getFrequenciesNextRunDatesBetween,
};