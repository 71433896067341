<div class="cwt-page-header-main">
    <div class="cwt-page-header-left gap-2">
        <button class="cwt-page-header-back" type="button" nz-button nzType="link" cwtGoBack>
            <span nz-icon nzType="left"></span>
        </button>

        <ng-container *ngIf="avatar"><ng-content select="cwt-page-header-avatar, [cwt-page-header-avatar]"></ng-content></ng-container>
    </div>

    <ng-container *cwtIsMinBreakpoint="'lg'; let hasPlaceForTags">
        <div *ngIf="title || subtitle || (hasPlaceForTags && tags)" class="cwt-page-header-middle">
            <ng-container *ngIf="title"><ng-content select="cwt-page-header-title, [cwt-page-header-title]"></ng-content></ng-container>
            <ng-container *ngIf="subtitle"><ng-content select="cwt-page-header-subtitle, [cwt-page-header-subtitle]"></ng-content></ng-container>
            <ng-container *ngIf="hasPlaceForTags && tags"><ng-content select="cwt-page-header-tags, [cwt-page-header-tags]"></ng-content></ng-container>
        </div>
    </ng-container>
    
    <div *ngIf="actions" class="cwt-page-header-right">
        <ng-content select="cwt-page-header-actions, [cwt-page-header-actions]"></ng-content>
    </div>    
</div>

<div *ngIf="content" class="cwt-page-header-content mt-3">
    <ng-content select="cwt-page-header-content, [cwt-page-header-content]"></ng-content>
</div>