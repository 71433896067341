import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp } from "@cawita/core-front/api";
import { UploadFile, User } from "../common";
import { Company } from "../company";

export enum BillingExportStatus {
    Ongoing = 'ongoing',
    Available = 'available',
    Expired = 'expired',
}

@CwtModel({ name: 'billing-export' })
@CwtCrudContract({ path: 'company/:companyId/billing-export' })
export class BillingExport extends CwtApiEntity {
    @CwtProp({ ref: Company }) company: Company;
    @CwtProp({ ref: User }) requester: User;
    @CwtProp() startDate: number;
    @CwtProp() endDate: number;
    @CwtProp({ ref: Date }) expirationDate: Date;
    @CwtProp({ ref: UploadFile }) file: UploadFile;
    @CwtProp() status: BillingExportStatus;
}