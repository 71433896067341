import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { TabDirective } from './tab.directive';
import { TabsetDirective } from './tabset.directive';



@NgModule({
  declarations: [
    TabsetDirective,
    TabDirective
  ],
  imports: [
    CommonModule,
    NzTabsModule
  ],
  exports: [
    NzTabsModule,
    TabsetDirective,
    TabDirective
  ]
})
export class TabsModule { }
