import { Injectable } from '@angular/core';
import { getId, isset } from '@cawita/core-front';
import { isEmpty } from '@hints/utils/data';
import { sameIds } from '@hints/utils/mongo';
import { Company } from '@shared/models';
import { formatCommands } from '@shared/utils/router.utils';
import { Subscription, distinctUntilChanged, map } from 'rxjs';
import { CompanyStore } from '../company.store';
import { NavigationExtras, Router, UrlCreationOptions } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class CompanyRouter {
    constructor(
        private store: CompanyStore,
        private router: Router
    ) { }

    public createCompanyRouterWatcher(
        commands: string | string[] | undefined,
        routeChanged: (absolutePath: string[]) => void
    ): Subscription {
        return this.store.stableState$.pipe(
            distinctUntilChanged((a, b) => sameIds(a.activeCompany, b.activeCompany)),
            map((s) => s.activeCompany)
        ).subscribe(company => {
            const path = this._formatRedirectUrl(company, commands);
            routeChanged(path);
        });
    }


    public navigate(commands: any[], extras?: NavigationExtras) {
        const { activeCompany } = this.store.getValue();
        const path = this._formatRedirectUrl(activeCompany, commands);
        if (!isset(path)) return Promise.resolve(false);
        return this.router.navigate(path, extras);
    }

    public createUrlTree(commands: any[], extras?: UrlCreationOptions) {
        const { activeCompany } = this.store.getValue();
        const path = this._formatRedirectUrl(activeCompany, commands);
        return this.router.createUrlTree(path, extras);
    }

    public refreshAndNavigateToCompany(company: Company) {
        this.store.refresh().subscribe(() => this.router.navigate(['/company', getId(company)], { replaceUrl: true }));
    }

    private _formatRedirectUrl(company: Company, commands?: string | string[]) {
        if (!isset(company)) return null;
        const saneCommands = formatCommands(commands);
        if (isEmpty(saneCommands)) return null;
        return ['/company', getId(company), ...saneCommands];
    }
}