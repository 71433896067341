import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { CwtDirectiveView, getId, isset } from '@cawita/core-front';
import { Reporter } from '@shared/models';
import { Subscription, take } from 'rxjs';
import { ReportingStore } from '../reporting.store';

export type ActiveReporterContext = {
  $implicit: Reporter;
  id: string;
}

@Directive({
  selector: '[cwtActiveReporter]'
})
export class ActiveReporterDirective {
  private _sub: Subscription;
  private _ctx: ActiveReporterContext = { $implicit: null, id: null };
  private _view = new CwtDirectiveView(this.vcRef, this.tRef, this._ctx);
  private _elseView = new CwtDirectiveView(this.vcRef, null);

  @Input('cwtActiveReporterElse') public set elseRef(v: TemplateRef<any>) {
    this._elseView.setTemplate(v);
  }

  constructor(
    private tRef: TemplateRef<ActiveReporterContext>,
    private vcRef: ViewContainerRef,
    private reportingStore: ReportingStore
  ) { }

  ngOnDestroy(): void {
    this._sub?.unsubscribe();
  }

  ngOnInit(): void {
    this._sub = this.reportingStore.stableState$.pipe(take(1)).subscribe({
      next: (state) => {
        this._ctx.$implicit = state.activeReporter;
        this._ctx.id = getId(state.activeReporter);
        this._view.ensureState(isset(state.activeReporter));
        this._elseView.ensureState(!isset(state.activeReporter));
      }
    });
  }

}
