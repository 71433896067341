import { Directive, Input, OnInit } from '@angular/core';
import { BooleanInput } from 'ng-zorro-antd/core/types';
import { InputBoolean } from 'ng-zorro-antd/core/util';
import { NzSelectComponent } from 'ng-zorro-antd/select';

@Directive({ selector: 'nz-select', standalone: true })
export class FixSelectItemHeightDirective implements OnInit {
    @Input() @InputBoolean() tagSelect: BooleanInput = false;
    @Input() defaultOptionHeight: number = 28;
    @Input() tagOptionHeight: number = 32;

    constructor(private nzSelect: NzSelectComponent) { }

    ngOnInit(): void {
        this.nzSelect.nzOptionHeightPx = this.tagSelect ? this.tagOptionHeight : this.defaultOptionHeight;
        this.nzSelect.nzOptionOverflowSize = 10;
    }
}