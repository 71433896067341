import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp } from "@cawita/core-front/api";
import { Invoice } from "./billing";
import { Company } from "./company";
import { CompanyProduct } from "./company-product";
import type { Contract } from "./contract.model";
import { Customer, CustomerSite } from "./customer";
import { Product } from "./product.model";

export enum MachineMoveType {
    delivery = 'delivery',
    withdrawal = 'withdrawal',
}

export function MachineMoveSearchMapper(search: string) { return { ref: { regexp: search } }; }

@CwtModel({ name: 'machine-move' })
@CwtCrudContract({ path: 'company/:companyId/machine-move', searchMapper: MachineMoveSearchMapper })
export class MachineMove extends CwtApiEntity {
    @CwtProp({ ref: Company }) company: Company;
    @CwtProp({ ref: Customer }) customer: Customer;
    @CwtProp() type: MachineMoveType;
    @CwtProp() ref: string[];
    @CwtProp({ ref: CustomerSite }) site: CustomerSite;
    @CwtProp() date: number;
    @CwtProp() isDelivery: boolean;
    @CwtProp() deliveryFee: number;
    @CwtProp() isAutoInvoice: boolean;
    @CwtProp() mustGenerateFirstInvoice: boolean;
    @CwtProp() mustGenerateContract: boolean;
    @CwtProp() mustGenerateAvoir: boolean;
    @CwtProp({ ref: Invoice }) invoice: Invoice;
    @CwtProp({ ref: Invoice }) avoir: Invoice;
    @CwtProp({ ref: Product }) products: Product[];
    @CwtProp({ ref: 'contract' }) contract: Contract;
    @CwtProp() isDeleted: boolean;
}

export type MachineMoveWithdrawalProductInfo = {
    product: CompanyProduct;
    quantityToRemove: number;
    quantityOnSite: number;
    unitPrice: number;
}