import { ChangeDetectorRef, Directive, Inject, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { forEveryFormControl } from '@cawita/core-front';

@Directive({
  selector: '[cwtForwardCVA]',
  standalone: true
})
export class ForwardCvaDirective implements OnInit, OnDestroy {
  private control: AbstractControl;
  private _oldMarkAllAsTouched: any;
  private _oldUpdateValueAndValidity: any;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(NgControl) @Optional() private ngControl?: NgControl,
    @Inject(NG_VALUE_ACCESSOR) @Optional() @Self() private valueAccessors?: ControlValueAccessor[]
  ) { }

  ngOnInit(): void {
    if (!this.ngControl || !this.valueAccessors || this.valueAccessors.length !== 1) return;
    const controls: AbstractControl[] = Object.values(this.valueAccessors[0]).filter((v: unknown) => v instanceof AbstractControl);
    if (controls.length === 0) return;
    this.control = controls[0];
    this._oldMarkAllAsTouched = this.ngControl.control.markAllAsTouched;
    this._oldUpdateValueAndValidity = this.ngControl.control.updateValueAndValidity;

    this.ngControl.control.markAllAsTouched = (): void => {
      this._oldMarkAllAsTouched.bind(this.ngControl.control)();
      this.control.markAllAsTouched();
      this.changeDetectorRef.markForCheck();
    };

    this.ngControl.control.updateValueAndValidity = (...args: any): void => {
      this._oldUpdateValueAndValidity.bind(this.ngControl.control)(...args);
      forEveryFormControl(this.control, c => c.updateValueAndValidity(...args));
      this.changeDetectorRef.markForCheck();
    }
  }

  ngOnDestroy(): void {
    if (this._oldMarkAllAsTouched && this.ngControl?.control?.markAllAsTouched) {
      this.ngControl.control.markAllAsTouched = this._oldMarkAllAsTouched;
      this._oldMarkAllAsTouched = null;
    }
    if (this._oldUpdateValueAndValidity && this.ngControl?.control?.updateValueAndValidity) {
      this.ngControl.control.updateValueAndValidity = this._oldUpdateValueAndValidity;
      this._oldUpdateValueAndValidity = null;
    }
  }
}
