import { Inject, Injectable, Optional } from '@angular/core';
import { CwtStorageService, getId, isObject, isset, sameId } from '@cawita/core-front';
import { CwtCacheService, CwtCrudContract, CwtCrudService, CwtSubCrudContract, CwtSubCrudService } from '@cawita/core-front/api';
import { CwtStore } from '@cawita/core-front/state';
import { firstValueFrom, forkJoin, from, lastValueFrom } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { BestCompanyConfig, BestConfigValue, BillingType, Company, CompanyAccess, CompanyConfig, CompayConfigFeatures, User } from '../../models';
import { findCompanyAndAccessInArrays, getPreferredCompany } from './company.utils';
import { hasRequiredPermission } from '@cawita/core-front/auth';
import { replaceOrInsert } from '@shared/utils';
import { sameIds } from '@hints/utils/mongo';
import { COMPANY_LISTENER, ICompanyListener } from './company-listener';
import { CompanyApi } from './company.api';

export type CompanyState = {
    activeCompany: Company;
    activeAccess: CompanyAccess;
    activeConfig: BestCompanyConfig;
    user: User;
    accesses: CompanyAccess[];
    companies: Company[];
    preferred: Company;
}

@Injectable({ providedIn: 'root' })
export class CompanyStore extends CwtStore<CompanyState> {
    private _lastActiveCompanies: Record<string, string> = {};

    constructor(
        private store: CwtStorageService,
        private companyApi: CompanyApi,
        @Inject(COMPANY_LISTENER) @Optional() private listeners: ICompanyListener[],
        @Inject(CwtCrudContract.get(Company)) private companyCrud: CwtCrudService<Company>,
        @Inject(CwtSubCrudContract.get(CompanyAccess, User)) private userCompanyAccessCrud: CwtSubCrudService<CompanyAccess>,
        private cache: CwtCacheService
    ) {
        super({
            companies: [],
            accesses: [],
            user: null,
            preferred: null,
            activeCompany: null,
            activeAccess: null,
            activeConfig: null,
            initialized: false,
            loading: false
        });
    }

    public hasCompanyPermission(permissions: string | string[]) {
        const { activeAccess } = this.getValue();
        return hasRequiredPermission(activeAccess?.permissions, permissions);
    }

    public init(user: User) {
        this._restoreLastActive();
        return forkJoin([
            this.userCompanyAccessCrud.getArray(getId(user)),
            this.companyCrud.getArray()
        ]).pipe(
            tap(([accesses, companies]) => {
                this.setState(c => ({
                    ...c,
                    user: user,
                    accesses: accesses,
                    companies: companies,
                    preferred: getPreferredCompany(companies, accesses, this._lastActiveCompanies[getId(user)]),
                    initialized: true,
                    loading: false
                }));
            })
        );
    }

    public clear() {
        this.setState(c => ({
            companies: [],
            accesses: [],
            preferred: null,
            user: null,
            activeCompany: null,
            activeAccess: null,
            activeConfig: null,
            initialized: false,
            loading: false
        }));
    }

    public activate(company: Company | string) {
        const { user } = this.getValue();
        return from(this._activateCompany(user, company));
    }

    public setCompanyIncrement(type: BillingType, increment: number) {
        const { activeCompany } = this.getValue();
        const newCompany = new Company(activeCompany);
        newCompany.increments[type] = increment;
        this.setState(c => ({
            ...c,
            activeCompany: newCompany,
            companies: replaceOrInsert(c.companies, newCompany, (a, b) => sameIds(a, b))
        }));
    }

    public deactivate() {
        this.cache.invalidate('company');
        this.setState(c => {
            if (c.activeCompany) this._triggerCompanyDeactivated(c.activeCompany, c.activeAccess, c.activeConfig);
            return ({ ...c, activeCompany: null, activeAccess: null, activeConfig: null });
        });
    }

    public hasCompanyFeature(feature: keyof CompayConfigFeatures<any>) {
        const { activeConfig } = this.getValue();
        return (
            isset(activeConfig) &&
            isset(activeConfig[feature]) &&
            (activeConfig[feature] as BestConfigValue<boolean>).value === true
        );
    }

    public updateActive(company: Company) {
        const { activeCompany } = this.getValue();
        if (!sameId(company, activeCompany)) return;
        this.setState(c => ({
            ...c,
            activeCompany: new Company({
                ...activeCompany,
                ...company
            })
        }))
    }

    public refresh() {
        const { user } = this.getValue();
        return this.init(user);
    }

    private _restoreLastActive() {
        this._lastActiveCompanies = this.store.getObject<Record<string, string>>('cwt-cca-last-company', {});
    }

    private _persistLastActive(user: User, company: string) {
        this._lastActiveCompanies[getId(user)] = company;
        this.store.setObject('cwt-cca-last-company', this._lastActiveCompanies);
    }

    private _triggerCompanyActivated(company: Company, access: CompanyAccess, config: BestCompanyConfig) {
        this.listeners?.forEach(l => l?.onCompanyActivated(company, access, config));
    }

    private _triggerCompanyDeactivated(company: Company, access: CompanyAccess, config: BestCompanyConfig) {
        this.listeners?.forEach(l => l?.onCompanyDeactivated(company, access, config));
    }

    private async _activateCompany(user: User, companyToActivate: Company | string) {
        const state = await firstValueFrom(this.stableState$);
        if (!state) return;
        this.cache.invalidate('company');

        const { activeAccess, activeCompany, activeConfig } = state;
        const { access = null, company = null } = findCompanyAndAccessInArrays(state.companies, state.accesses, companyToActivate);
        if (isset(activeCompany)) this._triggerCompanyDeactivated(activeCompany, activeAccess, activeConfig);
        if (!isset(company)) {
            this.setState(c => ({ ...c, activeCompany: null, activeAccess: null, activeConfig: null }));
            this._persistLastActive(user, undefined);
            return null;
        } else {
            const config = await lastValueFrom(this.companyApi.getBestConfig(company));
            this.setState(c => ({ ...c, activeCompany: company, activeAccess: access, activeConfig: config }));
            this._persistLastActive(user, getId(company));
            this._triggerCompanyActivated(company, access, config);
            return company;
        }

    }

}