import { Directive, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { CwtDirectiveView, hasSomeChanges } from '@cawita/core-front';
import { Quote } from '@shared/models';

@Directive({ selector: '[cwtHasQuoteInvoices]' })
export class HasQuoteInvoicesDirective implements OnChanges {
    private _view = new CwtDirectiveView(this.vcRef, this.tRef);
    @Input('cwtHasQuoteInvoices') quote: Quote;

    constructor(
        private vcRef: ViewContainerRef,
        private tRef: TemplateRef<void>
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (hasSomeChanges(changes, ['quote'], true)) {
            const hasQuoteInvoices = (this.quote?.totalInvoiced ?? 0) > 0;
            this._view.ensureState(hasQuoteInvoices);
        }
    }
}