import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { getId } from '@cawita/core-front';
import { findByAddress } from '@hints/utils/data';
import { catchError, map } from 'rxjs/operators';
import { CustomerStore } from './customer.store';
import { of } from 'rxjs';

export function CustomerTokenActivationGuard(tokenParamsPath: string): CanActivateFn {
    return (route, state) => {
        const router = inject(Router);
        const customerStore = inject(CustomerStore);
        const customerToken = findByAddress(route.params, tokenParamsPath);
        if (!customerToken?.length) return router.createUrlTree(['/']);
        return customerStore.init(customerToken).pipe(
            map(() => true),
            catchError((err) => of(false)),
            map((success) => {
                if (success) return true;
                else return router.createUrlTree(['/']);
            })
        );
    }
}