import { Directive, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { CwtDirectiveView, hasSomeChanges, isset } from '@cawita/core-front';
import { User } from '@shared/models';

export type UserLockedContext = {
  $implicit: boolean;
  cwtUserLocked: boolean;
}

@Directive({
  selector: '[cwtUserLocked]'
})
export class UserLockedDirective implements OnInit, OnChanges, OnDestroy {

  private _ctx: UserLockedContext = { $implicit: null, cwtUserLocked: null };
  private _view = new CwtDirectiveView(this.vcRef, this.tRef, this._ctx);

  @Input('cwtUserLocked') user: User;

  constructor(
    private tRef: TemplateRef<UserLockedContext>,
    private vcRef: ViewContainerRef,
  ) { }

  ngOnInit(): void {
    this._updateView();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (hasSomeChanges(changes, ['user'])) this._updateView();
  }

  ngOnDestroy(): void {
    this._view?.ensureState(false);
  }

  private _updateView() {
    const isLocked = isset(this.user.lockType) && this.user.lockType !== 'none';
    this._ctx.$implicit = this._ctx.cwtUserLocked = isLocked;
    this._view.ensureState(true);
  }
}
