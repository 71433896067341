import { ChangeDetectorRef, Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { CwtDirectiveView, getId, isset } from '@cawita/core-front';
import { Subscription } from 'rxjs';
import { ReportingStore } from '../reporting.store';
import { Reporter } from '@shared/models';

export type IfReportingAccessContext = {
  $implicit: boolean;
  visibleReporterCount: number;
  activeReporter: Reporter;
}

@Directive({
  selector: '[cwtIfReportingAccess]'
})
export class IfReportingAccessDirective {
  static ngTemplateContextGuard(dir: IfReportingAccessDirective, ctx: any): ctx is IfReportingAccessContext { return true; }

  private _sub: Subscription;
  private _ctx: IfReportingAccessContext = { $implicit: false, activeReporter: null, visibleReporterCount: 0 };
  private _view = new CwtDirectiveView(this.vcRef, this.tRef, this._ctx);
  private _elseView = new CwtDirectiveView(this.vcRef, null);

  @Input('cwtIfReportingAccessElse') public set elseRef(v: TemplateRef<any>) {
    this._elseView.setTemplate(v);
  }

  constructor(
    private tRef: TemplateRef<IfReportingAccessContext>,
    private vcRef: ViewContainerRef,
    private reportingStore: ReportingStore
  ) { }

  ngOnDestroy(): void {
    this._sub?.unsubscribe();
  }

  ngOnInit(): void {
    this._sub = this.reportingStore.stableState$.subscribe({
      next: (state) => {
        this._ctx.$implicit = state.hasReportingAccess;
        this._ctx.visibleReporterCount = state.visibleReporterCount;
        this._ctx.activeReporter = state.activeReporter;
        this._view.ensureState(state.hasReportingAccess);
        this._elseView.ensureState(state.hasReportingAccess);
      }
    });
  }

}
