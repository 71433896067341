import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { COMPANY_LISTENER } from '../company';
import { ReportingCompanyListener } from './reporting-company-listener';
import { ActiveReporterDirective } from './directives/active-reporter.directive';
import { IfReportingAccessDirective } from './directives/if-reporting-access.directive';



@NgModule({
  declarations: [
    ActiveReporterDirective,
    IfReportingAccessDirective
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    ActiveReporterDirective,
    IfReportingAccessDirective
  ]
})
export class SharedReportingModule {
  static forRoot(): ModuleWithProviders<SharedReportingModule> {
    return {
      ngModule: SharedReportingModule,
      providers: [
        { provide: COMPANY_LISTENER, useClass: ReportingCompanyListener, multi: true },
      ]
    }
  }
}
