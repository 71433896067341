import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp } from "@cawita/core-front/api";
import { isEmpty } from "@hints/utils/data";
import { Role } from "./role.model";
import { UploadFile } from "./upload-file.model";
import { UserLockType } from "./user-lock-type.model";

const UserSearchMapper = (s) => ({
    or: [
        { firstName: { regexp: s } },
        { lastName: { regexp: s } },
        { email: { regexp: s } },
    ]
});

@CwtModel({ name: 'user' })
@CwtCrudContract({ path: '/user', searchMapper: UserSearchMapper })
@CwtCrudContract({ path: 'company/:companyId/user', searchMapper: UserSearchMapper, identifier: 'company' })
export class User extends CwtApiEntity {
    @CwtProp() firstName?: string;
    @CwtProp() lastName?: string;
    @CwtProp() email?: string;
    @CwtProp() emailValid?: boolean;
    @CwtProp() phoneValid?: boolean;
    @CwtProp() accessType?: string;
    @CwtProp() lockType: UserLockType | 'none';
    @CwtProp({ ref: Role }) role?: Role;
    @CwtProp({ ref: UploadFile }) picture: UploadFile;

    public get name(): string { return [this.firstName, this.lastName].filter(v => !isEmpty(v)).join(' '); }
}