import { CwtApiEntity, CwtApiSchema, CwtCrudContract, CwtModel, CwtProp, CwtSchema } from "@cawita/core-front/api";
import { User } from "../common";
import { Company } from "../company";
import { Customer } from "../customer";
import { BusinessType } from "./business-type.model";

@CwtSchema()
export class ProspectSheetBusinessDetail extends CwtApiSchema {
    @CwtProp({ ref: BusinessType }) businessType: BusinessType;
    @CwtProp() title: string;
    @CwtProp() content: string;
}

@CwtModel({ name: 'prospect-sheet' })
@CwtCrudContract({
    path: 'company/:companyId/prospect-sheet',
    searchMapper: (s) => ({ 'customer.name': { regexp: s } }),
})
export class ProspectSheet extends CwtApiEntity {
    @CwtProp({ ref: Company }) company: Company;
    @CwtProp({ ref: User }) user: User;
    @CwtProp({ ref: ProspectSheet }) transferFrom: ProspectSheet;
    @CwtProp({ ref: Customer }) customer: Customer;
    @CwtProp() nbActions: number;
    @CwtProp() observation: string;
    @CwtProp({ ref: ProspectSheetBusinessDetail }) businessDetails: ProspectSheetBusinessDetail[];
    @CwtProp() isArchived: boolean;
}