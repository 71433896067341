import { Injectable } from '@angular/core';
import { getId } from '@cawita/core-front';
import { CwtApiService } from '@cawita/core-front/api';
import { BestCompanyConfig, Company } from '@shared/models';
import { map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CompanyApi {
    constructor(private api: CwtApiService) { }

    public getBestConfig(company: Company) {
        return this.api.get(`company/${getId(company)}/company-config/best`).pipe(
            map(res => new BestCompanyConfig(res))
        )
    }
}