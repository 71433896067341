import { Inject, Injectable } from '@angular/core';
import { getId } from '@cawita/core-front';
import { CwtCrudContract, CwtCrudService, CwtSubCrudContract, CwtSubCrudService } from '@cawita/core-front/api';
import { CWT_FORM_CONFIG, ICwtFormConfig } from '@cawita/core-front/form';
import { Observable, map, of } from 'rxjs';
import { Customer, CustomerContact, User } from '../../../models';
import { MailRecipient } from '../mailing-types';
import { isEmpty } from '@hints/utils/data';

@Injectable({ providedIn: 'root' })
export class MailRecipientsService {
    constructor(
        @Inject(CWT_FORM_CONFIG) private formConfig: ICwtFormConfig,
        @Inject(CwtSubCrudContract.get(CustomerContact, Customer)) private customerContactCrud: CwtSubCrudService<CustomerContact>,
        @Inject(CwtCrudContract.get(User, 'company')) private userCompanyCrud: CwtCrudService<User>,
    ) { }

    public getCustomerContactRecipients(customer?: Customer): Observable<MailRecipient<CustomerContact>[]> {
        if (!customer) return of([]);
        return this.customerContactCrud.getArray(getId(customer), {}, { options: { projection: 'forCustomerContactMailing' } }).pipe(map(contacts => contacts.map(contact => ({
            emailValid: this.formConfig.emailValidatorRegexp.test(contact?.email ?? ''),
            origin: 'customer-contact',
            label: [contact.firstName, contact.lastName].filter(s => !isEmpty(s)).join(' ')?.trim(),
            email: contact.email,
            data: contact
        }))));
    }

    public getCompanyUserRecipients(): Observable<MailRecipient<User>[]> {
        return this.userCompanyCrud.getArray().pipe(map(users => users.map(user => ({
            emailValid: this.formConfig.emailValidatorRegexp.test(user.email ?? ''),
            origin: 'company-user',
            label: [user.firstName, user.lastName].filter(s => !isEmpty(s)).join(' ')?.trim(),
            email: user.email,
            data: user
        }))));
    }
}