import { RouteReuseStrategy, ActivatedRouteSnapshot, DetachedRouteHandle } from "@angular/router";
import { getId, isset, sameId } from "@cawita/core-front";

export class CompanyRouteReuseStrategy implements RouteReuseStrategy {

    /**
     * Whether the given route should detach for later reuse.
     * Always returns false for `BaseRouteReuseStrategy`.
     * */
    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return false;
    }

    /**
     * A no-op; the route is never stored since this strategy never detaches routes for later re-use.
     */
    store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void { }

    /** Returns `false`, meaning the route (and its subtree) is never reattached */
    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        return false;
    }

    /** Returns `null` because this strategy does not store routes for later re-use. */
    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
        return null;
    }

    /**
     * Determines if a route should be reused.
     * This strategy returns `true` when the future route config and current route config are
     * identical.
     */
    shouldReuseRoute(future: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot): boolean {
        if (future.routeConfig !== current.routeConfig) return false;
        const companyIdA = _mergeParams(current)['companyId'];
        const companyIdB = _mergeParams(future)['companyId'];
        if (!getId(companyIdA) || !getId(companyIdB)) return true;
        return sameId(companyIdA, companyIdB);
    }
}


function _mergeParams(route: ActivatedRouteSnapshot) {
    let params = {};
    const stack: ActivatedRouteSnapshot[] = [route];
    while (stack.length > 0) {
        const route = stack.pop()!;
        params = { ...params, ...route.params };
        if (isset(route.parent)) stack.push(route.parent);
    }
    return params;
}