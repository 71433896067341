import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp } from "@cawita/core-front/api";
import { User } from "../common";
import { Company } from "../company";
import { CustomFieldValues } from "../custom-field";

export enum ReportingType {
    EveryDay = 'everyDay',
    PickDays = 'pickDays'
}

@CwtModel({ name: 'reporter' })
@CwtCrudContract({ path: '/company/:companyId/reporter', searchMapper: s => ({ search: s }) })
@CwtCrudContract({ path: '/company/:companyId/reporter/accessible', identifier: 'accessible', searchMapper: s => ({ search: s }) })
export class Reporter extends CwtApiEntity {
    @CwtProp({ ref: Company }) company: Company;
    @CwtProp({ ref: User }) user: User;
    @CwtProp() startMonth: number;
    @CwtProp() endMonth: number;
    @CwtProp() reportingType: ReportingType;
    @CwtProp({ ref: User }) notifiedManager: User[];
    @CwtProp() customFieldValues: CustomFieldValues;
}
