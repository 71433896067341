
// prospectSheet: { ref: 'prospectSheet' },
// statusDates: submodels.getStatusDates(opportunityConfig.opportunityStatuses),
// status: { type: String, enum: opportunityConfig.opportunityStatuses },
// type: { type: String, enum: opportunityConfig.opportunityTypes },
// businessType: { ref: 'businessType' },
// salesRevenue: Number,
// margin: Number,
// comment: String,
// statusComment: String,
// transformationPercentage: Number,
// userPercentage: Number,
// customer: { ref: 'customer' },
// user: { ref: 'user' },
// isArchived: { type: Boolean, default: false }

import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp } from "@cawita/core-front/api";
import { StatusDates, StatusDatesProxy, User } from "../common";
import { Company } from "../company";
import { Customer } from "../customer";
import { BusinessType } from "./business-type.model";
import { ProspectSheet } from "./prospect-sheet.model";

export enum OpportunityTypes {
    Opening = 'opening',
    Renewal = 'renewal',
    Additional = 'additional'
}

export enum OpportunityStatus {
    InProgress = 'inProgress',
    Concluded = 'concluded',
    Planned = 'planned',
    Won = 'won',
    Lost = 'lost',
    Abandonned = 'abandonned'
}

export enum OpportunityFinalStatus {
    Won = 'won',
    Lost = 'lost',
    Abandonned = 'abandonned'
}

export enum OpportunityStatusOrder {
    inProgress = 10,
    concluded = 15,
    planned = 20,
    won = 100,
    lost = 101,
    abandonned = 102
}

export enum OpportunityStatusColors {
    inProgress = 'blue',
    concluded = 'blue',
    planned = 'blue',
    won = 'green',
    lost = 'error',
    abandonned = 'volcano'
}

export enum OpportunityStatusHexColors {
    inProgress = '#eaf6ff',
    concluded = '#eaf6ff',
    planned = '#eaf6ff',
    won = '#f8ffed',
    lost = '#fae9e6',
    abandonned = '#fcf3e8'
}

export const OpportunityTransformationPercentages = [30, 60, 90];

@CwtModel({ name: 'opportunity' })
@CwtCrudContract({
    path: 'company/:companyId/opportunity',
})
export class Opportunity extends CwtApiEntity {
    @CwtProp({ ref: Company }) company: Company;
    @CwtProp({ ref: ProspectSheet }) prospectSheet: ProspectSheet;
    @CwtProp() type: OpportunityTypes;
    @CwtProp() status: OpportunityStatus;
    @CwtProp({ ref: StatusDatesProxy }) statusDates: StatusDates<OpportunityStatus>;
    @CwtProp({ ref: BusinessType }) businessType: BusinessType;
    @CwtProp({ ref: Customer }) customer: Customer;
    @CwtProp({ ref: User }) user: User;
    @CwtProp() salesRevenue: number;
    @CwtProp() margin: number;
    @CwtProp() userPercentage: number;
    @CwtProp() transformationPercentage: number;
    @CwtProp() comment: string;
    @CwtProp() statusComment: string;
    @CwtProp() isArchived: boolean;
}


