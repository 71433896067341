import { Location } from '@angular/common';
import { Directive, HostListener, Inject, Input } from '@angular/core';
import { CwtStopPropagationDirective, formatURL, getId, isset } from '@cawita/core-front';
import { CustomerAccessToken, Customer, Invoice, Quote } from '@shared/models';
import { CustomerApi } from '@shared/services/apis/customer.api';
import { createQueryString } from '@hints/utils/url';
import { firstValueFrom } from 'rxjs';
import { CUSTOMER_CONFIG, ICustomerConfig } from './customer.config';

@Directive({
  selector: '[cwtOpenCustomerSpace]',
  hostDirectives: [CwtStopPropagationDirective]
})
export class OpenCustomerSpaceDirective {

  @Input('cwtOpenCustomerSpace') customer: Customer;
  @Input() disabled: boolean;
  @Input() nzDisabled: boolean;

  @Input() quote?: Quote;
  @Input() invoice?: Invoice;

  constructor(
    @Inject(CUSTOMER_CONFIG) private config: ICustomerConfig,
    private customerApi: CustomerApi
  ) { }

  @HostListener('click')
  public async onClick() {
    if (this.disabled || this.nzDisabled) return false;
    if (!this.customer) return;
    const accessToken = await firstValueFrom(this.customerApi.getCustomerSpace(this.customer));
    const customerSpaceUrl = this._getPathToOpen(accessToken);
    if (isset(customerSpaceUrl)) window.open(customerSpaceUrl, "_blank");
  }

  private _getPathToOpen(accessToken: CustomerAccessToken) {
    if (!accessToken) return null;
    const basePath = formatURL(this.config.customerSpaceUrl, accessToken.token);
    if (this.quote) return this._formatURLWithParams(basePath, 'quote', getId(this.quote));
    if (this.invoice) return this._formatURLWithParams(basePath, 'invoice', getId(this.invoice));
    return this._formatURLWithParams(basePath, 'dashboard');
  }

  private _formatURLWithParams(basePath: string, ...segments: string[]) {
    const queryString = createQueryString({ [this.config.trackingParam]: false });
    return `${formatURL(basePath, ...segments)}?${queryString}`;
  }
}
