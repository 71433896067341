import { ChangeDetectorRef, Type, inject } from "@angular/core";
import { CwtTranslatable, getId } from "@cawita/core-front";
import { CwtCrudContract, CwtCrudService, CwtRequestConfig, CwtSubCrudContract, CwtSubCrudService } from "@cawita/core-front/api";
import { CwtNzMessageService } from "@cawita/core-front/zorro";
import { EMPTY, Observable, finalize, tap } from "rxjs";

export type CrudResourceOptions<T> = {
    model: Type<T>,
    identifier?: string,
    updatedMessage: (item: T) => CwtTranslatable,
    createdMessage: (item: T) => CwtTranslatable,
    deletedMessage: (item: T) => CwtTranslatable,
}

export class CrudResource<T> {
    protected alert = inject(CwtNzMessageService);
    protected cdRef = inject(ChangeDetectorRef);
    protected crud = inject<CwtCrudService<T>>(CwtCrudContract.get(this.options.model, this.options.identifier));
    public loading = false;

    constructor(protected options?: CrudResourceOptions<T>) { }

    public save(item: T | null, payload: any, config?: CwtRequestConfig): Observable<T> {
        let obs: Observable<T>;
        if (item) obs = this.crud.update(getId(item), payload, config);
        else obs = this.crud.create(payload, config);

        this.loading = true;
        return obs.pipe(
            finalize(() => {
                this.loading = false;
                this.cdRef.markForCheck();
            }),
            tap(newItem => {
                const msg = item ? this.options.updatedMessage(newItem) : this.options.createdMessage(newItem);
                this.alert.success(msg);
            })
        );
    }

    public delete(item: T | null, config?: CwtRequestConfig): Observable<any> {
        if (!item) return EMPTY;
        this.loading = true;
        return this.crud.delete(getId(item), config).pipe(
            finalize(() => {
                this.loading = false;
                this.cdRef.markForCheck();
            }),
            tap(() => {
                const msg = this.options.deletedMessage(item);
                this.alert.success(msg);
            })
        );
    }
}