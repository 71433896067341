import { Injectable, inject } from '@angular/core';
import { isset } from '@cawita/core-front';
import { ConstraintOptions, MailConstraintHandler, MailParams } from '@shared/modules/mailing';
import { CustomerApi } from '@shared/services/apis/customer.api';
import { map, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AccessTokenConstraintHandler extends MailConstraintHandler {
    protected customerApi = inject(CustomerApi);

    public check(params: MailParams, options: ConstraintOptions) {
        if (params.customer) return this.customerApi.getCustomerSpace(params.customer).pipe(map(isset));
        return false;
    }

    public resolve(params: MailParams, options: ConstraintOptions) {
        if (params.customer) return this.customerApi.openDefaultCustomerSpace(params.customer).pipe(map(isset));
        return false;
    }
}