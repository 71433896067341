import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { CwtDirectiveView } from '@cawita/core-front';
import { Subscription } from 'rxjs';
import { Company } from '../../../models';
import { CompanyStore } from '../company.store';

export type AllCompaniesContext = {
    $implicit: Company[];
}

@Directive({
    selector: '[cwtAllCompanies]'
})
export class AllCompaniesDirective {
    static ngTemplateContextGuard(dir: AllCompaniesDirective, ctx: any): ctx is AllCompaniesContext { return true; }

    private _sub: Subscription;
    private _ctx: AllCompaniesContext = { $implicit: [] };
    private _view = new CwtDirectiveView(this.vcRef, this.tRef, this._ctx);

    constructor(
        private tRef: TemplateRef<AllCompaniesContext>,
        private vcRef: ViewContainerRef,
        private companyStore: CompanyStore
    ) { }

    ngOnDestroy(): void {
        this._sub?.unsubscribe();
    }

    ngOnInit(): void {
        this._sub = this.companyStore.stableState$.subscribe({
            next: (state) => {
                this._ctx.$implicit = state.companies ?? [];
                this._view.ensureState(true);
            }
        });
    }

}
