import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CUSTOM_FIELDS_CONFIG, ICustomFieldsConfig } from './custom-field.provider';

@NgModule({
  imports: [CommonModule]
})
export class SharedCustomFieldsModule {
  static forRoot(config: ICustomFieldsConfig): ModuleWithProviders<SharedCustomFieldsModule> {
    return {
      ngModule: SharedCustomFieldsModule,
      providers: [
        { provide: CUSTOM_FIELDS_CONFIG, useValue: config }
      ]
    };
  }
}
