import { isset } from "@cawita/core-front";
import { addDays, compareAsc, differenceInDays, isAfter, isBefore, isSameDay } from "date-fns";

export function limitDateRangeEnd(dates: [Date, Date], limit: number): [Date, Date] {
    const diff = Math.abs(differenceInDays(dates[0], dates[1]))
    if (diff <= limit) return dates;
    const daysToAdd = diff - limit;
    const maxDate = isAfter(dates[0], dates[1]) ? dates[0] : dates[1];
    const minDate = isAfter(dates[0], dates[1]) ? dates[1] : dates[0];
    return [
        addDays(minDate, daysToAdd),
        maxDate,
    ]
}

export function createDatePickerDisabledBeforeDate(referenceDate: Date, incl: boolean = false) {
    return (date: Date) => {
        if (!isset(referenceDate)) return false;
        if (incl && isSameDay(date, referenceDate)) return true;
        if (!incl && isSameDay(date, referenceDate)) return false;
        return isBefore(date, referenceDate)
    };
}

export function createDatePickerDisabledAfterDate(referenceDate: Date, incl: boolean = false) {
    return (date: Date) => {
        if (!isset(referenceDate)) return false;
        if (incl && isSameDay(date, referenceDate)) return true;
        if (!incl && isSameDay(date, referenceDate)) return false;
        return isAfter(date, referenceDate)
    };
}
export function createDatePickerBetweenDate(startDate: Date, endDate: Date, inclStart: boolean = false, inclEnd: boolean = false) {
    const before = createDatePickerDisabledBeforeDate(startDate, inclStart);
    const after = createDatePickerDisabledAfterDate(endDate, inclEnd);
    return (date) => before(date) || after(date);
}

export function highestDate(...dates: Date[]): Date {
    return dates?.filter(isset)?.sort(compareAsc)?.[0] ?? null;
}