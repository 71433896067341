import { Injectable } from "@angular/core";
import { ICwtAuthListener } from '@cawita/core-front/auth';
import { CompanyStore } from "./company.store";

@Injectable()
export class CompanyAuthListener implements ICwtAuthListener {
    constructor(private company: CompanyStore) { }

    onLogin(user: any): void { this.company.init(user).subscribe(); }
    onLogout(): void { this.company.clear(); }
}