import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { CwtAuthStore } from '@cawita/core-front/auth';
import { CompayConfigFeatures } from '@shared/models';
import { CompanyStore } from '../company.store';
import { CompanyRouter } from '../services';

export function CompanyFeatureGuard(feature: keyof CompayConfigFeatures<any>, redirectTo: string[]): CanActivateFn {
    return async (route, state) => {
        const router = inject(Router);
        const companyRouter = inject(CompanyRouter);
        const auth = inject(CwtAuthStore);
        const company = inject(CompanyStore);
        if (!auth.isLoggedIn) return router.createUrlTree(['/']);
        if (!company.hasCompanyFeature(feature)) return companyRouter.createUrlTree(redirectTo);
        return true;
    }
}