import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MailRecipient } from '../../mailing-types';

@Component({
  selector: 'cwt-recipient-option',
  template: `
    <span
        [nz-tooltip]="'alert.recipient-email-invalid' | translate"
        [nzTooltipTrigger]="recipient.emailValid ? null : 'hover'">
        {{recipient.label}} - {{recipient.email}}
    </span>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecipientOptionComponent {
  @Input() recipient: MailRecipient<any>;
}
