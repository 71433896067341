import { Pipe, PipeTransform } from '@angular/core';
import { round } from '@hints/utils/math';

@Pipe({
  name: 'round'
})
export class RoundPipe implements PipeTransform {

  transform(value: number, places: number = 2): number {
    return round(value, places);
  }

}
