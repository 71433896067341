import { Injectable } from '@angular/core';
import { NavigationExtras, UrlCreationOptions } from '@angular/router';
import { getId } from '@cawita/core-front';
import { isEmpty } from '@hints/utils/data';
import { ProspectSheet } from '@shared/models';
import { CompanyRouter } from '@shared/modules/company';
import { formatCommands } from '@shared/utils/router.utils';
import { Subscription } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CrmRouter {
    constructor(private companySvc: CompanyRouter) { }

    public createCrmRouterWatcher(
        commands: string | string[] | undefined,
        routeChanged: (absolutePath: string[]) => void
    ): Subscription {
        const billingCommands = this._formatRedirectUrl(commands);
        return this.companySvc.createCompanyRouterWatcher(billingCommands, routeChanged);
    }

    public navigateToProspectSheet(prospectSheet: ProspectSheet, extras?: NavigationExtras) { return this.navigate(['prospect-sheet', getId(prospectSheet)], extras); }

    public navigate(commands: any[], extras?: NavigationExtras) {
        const path = this._formatRedirectUrl(commands);
        return this.companySvc.navigate(path, extras);
    }

    public createUrlTree(commands: any[], extras?: UrlCreationOptions) {
        const path = this._formatRedirectUrl(commands);
        return this.companySvc.createUrlTree(path, extras);
    }

    private _formatRedirectUrl(commands?: string | string[]) {
        const saneCommands = formatCommands(commands);
        if (isEmpty(saneCommands)) return null;
        return ['/crm', ...saneCommands];
    }
}