import { Injectable } from '@angular/core';
import { CwtApiService, CwtRequestConfig } from '@cawita/core-front/api';

@Injectable({ providedIn: 'root' })
export class ActionApi {
    constructor(private api: CwtApiService) { }

    public getActionStats(filter?: any, config?: CwtRequestConfig) { return this.api.get(`company/:companyId/action/stats`, filter, config).pipe(); }
    public getActionRepartition(filter?: any, config?: CwtRequestConfig) { return this.api.get(`company/:companyId/action/repartition`, filter, config).pipe() }
    public transfer(body: any, config?: CwtRequestConfig) { return this.api.post(`company/:companyId/action/transfer`, body, config); }
    public copy(body: any, config?: CwtRequestConfig) { return this.api.post(`company/:companyId/action/copy`, body, config); }
    public export(filter: any, config?: CwtRequestConfig) { return this.api.get(`company/:companyId/action/export/xls`, filter, config); }
}
