import { CwtApiEntity, CwtModel, CwtProp, CwtSubCrudContract } from "@cawita/core-front/api";
import { Company } from "../company";
import { Customer } from "./customer.model";


@CwtModel({ name: 'access-token' })
@CwtSubCrudContract({ parent: Customer, parentPath: 'company/:companyId/customer', subPath: 'access-token' })
export class CustomerAccessToken extends CwtApiEntity {
    @CwtProp({ ref: Company }) company: Company;
    @CwtProp({ ref: Customer }) customer: Customer;
    @CwtProp({}) token: string;
    @CwtProp({}) label: string;
    @CwtProp({ ref: Date }) sentDate: Date;
    @CwtProp({}) isRevoked: boolean;
}