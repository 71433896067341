const { isNumber } = require('@hints/utils/types');
const { isset, isEmptyOrNotSet } = require('@hints/utils/data');
const formatting = require('./formatting');

function getTotalsForBillingItems(billingItems) {
    const total = { amount: 0, vatAmount: 0 };
    if (isEmptyOrNotSet(billingItems)) return total;

    billingItems.forEach((billingItem) => { 
        const itemTotals = getTotalsForBillingItem(billingItem);
        total.amount += itemTotals.amount;
        total.vatAmount += itemTotals.vatAmount;
    });

    return {
        amount: formatting.currency.round(total.amount),
        vatAmount: formatting.currency.round(total.vatAmount),
    };
}

function getTotalsForBillings(billings) {
    const total = { amount: 0, vatAmount: 0 };
    if (isEmptyOrNotSet(billings)) return total;
    billings.forEach((billing) => { 
        total.amount += billing.amount;
        total.vatAmount += billing.vatAmount;
    });
    return {
        amount: formatting.currency.round(total.amount),
        vatAmount: formatting.currency.round(total.vatAmount),
    };
}

function getTotalsForBillingItem(item) {
    const quantity = item.quantity || 0;
    const unitPrice = item.unitPrice || 0;
    const taxRate = item.vatRate || 0;
    const amount = formatting.currency.round(quantity * unitPrice);
    const vatAmount = getVatAmountFromNetAmountAndVatRate(amount, taxRate);
    return {
        amount,
        vatAmount,
    };
}

function getTotalForPayments(payments) {
    if (isEmptyOrNotSet(payments)) return 0;
    return sumAmounts(...payments.map(p => p.amount));
}

function getGrossAmountForBilling(billing) {
    const { amount = 0, vatAmount = 0 } = billing || {};
    return sumAmounts(amount, vatAmount);
}

function getGrossTotalCreditedAmountForInvoice(invoice) {
    const { totalCredited = 0, totalVatCredited = 0 } = invoice || {};
    return sumAmounts((Math.abs(totalCredited)), (Math.abs(totalVatCredited)));
}
function getGrossTotalInvoicedAmountForQuote(quote) {
    const { totalInvoiced = 0, totalVatInvoiced = 0 } = quote || {};
    return sumAmounts(totalInvoiced, totalVatInvoiced);
}

function getExactAmountForInvoice(invoice) {
    const grossAmount = getGrossAmountForBilling(invoice);
    const grossCredited = getGrossAmountForBilling(invoice);
    return sumAmounts(grossAmount, -grossCredited);
}

function getVatAmountFromNetAmountAndVatRate(netAmount, vatRate) {
    if (!isset(netAmount) || !isNumber(netAmount) || netAmount === 0) return 0;
    if (!isset(vatRate) || !isNumber(vatRate) || vatRate === 0) return 0;
    return formatting.currency.round(netAmount * vatRate);
}

function getGrossAmountFromNetAmountAndVatAmount(netAmount, vatAmount) {
    return sumAmounts(netAmount, vatAmount);
}

function getGrossAmountFromNetAmountAndVatRate(netAmount, vatRate) {
    if (!isset(netAmount) || !isNumber(netAmount) || netAmount === 0) return 0;
    return getGrossAmountFromNetAmountAndVatAmount(netAmount, getVatAmountFromNetAmountAndVatRate(netAmount, vatRate));
}

function getVatAmountFromNetAmountAndGrossAmount(netAmount, grossAmount) {
    if (!isset(netAmount) || !isNumber(netAmount) || netAmount === 0) return 0;
    if (!isset(grossAmount) || !isNumber(grossAmount) || grossAmount === 0) return 0;
    return sumAmounts(grossAmount, -netAmount);
}

function getVatRateFromNetAmountAndVatAmount(netAmount, vatAmount) {
    if (!isset(netAmount) || !isNumber(netAmount) || netAmount === 0) return 0;
    if (!isset(vatAmount) || !isNumber(vatAmount) || vatAmount === 0) return 0;
    return formatting.percent.round(vatAmount / netAmount);
}

function getVatRateFromNetAmountAndGrossAmount(netAmount, grossAmount) {
    if (!isset(netAmount) || !isNumber(netAmount) || netAmount === 0) return 0;
    const vatAmount = getVatAmountFromNetAmountAndGrossAmount(netAmount, grossAmount);
    return getVatRateFromNetAmountAndVatAmount(netAmount, vatAmount);
}

function getRatioBetweenValues(value, totalValue) {
    if (!isset(totalValue) || !isNumber(totalValue) || totalValue === 0) return 0;
    if (!isset(value) || !isNumber(value) || value === 0) return 0;
    return formatting.percent.round(value / totalValue);
}

function getValueForRatio(ratio, totalValue) {
    if (!isset(totalValue) || !isNumber(totalValue) || totalValue === 0) return 0;
    if (!isset(ratio) || !isNumber(ratio) || ratio === 0) return 0;
    return formatting.currency.round(totalValue * ratio);
}

function sumAmounts(...value) {
    const total = value.reduce((total, value) => total + (value || 0), 0);
    return formatting.currency.round(total);
}

module.exports = {
    getVatAmountFromNetAmountAndVatRate,
    getGrossAmountFromNetAmountAndVatRate,
    getVatAmountFromNetAmountAndGrossAmount,
    getVatRateFromNetAmountAndVatAmount,
    getVatRateFromNetAmountAndGrossAmount,
    getGrossAmountFromNetAmountAndVatAmount,
    getRatioBetweenValues,
    getValueForRatio,
    getTotalsForBillingItem,
    getTotalsForBillingItems,
    getTotalsForBillings,
    getTotalForPayments,
    sumAmounts,
    getGrossAmountForBilling,
    getGrossTotalCreditedAmountForInvoice,
    getGrossTotalInvoicedAmountForQuote,
    getExactAmountForInvoice
};