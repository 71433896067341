import { Directive, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { CwtDirectiveView, hasSomeChanges } from '@cawita/core-front';
import { Invoice, InvoiceStatus } from '@shared/models';

@Directive({ selector: '[cwtCanPayInvoice]' })
export class CanPayInvoiceDirective implements OnChanges {
    private _view = new CwtDirectiveView(this.vcRef, this.tRef);
    @Input('cwtCanPayInvoice') invoice: Invoice;

    constructor(
        private vcRef: ViewContainerRef,
        private tRef: TemplateRef<void>
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (hasSomeChanges(changes, ['invoice'], true)) {
            const hasStatuses = [InvoiceStatus.Paid, InvoiceStatus.Finalized].includes(this.invoice?.status);
            const canPayInvoice = this.invoice.remainingDue > 0;
            this._view.ensureState(hasStatuses && canPayInvoice);
        }
    }
}