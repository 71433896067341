import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp } from "@cawita/core-front/api";
import { Company } from "../company";
import { CustomFieldTarget } from "./custom-field-target.model";
import { CustomFieldType } from "./custom-field-type.model";

@CwtModel({ name: 'custom-field' })
@CwtCrudContract({ path: 'company/:companyId/custom-field' })
export class CustomField extends CwtApiEntity {
    @CwtProp() company: Company;
    @CwtProp() target: CustomFieldTarget;
    @CwtProp() type: CustomFieldType;
    @CwtProp() name: string;
    @CwtProp() enumValues: string[];
    @CwtProp() isMultiSelect: boolean;
    @CwtProp() isActive: boolean;
}