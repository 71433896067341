import { Injectable, inject } from '@angular/core';
import { getId } from '@cawita/core-front';
import { CwtApiService } from '@cawita/core-front/api';
import { Observable } from 'rxjs';
import { CompanyTemplateUseCase } from '../../../../models';
import { MailActionHandler, MailPreviewOptions, MailSendOptions } from '../../../../modules/mailing';

@Injectable({ providedIn: 'root' })
export class SendQuoteMailAdapter extends MailActionHandler {
    protected api = inject(CwtApiService);

    getTemplatesUseCases() { return [CompanyTemplateUseCase.SendQuote, CompanyTemplateUseCase.SendQuoteAttachment]; }

    preview(options: MailPreviewOptions): Observable<any> {
        return this.api.post(`company/:companyId/quote/${getId(options.params.quote)}/action/preview`, {
            template: getId(options.template)
        });
    }

    send(options: MailSendOptions): Observable<any> {
        return this.api.post(`company/:companyId/quote/${getId(options.params.quote)}/action/send`, {
            emails: options.emails,
            template: getId(options.template),
            content: options.content,
            subject: options.subject
        });
    }
}