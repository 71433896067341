import { Inject, Injectable } from '@angular/core';
import { getId } from '@cawita/core-front';
import { CwtCrudContract, CwtCrudService } from '@cawita/core-front/api';
import { hasRequiredPermission } from '@cawita/core-front/auth';
import { CwtStore } from '@cawita/core-front/state';
import { CompanyAccess, Reporter } from '@shared/models';
import { from, lastValueFrom, tap } from 'rxjs';

export type ReportingState = {
  activeReporter?: Reporter;
  hasReportingAccess: boolean;
  visibleReporterCount: number;
}

@Injectable({
  providedIn: 'root'
})
export class ReportingStore extends CwtStore<ReportingState> {
  private _companyAccess: CompanyAccess;

  constructor(
    @Inject(CwtCrudContract.get(Reporter, 'accessible')) private reporterCrud: CwtCrudService<Reporter>,
  ) {
    super({
      activeReporter: null,
      visibleReporterCount: 0,
      hasReportingAccess: false,
      initialized: false,
      loading: false,
    })
  }

  public refresh() {
    if (!this._companyAccess) return;
    this.init(this._companyAccess).subscribe();
  }

  public init(companyAccess: CompanyAccess) {
    this._companyAccess = companyAccess;
    return from(this._getData(companyAccess)).pipe(
      tap(res => this.setState(c => ({
        ...c,
        initialized: true,
        loading: false,
        activeReporter: res.activeReporter,
        visibleReporterCount: res.visibleReporterCount,
        hasReportingAccess: res.hasReportingAccess
      })))
    );
  }

  private async _getData(companyAccess: CompanyAccess) {
    const activeReporter = await lastValueFrom(this.reporterCrud.getOne({ user: getId(companyAccess.user) }));
    const numberOfReporters = await lastValueFrom(this.reporterCrud.count({}));
    return { activeReporter, hasReportingAccess: numberOfReporters > 0, visibleReporterCount: numberOfReporters };
  }

  public clear() {
    this.setState({
      activeReporter: null,
      visibleReporterCount: 0,
      hasReportingAccess: false,
      initialized: false,
      loading: false,
    })
  }
}
