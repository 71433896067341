import { isset } from "@cawita/core-front";
import { User } from "./user.model";
import { compareDesc, isAfter, isBefore } from "date-fns";

export type StatusDateData<Meta = {}> = Meta & {
    date: Date;
    user: User;
};

export type StatusDates<T extends string | number, Meta = {}> = {
    [key in T]: StatusDateData<Meta>;
}

export class StatusDatesProxy {
    constructor(data: StatusDates<any, any>) {
        return Object.keys(data).reduce<StatusDates<any, any>>((map, status) => {
            map[status] = {
                ...data[status],
                date: new Date(data[status].date),
                user: new User(data[status].user),
            };

            return map;
        }, {});
    }
}

export function getStatusDate<T extends string | number>(values: StatusDates<T, any>, status: T): Date | null {
    return status ? values[status]?.date ?? null : null;
}
export function getLastStatusBetween<T extends string | number>(values: StatusDates<T, any>, start: Date, end: Date): T {
    const statusSorted = Object.entries<StatusDateData<any>>(values)
        .map(([status, value]) => ({ status, date: value.date }))
        .filter(entry => isset(entry.date) && !isBefore(entry.date, start) && !isAfter(entry.date, end))
        .sort((entryA, entryB) => compareDesc(entryA.date, entryB.date));
    return statusSorted[0]?.status as T;
}