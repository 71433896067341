import { CwtApiEntity, CwtApiSchema, CwtProp, CwtSchema } from "@cawita/core-front/api";

export enum FrequencyType {
    DaysOfWeek = 'daysOfWeek',
    DaysOfMonth = 'daysOfMonth',
    DayOfYear = 'dayOfYear',
    Expression = 'expression',
}

export enum RecurringType {
    Frequencies = 'frequencies',
    Dates = 'dates',
    Rules = 'rules'
}

export enum RulesFrequency {
    Yearly = 'yearly',
    Monthly = 'monthly',
    Weekly = 'weekly',
    Daily = 'daily'
}

export const FrequencyTypeFieldMap: Record<FrequencyType, keyof FrequencySetting> = {
    [FrequencyType.DaysOfWeek]: 'daysOfWeek',
    [FrequencyType.DaysOfMonth]: 'daysOfMonth',
    [FrequencyType.DayOfYear]: 'dayOfYear',
    [FrequencyType.Expression]: 'expression',
};


export const RecurringTypeFieldMap: Record<RecurringType, keyof RecurringConfig> = {
    [RecurringType.Dates]: 'dates',
    [RecurringType.Rules]: 'rules',
    [RecurringType.Frequencies]: 'frequencies',
};

@CwtSchema()
export class FrequencySetting extends CwtApiSchema {
    @CwtProp() type: FrequencyType;
    @CwtProp() daysOfWeek: number[];
    @CwtProp() daysOfMonth: number[];
    @CwtProp() dayOfYear: {
        day: number,
        month: number
    };
    @CwtProp() expression: string;
}
export type RulesSettingWeekday = { day: number, offset: number };

@CwtSchema()
export class RulesSetting extends CwtApiSchema {
    @CwtProp() frequency: RulesFrequency;
    @CwtProp() count: number;
    @CwtProp() interval: number;
    @CwtProp() months?: number[];
    @CwtProp() days?: number[];
    @CwtProp() weekdays?: RulesSettingWeekday[];
}


@CwtSchema()
export class RecurringConfig extends CwtApiEntity {
    @CwtProp() startDate: number;
    @CwtProp() endDate: number;
    @CwtProp() isActive: boolean;
    @CwtProp() type: RecurringType;
    @CwtProp() dates: number[];
    @CwtProp({ ref: FrequencySetting }) frequencies: FrequencySetting[];
    @CwtProp({ ref: RulesSetting }) rules: RulesSetting;
    @CwtProp({ ref: Date }) lastRanDate: Date;
    @CwtProp({ ref: Date }) nextRunDate: Date;
}