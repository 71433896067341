import { AccessTokenConstraintHandler } from "@shared/services/utils/mail/constraints/access-token-constraint.handler";
import { MailAction, MailingConfig } from "../modules/mailing";
import { IsVisibleConstraintHandler, SendInvoiceMailAdapter, SendQuoteMailAdapter } from "../services/utils/mail";

export const CwtMailingConfig: MailingConfig = {
    adapters: {
        [MailAction.SendQuote]: {
            handler: SendQuoteMailAdapter,
            constraints: {
                accessToken: AccessTokenConstraintHandler,
                isVisible: IsVisibleConstraintHandler,
            }
        },
        [MailAction.SendInvoice]: {
            handler: SendInvoiceMailAdapter,
            constraints: {
                accessToken: AccessTokenConstraintHandler,
                isVisible: IsVisibleConstraintHandler,
            }
        }
    }
}