import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp } from "@cawita/core-front/api";
import { Company } from "../company";

@CwtModel({ name: 'business-type' })
@CwtCrudContract({
    path: 'company/:companyId/business-type',
    searchMapper: (s) => ({ name: { regexp: s } }),
})

export class BusinessType extends CwtApiEntity {
    @CwtProp({ ref: Company }) company: Company;
    @CwtProp() name: string;
}
