const { isset } = require('@hints/utils/data');
const { isNumber, isString } = require('@hints/utils/types');
const { round_to_decimal_place, parse_formatted_number } = require('./intl-utils');

/**
 * Decimal constant to round values
 */
const DECIMAL_PLACES = 2;

/**
 * Rounds a percentage (0 - 1) value to have max N places when normalized to 0-100 format
 * @param {number | null | undefined} value value to round
 * @return {number} rounded value
 */
function round_percent(value) {
    if (!isset(value)) return 0;
    if (!isNumber(value)) return 0;
    return round_to_decimal_place(value, DECIMAL_PLACES + 2);
}

function round_non_normalized(value) {
    if (!isset(value)) return 0;
    if (!isNumber(value)) return 0;
    return round_to_decimal_place(value, DECIMAL_PLACES);
} 

/**
 * Formats a percentage value (0-1) value to have max N places when normalized to 0-100 format
 * @param {number | null | undefined} value value to format
 * @param {string} locale locale in which to format the value (defaults to fr-FR)
 * @return {string} formatted percent
 */
function format_percent(value, locale = 'fr-FR') {
    const formatter = create_percent_formatter(locale);
    return formatter.format(round_percent(value));
}

function parse_percent(value, locale = 'fr-FR') {
    if (!isset(value)) return undefined;
    if (isNumber(value)) return value;
    if (!isString(value)) return undefined;
    const formatter = create_percent_formatter(locale);
    const parsed = parse_formatted_number(formatter, value, 11.111111, {
        group: '',
        symbol: '',
        literal: '',
        plusSign: '',
        minusSign: '-',
        percentSign: '',
        decimal: '.'
    });

    return round_percent(parsed / 100);
}

module.exports = {
    round: round_percent,
    round_non_normalized: round_non_normalized,
    format: format_percent,
    parse: parse_percent,
};

function create_percent_formatter(locale = 'fr-FR') {
    return new Intl.NumberFormat(locale, {
        style: 'percent',
        minimumFractionDigits: 0,
        maximumFractionDigits: DECIMAL_PLACES,
        signDisplay: 'auto',
    });
}