import { ChangeDetectorRef, Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { CwtDirectiveView } from '@cawita/core-front';
import { isEmpty } from '@hints/utils/data';
import { CompanyConfig } from '@shared/config/company.config';
import { Subscription } from 'rxjs';
import { CompanyStore } from '../company.store';

export type CompanyVatRatesContext = {
    $implicit: number[];
}

@Directive({ selector: '[cwtCompanyVatRates]' })
export class CompanyVatRatesDirective {
    private _sub: Subscription;
    private _ctx: CompanyVatRatesContext = { $implicit: [] };
    private _view = new CwtDirectiveView(this.vcRef, this.tRef, this._ctx);

    constructor(
        private tRef: TemplateRef<CompanyVatRatesContext>,
        private vcRef: ViewContainerRef,
        private cdRef: ChangeDetectorRef,
        private companyStore: CompanyStore
    ) { }

    ngOnDestroy(): void {
        this._sub?.unsubscribe();
    }

    ngOnInit(): void {
        this._sub = this.companyStore.stableState$.subscribe({
            next: (state) => {
                const companyVatRates = state.activeCompany.vatRates;
                if (!!companyVatRates?.length) this._ctx.$implicit = companyVatRates.sort().reverse();
                else this._ctx.$implicit = CompanyConfig.defaultVatRates.sort().reverse();
                this._view.ensureState(true);
                this.cdRef.markForCheck();
            }
        });
    }

}