import { Directive, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { CwtDirectiveView, asArray, hasSomeChanges } from '@cawita/core-front';
import { Quote, QuoteStatus } from '@shared/models';
import { AnyEnumValue } from '@shared/utils/types.utils';


@Directive({
  selector: '[cwtIfQuoteStatus]'
})
export class IfQuoteStatusDirective implements OnChanges {
  private _view = new CwtDirectiveView(this.vcRef, this.tRef);

  @Input('cwtIfQuoteStatus') quote: Quote;
  @Input('cwtIfQuoteStatusStatus') quoteStatus: AnyEnumValue<QuoteStatus> | AnyEnumValue<QuoteStatus>[];

  constructor(
    private vcRef: ViewContainerRef,
    private tRef: TemplateRef<void>
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (hasSomeChanges(changes, ['quote', 'quoteStatus'], true)) {
      const statuses = asArray(this.quoteStatus) ?? [];
      const matchStatus = statuses.some(s => s === this.quote?.status);
      this._view.ensureState(matchStatus);
    }
  }

}
