import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CwtNzSelectModule } from '@cawita/core-front/zorro';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { QuillModule } from 'ngx-quill';
import { SharedCommonModule, SharedFormModule, SharedLayoutModule } from '..';
import { SharedMediaModule } from '../../widgets/features/common/media';
import { MailRecipientsInputComponent } from './mail-recipients-input/mail-recipients-input.component';
import { ContactRecipientComponent } from './mail-recipients-input/recipient-displays/contact-recipient.component';
import { UserRecipientComponent } from './mail-recipients-input/recipient-displays/user-recipient.component';
import { MailTemplateInputComponent } from './mail-template-input/mail-template-input.component';
import { MailingFooterComponent, MailingHeaderComponent, MailingModalComponent } from './mailing-modal';
import { MAILING_CONFIG, MailingConfig } from './providers/mailing-config.provider';
import { SendMailDirective } from './send-mail.directive';
import { RecipientOptionComponent } from './mail-recipients-input/recipient-displays/recipient-option.component';

@NgModule({
  declarations: [
    MailingModalComponent,
    SendMailDirective,
    MailingHeaderComponent,
    MailingFooterComponent,
    ContactRecipientComponent,
    MailRecipientsInputComponent,
    MailTemplateInputComponent,
    UserRecipientComponent,
    RecipientOptionComponent
  ],
  imports: [
    CommonModule,
    CwtNzSelectModule,
    SharedLayoutModule,
    SharedCommonModule,
    SharedFormModule,
    SharedMediaModule,
    QuillModule,
    NzModalModule
  ],
  exports: [
    SendMailDirective,
    MailRecipientsInputComponent,
    MailTemplateInputComponent,
  ]
})
export class SharedMailingModule {
  static forRoot(config: MailingConfig): ModuleWithProviders<SharedMailingModule> {
    return {
      ngModule: SharedMailingModule,
      providers: [
        { provide: MAILING_CONFIG, useValue: config }
      ]
    };
  }
}
