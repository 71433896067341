import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CanInvoiceQuoteDirective, CanPayInvoiceDirective, HasInvoicePaymentDirective, HasQuoteInvoicesDirective } from './directives';
import { BillingRouterLinkDirective } from './directives/billing-router-link.directive';
import { IfInvoicePaymentActiveDirective } from './directives/invoice';
import { HasQuoteRecurringInvoiceDirective, IfQuoteInvoiceActiveDirective, IfQuoteStatusDirective } from './directives/quote';
import { BillingItemTotalPipe, QuoteOptionItemsPipe } from './pipes';

@NgModule({
  declarations: [
    CanPayInvoiceDirective,
    HasInvoicePaymentDirective,
    CanInvoiceQuoteDirective,
    HasQuoteInvoicesDirective,
    IfInvoicePaymentActiveDirective,
    IfQuoteInvoiceActiveDirective,
    HasQuoteRecurringInvoiceDirective,
    BillingRouterLinkDirective,
    QuoteOptionItemsPipe,
    IfQuoteStatusDirective,
    BillingItemTotalPipe,
  ],
  exports: [
    CanPayInvoiceDirective,
    HasInvoicePaymentDirective,
    CanInvoiceQuoteDirective,
    HasQuoteInvoicesDirective,
    IfInvoicePaymentActiveDirective,
    IfQuoteInvoiceActiveDirective,
    HasQuoteRecurringInvoiceDirective,
    BillingRouterLinkDirective,
    QuoteOptionItemsPipe,
    IfQuoteStatusDirective,
    BillingItemTotalPipe,
  ],
  imports: [
    CommonModule,
  ]
})
export class SharedBillingModule { }
