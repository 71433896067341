import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { findByAddress } from '@hints/utils/data';
import { firstValueFrom } from 'rxjs';
import { CompanyStore } from '../company.store';
import { CwtAuthStore } from '@cawita/core-front/auth';

export function CompanyActivationGuard(paramPath: string): CanActivateFn {
    return async (route, state) => {
        const router = inject(Router);
        const auth = inject(CwtAuthStore);
        const companyStore = inject(CompanyStore);
        const companyId = findByAddress(route, paramPath);
        if (!auth.isLoggedIn || !companyId) return router.createUrlTree(['/']);
        try {
            const success = await firstValueFrom(companyStore.activate(companyId));
            if (success) return true;
            else return router.createUrlTree(['/']);
        } catch (err) {
            return router.createUrlTree(['/']);
        }
    };
}

export function CompanyDeactivationGuard(): CanActivateFn {
    return async (route, state) => {
        const router = inject(Router);
        const auth = inject(CwtAuthStore);
        const companyStore = inject(CompanyStore);
        if (!auth.isLoggedIn) return router.createUrlTree(['/']);
        companyStore.deactivate();
        return true;
    };
}