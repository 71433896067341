import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CustomFieldInput } from '../custom-field-input.directive';
import { CommonModule } from '@angular/common';
import { SharedFormModule } from '@shared/modules/form';
import { CustomFieldType } from '@shared/models';
import { isNumber, isString } from '@hints/utils/types';

@Component({
  selector: 'cwt-custom-field-simple-input',
  templateUrl: './custom-field-simple-input.component.html',
  styleUrls: ['./custom-field-simple-input.component.less'],
  standalone: true,
  imports: [
    CommonModule,
    SharedFormModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomFieldSimpleInputComponent extends CustomFieldInput<number | string> {
  protected formatValue(v: any): number | string {
    if (this.customField.type === CustomFieldType.Number) {
      if (isNumber(v)) return v;
      return Number(v);
    }

    if (isString(v)) return v;
    return String(v);
  }
}
