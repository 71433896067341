import { ChangeDetectorRef, Directive, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { CwtDirectiveView, hasSomeChanges, isset } from '@cawita/core-front';
import { NzBreakpointKey, NzBreakpointService, gridResponsiveMap } from 'ng-zorro-antd/core/services';
import { Subscription } from 'rxjs';

const NzBreakpointOrder: NzBreakpointKey[] = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl']

export type IfBreakpointContext = {
    $implicit: boolean;
    cwtIsMaxBreakpoint: boolean;
    cwtIsMinBreakpoint: boolean;
}

@Directive({ selector: '[cwtIfMinBreakpoint],[cwtIfMaxBreakpoint],[cwtIsMaxBreakpoint],[cwtIsMinBreakpoint]' })
export class IfBreakpointDirective implements OnInit, OnChanges, OnDestroy {
    private _ctx: IfBreakpointContext = { $implicit: false, cwtIsMaxBreakpoint: false, cwtIsMinBreakpoint: false };
    private _view = new CwtDirectiveView<any>(this.vcRef, this.tRef, this._ctx);
    private _else = new CwtDirectiveView<any>(this.vcRef, null, this._ctx);
    private _currentBreakpoint: NzBreakpointKey;
    private _sub: Subscription;

    @Input('cwtIfMinBreakpoint') ifBreakpointMin?: NzBreakpointKey = null;
    @Input('cwtIfMaxBreakpoint') ifBreakpointMax?: NzBreakpointKey = null;
    @Input('cwtIsMinBreakpoint') isBreakpointMin?: NzBreakpointKey = null;
    @Input('cwtIsMaxBreakpoint') isBreakpointMax?: NzBreakpointKey = null;

    @Input('cwtIfMinBreakpointElse') minElse: TemplateRef<any>;
    @Input('cwtIfMaxBreakpointElse') maxElse: TemplateRef<any>;

    private _currentMatch: boolean = null;

    constructor(
        private vcRef: ViewContainerRef,
        private tRef: TemplateRef<IfBreakpointContext>,
        private bp: NzBreakpointService,
    ) { }

    ngOnInit(): void {
        this._else.setTemplate(this.minElse ?? this.maxElse);
        this._sub = this.bp.subscribe(gridResponsiveMap).subscribe((breakpoint) => {
            this._currentBreakpoint = breakpoint;
            this._updateView();
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (hasSomeChanges(changes, ['minElse', 'maxElse'], true)) {
            this._else.setTemplate(this.minElse ?? this.maxElse);
            this._updateView();
        }
    }

    ngOnDestroy(): void {
        this._sub?.unsubscribe();
    }

    private _updateView() {
        if (isset(this.ifBreakpointMin) || isset(this.isBreakpointMin)) this._updateViewWithMinBreakpoint();
        else if (isset(this.ifBreakpointMax) || isset(this.isBreakpointMax)) this._updateViewWithMaxBreakpoint();
        else this._view.ensureState(true);
    }

    private _updateViewWithMinBreakpoint() {
        const currentSize = NzBreakpointOrder.indexOf(this._currentBreakpoint);
        const minSize = NzBreakpointOrder.indexOf(this.ifBreakpointMin);
        const matches = currentSize >= minSize;
        this._ctx.$implicit = this._ctx.cwtIsMinBreakpoint = matches;
        if (!isset(this.ifBreakpointMin)) this._view.ensureState(true);
        else if (matches !== this._currentMatch) {
            this._currentMatch = matches;
            this._view.ensureState(matches);
            this._else.ensureState(!matches);
        }
    }

    private _updateViewWithMaxBreakpoint() {
        const currentSize = NzBreakpointOrder.indexOf(this._currentBreakpoint);
        const maxSize = NzBreakpointOrder.indexOf(this.ifBreakpointMax);
        const matches = currentSize < maxSize;
        this._ctx.$implicit = this._ctx.cwtIsMaxBreakpoint = matches;
        if (!isset(this.ifBreakpointMax)) this._view.ensureState(true);
        else if (matches !== this._currentMatch) {
            this._currentMatch = matches;
            this._view.ensureState(matches);
            this._else.ensureState(!matches);
        }
    }
}