import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp } from "@cawita/core-front/api";
import { Quote } from "../billing";
import { StatusDates, StatusDatesProxy, User } from "../common";
import { Company } from "../company";
import { Customer } from "../customer";
import { BudgetAmount } from "./budget-amount.model";

const ProjectSearchMapper = (s) => ({
    or: [
        { name: { regexp: s } },
    ]
});

export enum ProjectStatus {
    Draft = 'draft',
    Planned = 'planned',
    Ongoing = 'ongoing',
    Finished = 'finished'
}

@CwtModel({ name: 'project' })
@CwtCrudContract({ path: 'company/:companyId/project', searchMapper: ProjectSearchMapper })
export class Project extends CwtApiEntity {
    @CwtProp({ ref: Company }) company: Company;
    @CwtProp() name: string;
    @CwtProp({ ref: User }) administrators: User[];
    @CwtProp({ ref: Quote }) quotes: Quote[];
    @CwtProp({ ref: Customer }) customers: Customer[];
    @CwtProp() status: ProjectStatus;
    @CwtProp({ ref: StatusDatesProxy }) statusDates: StatusDates<ProjectStatus>;
    @CwtProp() budgeted: BudgetAmount;
    @CwtProp() remaining: BudgetAmount;
    @CwtProp() consumed: BudgetAmount;
    @CwtProp() allocatedBudget: number;
    @CwtProp() pipeOrder: number;
}