import { Injectable } from '@angular/core';
import { NavigationExtras, UrlCreationOptions } from '@angular/router';
import { getId } from '@cawita/core-front';
import { isEmpty } from '@hints/utils/data';
import { Invoice, Quote, RecurringInvoice } from '@shared/models';
import { CompanyRouter } from '@shared/modules/company';
import { formatCommands } from '@shared/utils/router.utils';
import { Subscription } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BillingRouter {
    constructor(private companySvc: CompanyRouter) { }

    public createBillingRouterWatcher(
        commands: string | string[] | undefined,
        routeChanged: (absolutePath: string[]) => void
    ): Subscription {
        const billingCommands = this._formatRedirectUrl(commands);
        return this.companySvc.createCompanyRouterWatcher(billingCommands, routeChanged);
    }

    public navigateToInvoice(invoice: Invoice | string, extras?: NavigationExtras) { return this.navigate(['invoice', getId(invoice)], extras); }
    public navigateToQuote(quote: Quote | string, extras?: NavigationExtras) { return this.navigate(['quote', getId(quote)], extras); }
    public navigateToRecurringInvoice(recurringInvoice: RecurringInvoice | string, extras?: NavigationExtras) { return this.navigate(['invoice', 'recurring', getId(recurringInvoice)], extras); }

    public navigate(commands: any[], extras?: NavigationExtras) {
        const path = this._formatRedirectUrl(commands);
        return this.companySvc.navigate(path, extras);
    }

    public createUrlTree(commands: any[], extras?: UrlCreationOptions) {
        const path = this._formatRedirectUrl(commands);
        return this.companySvc.createUrlTree(path, extras);
    }

    private _formatRedirectUrl(commands?: string | string[]) {
        const saneCommands = formatCommands(commands);
        if (isEmpty(saneCommands)) return null;
        return ['/billing', ...saneCommands];
    }
}