import { Routes } from "@angular/router";
import { LoggedInGuard } from "@shared/modules";

export const AppRoutes: Routes = [
    {
        path: 'auth',
        loadChildren: () => import('./pages/auth/auth.routes')
    },
    {
        path: 'invitation',
        canActivate: [LoggedInGuard()],
        loadComponent: () => import('./pages/invitation/invitation.page')
    },
    {
        path: '',
        canActivate: [LoggedInGuard()],
        loadChildren: () => import('./pages/main/main.routes')
    },
    { path: '**', pathMatch: 'full', redirectTo: '/' }
];