import { InjectionToken, Type } from "@angular/core";
import { CustomField, CustomFieldType } from "@shared/models";

export const CUSTOM_FIELDS_CONFIG = new InjectionToken<ICustomFieldsConfig>('cwt-custom-fields-config');

export type ICustomFieldsConfig = {
    adapters: {
        [T in CustomFieldType]: {
            displayer: Type<any>,
            input: Type<any>,
            cell?: Type<any>
        };
    };
}

export const CUSTOM_FIELD = new InjectionToken<CustomField>('cwt-custom-field');