import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp } from "@cawita/core-front/api";
import { Company } from "../company";
import { Customer } from "../customer";
import type { CompanyProduct } from "./company-product.model";
import type { CompanyProductType } from "./company-product-type.model";


@CwtModel({ name: 'customer-nego-price' })
@CwtCrudContract({ path: 'company/:companyId/customer-nego-price' })
export class CustomerNegoPrice extends CwtApiEntity {
    @CwtProp({ ref: Company }) company: Company;
    @CwtProp({ ref: Customer }) customer: Customer;
    @CwtProp({ ref: 'company-product' }) product: CompanyProduct;
    @CwtProp({ ref: 'company-product-type' }) productType: CompanyProductType;
    @CwtProp() unitPrice: number;
}