import { Injectable } from '@angular/core';
import { CwtCurrencyService } from '@cawita/core-front';
import { isNumber, isString } from '@hints/utils/types';
import { Formatting } from '@server-shared';

@Injectable()
export class CustomCurrencyService extends CwtCurrencyService {
    format(value: string | number, currencyCode?: string, digitsInfo: string = '1.0-2', format?: 'wide' | 'narrow'): string {
        if (isNumber(value)) value = Formatting.currency.round(value);
        if (isString(value)) value = Formatting.currency.round(Number(value));
        return super.format(value, currencyCode, digitsInfo, format);
    }
}