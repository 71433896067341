import { QuillConfig as _QuillConfig } from "ngx-quill";
import Quill, { StringMap } from "quill";
import { IndentStyle } from "./quill/indent-style";

const AlignStyle: StringMap = Quill.import('attributors/style/align');
const BackgroundStyle: StringMap = Quill.import('attributors/style/background');
const ColorStyle: StringMap = Quill.import('attributors/style/color');
const DirectionStyle: StringMap = Quill.import('attributors/style/direction');
const FontStyle: StringMap = Quill.import('attributors/style/font');
const SizeStyle: StringMap = Quill.import('attributors/style/size');

Quill.register(AlignStyle, true);
Quill.register(BackgroundStyle, true);
Quill.register(ColorStyle, true);
Quill.register(DirectionStyle, true);
Quill.register(FontStyle, true);
Quill.register(SizeStyle, true);
Quill.register(IndentStyle, true);

export const QuillConfig: _QuillConfig = {
    sanitize: false,
    theme: 'snow',
    format: 'html',
    defaultEmptyValue: '',
    placeholder: '',
    modules: {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'align': [] }],
            ['link', 'image'],
            ['clean'],
        ]
    }
}