import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Inject, Injectable } from '@angular/core';
import { CwtStorageService } from '@cawita/core-front';
import { isEmptyOrNotSet } from '@hints/utils/data';
import { CUSTOMER_CONFIG, ICustomerConfig } from './customer.config';

@Injectable({ providedIn: 'root' })
export class CustomerTrackingService {
    constructor(
        private storage: CwtStorageService,
        @Inject(CUSTOMER_CONFIG) private config: ICustomerConfig
    ) { }

    public isTrackingEnabled(token: string) {
        if (!this.config.useTrackingInterceptor || isEmptyOrNotSet(token)) return true;
        const windowURL = new URL(window.location.href);
        const doNotTrack = windowURL?.searchParams?.get(this.config.trackingParam);
        if (!isEmptyOrNotSet(doNotTrack)) {
            const tracking = coerceBooleanProperty(doNotTrack);
            return this._persist(token, tracking);
        }
        return this._getPersisted(token);
    }

    private _persist(token: string, trackingEnabled: boolean) {
        if (isEmptyOrNotSet(token)) return true;
        this.storage.setBoolean(`cwt-customer-tracking-${token}`, trackingEnabled);
        return trackingEnabled;
    }

    private _getPersisted(token: string): boolean {
        if (isEmptyOrNotSet(token)) return true;
        return this.storage.getBoolean(`cwt-customer-tracking-${token}`, true);
    }
}