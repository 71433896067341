import { Injectable, inject } from '@angular/core';
import { getId } from '@cawita/core-front';
import { CwtApiService } from '@cawita/core-front/api';
import { isset } from '@hints/utils/data';
import { CompanyTemplateUseCase } from '@shared/models';
import { MailActionHandler, MailParams, MailPreviewOptions, MailSendOptions } from '@shared/modules/mailing';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SendInvoiceMailAdapter extends MailActionHandler {
    protected api = inject(CwtApiService);
    getTemplatesUseCases() { return [CompanyTemplateUseCase.SendInvoice, CompanyTemplateUseCase.SendInvoiceAttachment, CompanyTemplateUseCase.SendInvoiceAndContract]; }

    canSelectTemplateUseCase(params: MailParams, template: string): boolean {
        if(template === CompanyTemplateUseCase.SendInvoiceAndContract) return isset(params?.invoice?.machineMove);
        return true;
    }    

    preview(options: MailPreviewOptions): Observable<any> {
        return this.api.post(`company/:companyId/invoice/${getId(options.params.invoice)}/action/preview`, {
            template: getId(options.template)
        });
    }

    send(options: MailSendOptions): Observable<any> {
        return this.api.post(`company/:companyId/invoice/${getId(options.params.invoice)}/action/send`, {
            emails: options.emails,
            template: getId(options.template),
            content: options.content,
            subject: options.subject
        });
    }
}