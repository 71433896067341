import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp } from "@cawita/core-front/api";
import type { Invoice } from "../billing";
import { Company } from "../company";
import type { Customer } from "../customer";

@CwtModel({ name: 'payment' })
@CwtCrudContract({ path: 'company/:companyId/payment' })
export class Payment extends CwtApiEntity {
    @CwtProp({ ref: Company }) company: Company;
    @CwtProp({ ref: 'invoice' }) invoice: Invoice;
    @CwtProp({ ref: 'customer' }) customer: Customer;
    @CwtProp({ ref: Date }) date: Date;
    @CwtProp() amount: number;
    @CwtProp() description: string;
}