import { Injectable } from "@angular/core";
import { ICompanyListener } from "../company/company-listener";
import { ReportingStore } from "./reporting.store";
import { BestCompanyConfig, Company, CompanyAccess } from "@shared/models";

@Injectable()
export class ReportingCompanyListener implements ICompanyListener {
    constructor(private reporting: ReportingStore) { }

    onCompanyActivated(company: Company, access: CompanyAccess, config: BestCompanyConfig): void {
        if (!config?.hasReportingAccess?.value) return;
        this.reporting.init(access).subscribe();
    }
    onCompanyDeactivated(company: Company, access: CompanyAccess, config: BestCompanyConfig): void {
        if (!config?.hasReportingAccess?.value) return;
        this.reporting.clear();
    }
}