import { Injectable } from '@angular/core';
import { CwtApiService, CwtRequestConfig } from '@cawita/core-front/api';

@Injectable({ providedIn: 'root' })
export class ProspectSheetApi {
    constructor(private api: CwtApiService) { }

    public transfer(body: any, config?: CwtRequestConfig) {
        return this.api.post(`company/:companyId/prospect-sheet/transfer`, body, config);
    }

}
