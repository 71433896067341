import { Directive, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { CwtDirectiveView, hasSomeChanges } from '@cawita/core-front';
import { Invoice, InvoiceStatus, InvoiceType } from '@shared/models';

@Directive({ selector: '[cwtIfInvoicePaymentActive]' })
export class IfInvoicePaymentActiveDirective implements OnChanges {
  private _view = new CwtDirectiveView(this.vcRef, this.tRef);
  private _elseView = new CwtDirectiveView(this.vcRef, null, null);

  @Input('cwtIfInvoicePaymentActive') invoice: Invoice;
  @Input('cwtIfInvoicePaymentActiveElse') else: TemplateRef<any>;

  constructor(
    private vcRef: ViewContainerRef,
    private tRef: TemplateRef<void>
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (hasSomeChanges(changes, ['else'], true)) this._elseView.setTemplate(this.else);
    if (hasSomeChanges(changes, ['invoice'], true)) {
      const hasStatuses = [InvoiceStatus.Paid, InvoiceStatus.Finalized].includes(this.invoice?.status);
      const isInvoice = this.invoice.type !== InvoiceType.Credit;
      this._view.ensureState(isInvoice && hasStatuses);
      this._elseView.ensureState(!isInvoice || !hasStatuses);
    }
  }
}