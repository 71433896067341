import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MailRecipient } from '../../mailing-types';
import { CustomerContact } from '../../../../models';

@Component({
  selector: 'cwt-contact-recipient',
  template: `
    <cwt-image [src]="recipient?.data?.customer?.logo | cwtAsset" height="20px" ratio="1" shape="circle">
      <cwt-media-avatar-addon *cwtImageAddon="'placeholder'" [text]="recipient?.data?.customer?.name" [length]="2"></cwt-media-avatar-addon>
      <cwt-media-loading-addon *cwtImageAddon="'loading'"></cwt-media-loading-addon>
    </cwt-image>
    <span>{{recipient?.data?.firstName}} {{recipient?.data?.lastName}}</span>
  `,
  styles: [
    `:host { display: flex; gap: 4px }`,
    `cwt-image { margin-inline-start: -4px; }`
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactRecipientComponent {
  @Input() recipient: MailRecipient<CustomerContact>;
}
