import { ChangeDetectorRef, Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { CwtDirectiveView, getId, isset, sameId } from '@cawita/core-front';
import { Subscription } from 'rxjs';
import { BestCompanyConfig, Company, CompanyAccess } from '../../../models';
import { CompanyStore } from '../company.store';

export type ActiveCompanyContext = {
  $implicit: Company;
  id: string;
  access: CompanyAccess;
  config: BestCompanyConfig
}

@Directive({
  selector: '[cwtActiveCompany]'
})
export class ActiveCompanyDirective {
  static ngTemplateContextGuard(dir: ActiveCompanyDirective, ctx: any): ctx is ActiveCompanyContext { return true; }

  private _sub: Subscription;
  private _ctx: ActiveCompanyContext = { $implicit: null, id: null, access: null, config: null };
  private _view = new CwtDirectiveView(this.vcRef, this.tRef, this._ctx);
  private _elseView = new CwtDirectiveView(this.vcRef, null);

  @Input('cwtActiveCompanyElse') public set elseRef(v: TemplateRef<any>) {
    this._elseView.setTemplate(v);
  }

  constructor(
    private tRef: TemplateRef<ActiveCompanyContext>,
    private vcRef: ViewContainerRef,
    private cdRef: ChangeDetectorRef,
    private companyStore: CompanyStore
  ) { }

  ngOnDestroy(): void {
    this._sub?.unsubscribe();
  }

  ngOnInit(): void {
    this._sub = this.companyStore.stableState$.subscribe({
      next: (state) => {
        this._ctx.$implicit = state.activeCompany;
        this._ctx.id = getId(state.activeCompany);
        this._ctx.access = state.activeAccess;
        this._ctx.config = state.activeConfig;
        this._view.ensureState(isset(state.activeCompany));
        this._elseView.ensureState(!isset(state.activeCompany));
      }
    });
  }

}
