import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CwtFormModule } from '@cawita/core-front/form';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzOutletModule } from 'ng-zorro-antd/core/outlet';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { SharedCommonModule } from '../common/common.module';
import { FormCardComponent } from './form-card/form-card.component';
import { ForwardCvaDirective } from './forward-cva.directive';
import { CurrencyInputComponent } from './currency-input.directive';
import { PercentInputComponent } from './percent-input.directive';
import { FixSelectItemHeightDirective } from './fix-select-item-height.directive';
import { DatePickerRangeDirective, DatePickerRangeHandlerDirective } from './date-picker-range.directive';
import { DatePickerFormatDirective } from './date-picker-format.directive';
import { CwtNzSelectModule } from '@cawita/core-front/zorro';
import { NumberInputComponent } from './number-input.directive';

@NgModule({
  imports: [
    CommonModule,
    CwtFormModule,
    NzCardModule,
    NzOutletModule,
    NgxMaskDirective,
    NgxMaskPipe,
    ForwardCvaDirective,
    NumberInputComponent,
    CurrencyInputComponent,
    PercentInputComponent,
    FixSelectItemHeightDirective
  ],
  exports: [
    SharedCommonModule,
    ReactiveFormsModule,
    CwtFormModule,
    FormsModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NzDividerModule,
    NzGridModule,
    NzCardModule,
    NzInputModule,
    NzSelectModule,
    NzCheckboxModule,
    NzFormModule,
    NzDatePickerModule,
    NzInputNumberModule,
    FormCardComponent,
    ForwardCvaDirective,
    NumberInputComponent,
    CurrencyInputComponent,
    PercentInputComponent,
    CwtNzSelectModule,
    FixSelectItemHeightDirective,
    DatePickerRangeDirective,
    DatePickerRangeHandlerDirective,
    DatePickerFormatDirective
  ],
  declarations: [
    FormCardComponent,
    DatePickerRangeDirective,
    DatePickerRangeHandlerDirective,
    DatePickerFormatDirective
  ],
  providers: [],
})
export class SharedFormModule { }
