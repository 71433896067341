import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp } from "@cawita/core-front/api";
import { Company } from ".";
import { User } from "../common/user.model";

@CwtModel({ name: 'template-signature' })
@CwtCrudContract({ path: 'user/:userId/template-signature', identifier: 'user' })
export class CompanyTemplateSignature extends CwtApiEntity {
    @CwtProp({ ref: User }) user: User;
    @CwtProp({ ref: Company }) company: Company;
    @CwtProp() content: string;
}