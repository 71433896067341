import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp, CwtSubCrudContract } from "@cawita/core-front/api";
import { Company } from "../company/company.model";
import type { CustomerNegoPrice } from "./customer-nego-price.model";
import { Customer } from "../customer";
import { throwErrorIfFieldNotSet } from "@shared/utils/error.utils";

export function CompanyProductTypeSearchMapper(search: string) { return { name: { regexp: search } }; }

export enum CompanyProductUnit {
    m2 = 'm2',
    ml = 'ml',
    unit = 'unit',
}

@CwtModel({ name: 'company-product-type' })
@CwtCrudContract({ path: 'company/:companyId/company-product-type', searchMapper: CompanyProductTypeSearchMapper })
@CwtSubCrudContract({ parent: Customer, parentPath: 'company/:companyId/customer', subPath: 'company-product-type', searchMapper: CompanyProductTypeSearchMapper })
export class CompanyProductType extends CwtApiEntity {
    @CwtProp({ ref: Company }) company: Company;
    @CwtProp() name: string;
    @CwtProp() unit: CompanyProductUnit;
    @CwtProp() unitPrice: number;
    @CwtProp({ ref: 'customer-nego-price' }) negoPrice?: CustomerNegoPrice;

    public get hasUnitPrice(): boolean { return this.unit !== CompanyProductUnit.unit; }
    public get effectiveUnitPrice(): number { return this.negoPrice?.unitPrice ?? this.unitPrice ?? 0; }
}