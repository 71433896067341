import { Injectable } from '@angular/core';
import { CwtStore } from '@cawita/core-front/state';
import { Company, Customer } from '@shared/models';
import { ForCustomerApi } from '@shared/services/apis/for-customer.api';
import { EMPTY, forkJoin, of, tap } from 'rxjs';
import { CustomerTrackingService } from './customer-tracking.service';

export type CustomerState = {
    token: string;
    trackingEnabled: boolean;
    customer: Customer;
    company: Company;
}

@Injectable({ providedIn: 'root' })
export class CustomerStore extends CwtStore<CustomerState> {
    constructor(
        private track: CustomerTrackingService,
        private forCustomerApi: ForCustomerApi
    ) {
        super({
            company: null,
            customer: null,
            token: null,
            trackingEnabled: true,
            initialized: false,
            loading: false,
        })
    }

    public init(token: string) {
        const trackingEnabled = this.track.isTrackingEnabled(token);
        return forkJoin([
            this.forCustomerApi.getCompany(token),
            this.forCustomerApi.getCustomer(token)
        ]).pipe(tap(([company, customer]) => this.setState({
            loading: false,
            initialized: true,
            token: token,
            trackingEnabled,
            company: company,
            customer: customer
        })));
    }

}