import { Injectable } from '@angular/core';
import { getId } from '@cawita/core-front';
import { CwtApiService, CwtRequestConfig } from '@cawita/core-front/api';
import { BusinessType, Opportunity, OpportunityStatus, OpportunityTypes, User } from '@shared/models';
import { map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OpportunityApi {
    constructor(private api: CwtApiService) { }

    public postStatus(opportunity: Opportunity, status: OpportunityStatus, statusDate: Date, config?: CwtRequestConfig) {
        return this.api.post(`company/:companyId/opportunity/${getId(opportunity)}/status`, {
            status: status,
            statusDates: { date: statusDate }
        }, config).pipe(
            map(opportunity => new Opportunity(opportunity))
        );
    }

    public patchStatus(opportunity: Opportunity, status: OpportunityStatus, statusDate: Date, config?: CwtRequestConfig) {
        return this.api.patch(`company/:companyId/opportunity/${getId(opportunity)}/status`, {
            status: status,
            statusDates: { date: statusDate }
        }, config).pipe(
            map(opportunity => new Opportunity(opportunity))
        );
    }

    public getUserPercentage(user: User, type: OpportunityTypes, businessType: BusinessType, config?: CwtRequestConfig) {
        return this.api.get(`company/:companyId/opportunity/user-percentage`, {}, {
            ...(config),
            options: {
                ...(config?.options ?? {}),
                user: getId(user),
                businessType: getId(businessType),
                type: type
            }
        });
    }
}
