import { Inject, Injectable } from '@angular/core';
import { asArray, getId, isset } from '@cawita/core-front';
import { CwtApiService, CwtCrudContract, CwtCrudService, CwtSubCrudContract, CwtSubCrudService } from '@cawita/core-front/api';
import { map, tap } from 'rxjs';
import { CustomerContact, Quote, QuoteItem, UploadFile } from '../../models';

@Injectable({ providedIn: 'root' })
export class QuoteApi {
    constructor(
        private api: CwtApiService,
        @Inject(CwtSubCrudContract.get(QuoteItem, Quote)) private itemCrud: CwtSubCrudService<QuoteItem>,
        @Inject(CwtCrudContract.get(Quote)) private crud: CwtCrudService<Quote>
    ) { }

    public getSummary(filters: any) { return this.api.get('company/:companyId/quote/summary', filters); }

    public setVisibleToCustomer(quote: Quote, isVisibleToCustomer: boolean) { return this.crud.update(getId(quote), { isVisibleToCustomer }); }
    public setCustomerContact(quote: Quote, customerContact: CustomerContact | string) { return this.crud.update(getId(quote), { customerContact: getId(customerContact) }); }


    public uploadMedia(quote: Quote, item: QuoteItem, files: File | File[]) {
        return this.itemCrud.update(getId(quote), getId(item), { medias: asArray(files) });
    }

    public deleteMedia(quote: Quote, item: QuoteItem, file: UploadFile) {
        return this.api.delete(`company/:companyId/quote/${getId(quote)}/quote-item/${getId(item)}/media/${getId(file)}`).pipe(
            map(res => new QuoteItem(res))
        );
    }
}