import { ChangeDetectorRef, Directive, EventEmitter, HostListener, Input, NgZone, Output, ViewContainerRef } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { MailingModalComponent } from './mailing-modal/mailing-modal.component';
import { isset } from '@cawita/core-front';
import { Customer, Invoice, Quote } from '../../models';
import { MailAction } from './mailing-types';
import { MailingConstraintService } from './services/mailing-constraint.service';

@Directive({
  selector: '[cwtSendMail]'
})
export class SendMailDirective {

  @Input('cwtSendMail') action: MailAction;
  @Input() customer: Customer;
  @Input() quote: Quote;
  @Input() invoice: Invoice;
  @Input() sentCallback: (event: any) => void;
  @Output() sent = new EventEmitter<any>();

  constructor(
    private vcRef: ViewContainerRef,
    private modal: NzModalService,
    private constraints: MailingConstraintService,
    private cdRef: ChangeDetectorRef
  ) { }

  @HostListener('click', ['$event'])
  public onClick($event: Event) {
    $event?.stopPropagation();
    $event?.preventDefault();
    if (!isset(this.action)) throw new Error(`Missing input for directive cwtSendMail: you must pass cwtSendMail="action"`);
    this.constraints.resolveCommonConstraints(this.action, {
      customer: this.customer,
      invoice: this.invoice,
      quote: this.quote
    }, {
      constraintsResolved: () => this._openModal(),
    });
  }

  private _openModal() {
    this.cdRef.markForCheck();
    this.modal.create({
      nzWrapClassName: 'cwt-mailing-modal-wrap',
      nzClassName: 'cwt-mailing-modal',
      nzFooter: null,
      nzTitle: null,
      nzMaskClosable: true,
      nzMask: true,
      nzCloseOnNavigation: true,
      nzClosable: false,
      nzKeyboard: false,
      nzViewContainerRef: this.vcRef,
      nzZIndex: 1000,
      nzContent: MailingModalComponent,
      nzComponentParams: {
        action: this.action,
        quote: this.quote,
        invoice: this.invoice,
        customer: this.customer,
        onSent: (response) => {
          this.sentCallback?.(response);
          this.sent.emit(response);
        }
      },
    });
  }
}
