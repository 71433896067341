import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp } from "@cawita/core-front/api";
import { Company } from "./company";
import type { MachineMove } from "./machine-move.model";
import { Customer, CustomerSite } from "./customer";
import { UploadFile } from "./common";

@CwtModel({ name: 'contract' })
@CwtCrudContract({ path: 'company/:companyId/contract', searchMapper: (search) => ({ search }) })
export class Contract extends CwtApiEntity {
    @CwtProp({ ref: Company }) company: Company;
    @CwtProp({ ref: Customer }) customer: Customer;
    @CwtProp({ ref: CustomerSite }) site: CustomerSite;
    @CwtProp() date: number;
    @CwtProp({ ref: 'machine-move' }) machineMove: MachineMove;
    @CwtProp() isSigned: boolean;
    @CwtProp({ ref: UploadFile }) signedDocument: UploadFile;
}