import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CwtCommonModule, CwtCurrencyService, CwtPercentService } from '@cawita/core-front';
import { CwtAssetModule } from '@cawita/core-front/asset';
import { CwtAuthModule } from '@cawita/core-front/auth';
import { CwtStateModule } from '@cawita/core-front/state';
import { CustomCurrencyService } from '@shared/services/overrides/custom-currency.service';
import { CustomPercentService } from '@shared/services/overrides/custom-percent.service';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { SharedCompanyModule } from '../company';
import { SharedCustomerModule } from '../customer';
import { SharedUserModule } from '../user';
import { CloseDrawerDirective } from './directives/close-drawer.directive';
import { CloseModalDirective } from './directives/close-modal.directive';
import { GoBackDirective } from './directives/go-back.directive';
import { IfBreakpointDirective } from './directives/if-breakpoint.directive';
import { LetResolvedDirective } from './directives/let-resolved.directive';
import { PickFileDirective } from './directives/pick-file.directive';
import { RatioPipe, TextColorPipe } from './pipes';
import { NamePipe } from './pipes/name.pipe';
import { ConfirmDirective } from './directives/confirm.directive';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { HighlightDiffDirective } from './directives/highlight-diff.directive';
import { SharedReportingModule } from '../reporting';
import { CropTextDirective } from './directives/crop-text.directive';
import { RoundPipe } from './pipes/round.pipe';
import { LocationService, initLocation } from './services/location.service';
import { SortPipe } from './pipes/sort.pipe';
import { SharedBillingModule } from '../billing';
import { DownloadUploadFileDirective } from './directives';
import { SharedCrmModule } from '../crm/crm.module';

@NgModule({
    imports: [],
    exports: [
        CommonModule,
        CwtStateModule,
        CwtCommonModule,
        CwtAuthModule,
        CwtAssetModule,
        RouterModule,
        NzTypographyModule,
        NzEmptyModule,
        NzSpaceModule,
        NzCardModule,
        NzGridModule,
        NzButtonModule,
        NzIconModule,
        NzModalModule,
        NzSpinModule,
        SharedUserModule,
        SharedCompanyModule,
        SharedCustomerModule,
        SharedReportingModule,
        SharedBillingModule,
        SharedCrmModule,
        PickFileDirective,
        TextColorPipe,
        RatioPipe,
        NamePipe,
        SortPipe,
        NzToolTipModule,
        NzDividerModule,
        LetResolvedDirective,
        CloseModalDirective,
        CloseDrawerDirective,
        IfBreakpointDirective,
        GoBackDirective,
        ConfirmDirective,
        HighlightDiffDirective,
        CropTextDirective,
        DownloadUploadFileDirective,
        RoundPipe
    ],
    declarations: [
        PickFileDirective,
        TextColorPipe,
        RatioPipe,
        NamePipe,
        SortPipe,
        LetResolvedDirective,
        CloseModalDirective,
        CloseDrawerDirective,
        IfBreakpointDirective,
        GoBackDirective,
        ConfirmDirective,
        HighlightDiffDirective,
        CropTextDirective,
        DownloadUploadFileDirective,
        RoundPipe
    ],
    providers: [],
})
export class SharedCommonModule {
    static forRoot(): ModuleWithProviders<SharedCommonModule> {
        return {
            ngModule: SharedCommonModule,
            providers: [
                { provide: APP_INITIALIZER, deps: [LocationService], useFactory: initLocation(), multi: true },
                { provide: CwtPercentService, useClass: CustomPercentService },
                { provide: CwtCurrencyService, useClass: CustomCurrencyService },
            ]
        }
    }
}
