import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp } from "@cawita/core-front/api";
import { Company } from "../company";
import { Supplier } from "./supplier.model";

@CwtModel({ name: 'purchase' })
@CwtCrudContract({ 
    path: '/company/:companyId/purchase',
    searchMapper: (s) => ({ name: { regexp: s } }),
})
export class Purchase extends CwtApiEntity {
    @CwtProp({ ref: Company }) company: Company;
    @CwtProp() name: string;
    @CwtProp() reason: string;
    @CwtProp() amount: number;
    @CwtProp() vatRate: number;
    @CwtProp({ ref: Supplier }) supplier: Supplier;
}