import { CwtApiSchema, CwtCrudContract, CwtModel, CwtProp, CwtSchema, CwtSubCrudContract } from "@cawita/core-front/api";
import { Accounting } from "@server-shared";
import { StatusDates, StatusDatesProxy } from "../common";
import { User } from "../common";
import { Billing, BillingItem, BillingTemplate, billingItemSearchMapper, billingSearchMapper } from "./billing.model";
import { QuoteStatus } from "./quote-status.model";
import type { RecurringInvoice } from "./recurring-invoice.model";
import { CompanyProductType } from "../company-product";

export enum QuoteMarginColors{
    moreThanTen = '#95c942',
    bewteenZeroAndTen = '#db9c4f',
    negative = '#ed6e58'
}

export enum QuoteItemType {
    Default = 'default',
    Rental = 'rental'
};

@CwtModel({ name: 'quote-template' })
@CwtCrudContract({ path: '/company/:companyId/quote-template' })
export class QuoteTemplate extends BillingTemplate { }

@CwtModel({ name: 'quote' })
@CwtCrudContract({ path: '/company/:companyId/quote', searchMapper: billingSearchMapper })
@CwtCrudContract({ path: 'for-customer/:customerToken/quote', identifier: 'for-customer', searchMapper: billingSearchMapper })
export class Quote extends Billing {
    @CwtProp() date: number;
    @CwtProp({ ref: 'quote-template' }) template: QuoteTemplate;
    @CwtProp() totalInvoiced: number;
    @CwtProp() totalVatInvoiced: number;
    @CwtProp() optionsAmount: number;
    @CwtProp() optionsVatAmount: number;
    @CwtProp() status: QuoteStatus;
    @CwtProp({ ref: StatusDatesProxy }) statusDates: StatusDates<QuoteStatus>;
    @CwtProp({ ref: 'recurring-invoice' }) recurringInvoice: RecurringInvoice;
    @CwtProp({ ref: User }) owner: User;
    @CwtProp() transformationPercentage: number;

    public get optionsGrossAmount() { return Accounting.sumAmounts(this.optionsAmount, this.optionsVatAmount); }
    public get totalGrossInvoiced() { return Accounting.sumAmounts(this.totalInvoiced, this.totalVatInvoiced); }
    public get remainingAmount() { return Accounting.sumAmounts(this.amount, -this.totalInvoiced); }
    public get remainingVatAmount() { return Accounting.sumAmounts(this.vatAmount, -this.totalVatInvoiced); }
    public get remainingGrossAmount() { return Accounting.sumAmounts(this.grossAmount, -this.totalGrossInvoiced); }
}

@CwtSchema()
export class QuoteItemOption extends CwtApiSchema {
    @CwtProp() isOption: boolean;
    @CwtProp() isSelected: boolean;
    @CwtProp({ ref: 'quote-item' }) requestedItems: QuoteItem[];
}

export const QuoteItemTransformationPercentages= [0,10,25,50,75,90,100];

@CwtModel({ name: 'quote-item' })
@CwtCrudContract({ path: 'company/:companyId/quote/item' })
@CwtSubCrudContract({ parent: Quote, parentPath: 'company/:companyId/quote', subPath: 'quote-item', searchMapper: billingItemSearchMapper })
@CwtSubCrudContract({ parent: Quote, parentPath: 'for-customer/:customerToken/quote', subPath: 'quote-item', identifier: 'for-customer', searchMapper: billingItemSearchMapper })
export class QuoteItem extends BillingItem {
    @CwtProp({ ref: Quote }) quote: Quote;
    @CwtProp({ ref: QuoteItemOption }) optional: QuoteItemOption;
    @CwtProp() itemType: QuoteItemType;
    @CwtProp({ ref: CompanyProductType }) productType: CompanyProductType;
}