import { Customer, Invoice, Quote, CompanyTemplate } from "../../models";

export type MailRecipient<D> = {
    emailValid: boolean;
    origin: string;
    email: string;
    label: string;
    data: D;
}

export type MailParams = {
    quote?: Quote;
    invoice?: Invoice;
    customer?: Customer;
};

export type MailPreviewOptions = {
    template: CompanyTemplate;
    params?: MailParams;
}

export type MailSendOptions = MailPreviewOptions & {
    emails: string[];
    subject: string;
    content: string;
}

export enum MailAction {
    SendQuote = 'sendQuote',
    SendInvoice = 'sendInvoice'
}