const { isEmptyOrNotSet, isset, asArray } = require('@hints/utils/data');
const { isType } = require('@hints/utils/types');

class TemplateConfiguration {
    constructor(templates, types = {}) {
        this.types = types;
        this.templates = templates;
        this.regexp_cache = {};

        this.templateUseCases = Object.keys(templates);
        this.templateTypes = Object.values(templates).reduce((types, template) => types.concat(template.type), []);
        this.templateFors = Object.values(templates).reduce((fors, template) => fors.concat(template.for), []);
    }

    validateTypeValue(type, value) {
        if (!isset(this.types[type]) || !isset(this.types[type].validate)) return isType(value, type);
        return this.types[type].validate(value);
    }

    formatTypeValue(type, value) {
        if (!isset(this.types[type]) || !isset(this.types[type].format)) return value;
        return this.types[type].format(value);
    }

    getUseCaseVariables(useCase) {
        if (!this.useCaseExists(useCase)) return [];
        const variables = this.templates[useCase].variables;
        if (isEmptyOrNotSet(variables)) return [];
        return Object.keys(variables);
    }

    getUseCastVariableConfig(useCase, variable) {
        if (!this.useCaseExists(useCase)) return null;
        if (!this.templates[useCase].variables) return null;
        if (!this.templates[useCase].variables[variable]) return null;
        return this.templates[useCase].variables[variable];
    }

    getUseCaseType(useCase) {
        if (!this.useCaseExists(useCase)) return null;
        return this.templates[useCase].type;
    }

    getUseCaseFor(useCase) {
        if (!this.useCaseExists(useCase)) return null;
        return this.templates[useCase].for;
    }

    getVariableRegexp(variable) {
        if (this.regexp_cache[variable]) return this.regexp_cache[variable];
        const regexp = new RegExp('{{' + variable + '}}', 'g');
        this.regexp_cache[variable] = regexp;
        return regexp;
    }


    getUseCaseMatchingFor(forCondition) {
        if (!isset(this.templates)) return [];
        if (isEmptyOrNotSet(forCondition)) return Object.keys(this.templates);
        const forConditionAsArray = asArray(forCondition);
        return Object.keys(this.templates).filter(useCase => forConditionAsArray.includes(this.templates[useCase].for));
    }

    getRequiredVariables(useCase) {
        const variables = this.getUseCaseVariables(useCase);
        return variables.filter(variable => !!this.templates[useCase].variables[variable].required);
    }

    getVariableObjects(useCase) {
        if (!this.useCaseExists(useCase)) return [];
        const variables = this.templates[useCase].variables;
        if (isEmptyOrNotSet(variables)) return [];
        return Object.entries(variables).map(([name, opt]) => ({ ...opt, name }));
    }

    getConstraints(useCase) {
        if (!this.useCaseExists(useCase)) return [];
        return this.templates[useCase].constraints || [];
    }
    
    getCommonConstraints(useCases = []) {
        if (isEmptyOrNotSet(useCases)) return [];
        const constraints = useCases.map(useCase => this.getConstraints(useCase));
        if (constraints.every(isEmptyOrNotSet)) return [];
        const [firstConstraints, ...otherConstraints] = constraints;
        return firstConstraints.filter(constraint => otherConstraints.every(c => c.includes(constraint)));
    }

    useCaseExists(useCase, type = null) {
        if (!isset(this.templates) || !isset(this.templates[useCase])) return false;
        if (!isEmptyOrNotSet(type)) return this.templates[useCase].type === type;
        return true;
    }

    parseContent(useCase, content, varz) {
        const result = { convertedVariables: [], failedVariables: [] };
        if (!this.useCaseExists(useCase) || isEmptyOrNotSet(content)) return { content: '', result };
        if (isEmptyOrNotSet(varz)) varz = {};
        const variables = this.getUseCaseVariables(useCase);

        for (const variable of variables) {
            const config = this.getUseCastVariableConfig(useCase, variable);
            const regexp = this.getVariableRegexp(variable);
            if (isEmptyOrNotSet(varz[variable])) {
                content = content.replace(regexp, '');
                result.convertedVariables.push(variable);
            } else if (this.validateTypeValue(config.type, varz[variable])) {
                let formatted = this.formatTypeValue(config.type, varz[variable]);
                content = content.replace(regexp, formatted);
                result.convertedVariables.push(variable);
            } else {
                result.failedVariables.push(variable);
            }
        }

        return { content, result };
    }
}

function getTemplateVariables(content) {
    const regexp = /(?<=\{{).+?(?=\}})/g;
    return content.match(regexp) || [];
}

module.exports = {
    TemplateConfiguration,
    getTemplateVariables,
};