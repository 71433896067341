import { CwtApiEntity, CwtModel, CwtProp } from "@cawita/core-front/api";

@CwtModel({ name: 'upload-file' })
export class UploadFile extends CwtApiEntity {
    @CwtProp() name: string;
    @CwtProp() type: string;
    @CwtProp() originalName: string;
    @CwtProp() absolutePath: string;
    @CwtProp() path: string[];
    @CwtProp() extension: string;
    @CwtProp() isPrivate: boolean;
}