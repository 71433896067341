import { Inject, Injectable } from '@angular/core';
import { asArray, getId, isset } from '@cawita/core-front';
import { CwtApiService, CwtCrudContract, CwtCrudService, CwtRequestConfig, CwtSubCrudContract, CwtSubCrudService } from '@cawita/core-front/api';
import { Observable, map, tap } from 'rxjs';
import { CustomerContact, Invoice, InvoiceItem, UploadFile } from '../../models';

@Injectable({ providedIn: 'root' })
export class InvoiceApi {
    constructor(
        private api: CwtApiService,
        @Inject(CwtCrudContract.get(Invoice)) private crud: CwtCrudService<Invoice>,
        @Inject(CwtSubCrudContract.get(InvoiceItem, Invoice)) private itemCrud: CwtSubCrudService<InvoiceItem>,
    ) { }

    public getSummary(filters: any) { return this.api.get('company/:companyId/invoice/summary', filters); }

    public setVisibleToCustomer(invoice: Invoice, isVisibleToCustomer: boolean) { return this.crud.update(getId(invoice), { isVisibleToCustomer }); }
    public setCustomerContact(invoice: Invoice, customerContact: CustomerContact | string) { return this.crud.update(getId(invoice), { customerContact: getId(customerContact) }); }

    public uploadMedia(invoice: Invoice, item: InvoiceItem, files: File | File[]) {
        return this.itemCrud.update(getId(invoice), getId(item), { medias: asArray(files) });
    }

    public deleteMedia(invoice: Invoice, item: InvoiceItem, file: UploadFile) {
        return this.api.delete(`company/:companyId/invoice/${getId(invoice)}/invoice-item/${getId(item)}/media/${getId(file)}`).pipe(
            map(res => new InvoiceItem(res))
        );
    }

    public createQuoteInvoice(dto: any, config?: CwtRequestConfig): Observable<Invoice> {
        return this.api.post(`company/:companyId/invoice/quote`, dto, config).pipe(
            map(res => new Invoice(res))
        );
    }

    public createCreditInvoice(dto: any, config?: CwtRequestConfig): Observable<Invoice> {
        return this.api.post(`company/:companyId/invoice/credit`, dto, config).pipe(
            map(res => new Invoice(res))
        );
    }
}