import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { CwtDirectiveView, isset } from '@cawita/core-front/common';
import { CompayConfigFeatures } from '@shared/models';
import { Subscription } from 'rxjs';
import { CompanyStore } from '../company.store';

/**
 * Displays the element only if the logged user as specified persmissions
 * Input format: 'permission' or ['perm1', 'perm2']
 */
@Directive({ selector: '[cwtIfCompanyFeature]' })
export class IfCompanyFeatureDirective implements OnDestroy {
    private _sub: Subscription;
    private _view = new CwtDirectiveView(this.vcRef, this.tRef);
    private _elseView = new CwtDirectiveView(this.vcRef, null);

    @Input('cwtIfCompanyFeature') set feature(feature: keyof CompayConfigFeatures<any>) {
        if (this._sub) this._unsubscribe();
        this._sub = this.companyStore.stableState$.subscribe(() => {
            const hasFeature = this.companyStore.hasCompanyFeature(feature);
            this._view.ensureState(hasFeature);
            this._elseView.ensureState(!hasFeature);
        });
    }

    @Input('cwtIfCompanyFeatureElse') set elseTemplate(v: TemplateRef<any>) {
        this._elseView.setTemplate(v);
    }

    constructor(
        private tRef: TemplateRef<any>,
        private vcRef: ViewContainerRef,
        private companyStore: CompanyStore
    ) { }

    ngOnDestroy(): void {
        this._unsubscribe();
    }

    private _unsubscribe() {
        this._sub?.unsubscribe();
        this._sub = null;
    }
}
