import { ICustomFieldsConfig } from "@shared/modules/custom-fields";
import { CustomFieldEnumInputComponent } from "@shared/widgets/custom-field/custom-fields-inputs/custom-field-enum-input/custom-field-enum-input.component";
import { CustomFieldSimpleInputComponent } from "@shared/widgets/custom-field/custom-fields-inputs/custom-field-simple-input/custom-field-simple-input.component";
import { CustomFieldTextInputComponent } from "@shared/widgets/custom-field/custom-fields-inputs/custom-field-text-input/custom-field-text-input.component";

export const CustomFieldsConfig: ICustomFieldsConfig = {
    adapters: {
        number: {
            input: CustomFieldSimpleInputComponent,
            cell: null,
            displayer: null,
        },
        string: {
            input: CustomFieldSimpleInputComponent,
            cell: null,
            displayer: null,
        },
        enum: {
            input: CustomFieldEnumInputComponent,
            cell: null,
            displayer: null,
        },
        longText: {
            input: CustomFieldTextInputComponent,
            cell: null,
            displayer: null,
        },
        richText: {
            input: CustomFieldTextInputComponent,
            cell: null,
            displayer: null,
        }
    }
}