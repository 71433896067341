import { CwtApiEntity, CwtApiSchema, CwtCrudContract, CwtModel, CwtProp, CwtSchema, CwtSubCrudContract } from "@cawita/core-front/api";
import { User } from "../common";
import { Company } from "../company";
import { BudgetAmount } from "./budget-amount.model";
import { Project } from "./project.model";
import { Reporter } from "./reporter.model";

const BudgetSearchMapper = (s) => ({ search: s });

export enum ReporterConstraintType {
    Reporters = 'reporters',
    CustomFieldFilters = 'customFieldFilters'
}

export type MatchingReporters = {
    canReport: Reporter[];
    canExceed: Reporter[];
}

@CwtSchema()
export class ReporterConstraintSchema extends CwtApiSchema {
    @CwtProp() constraintType: ReporterConstraintType;
    @CwtProp({ ref: Reporter }) reporters: Reporter[];
    @CwtProp() customFieldFilters: Record<string, any>;
}


@CwtModel({ name: 'budget' })
@CwtCrudContract({ path: 'company/:companyId/budget' })
@CwtSubCrudContract({ parent: Reporter, parentPath: 'company/:companyId/reporter', subPath: 'budget', searchMapper: BudgetSearchMapper })
export class Budget extends CwtApiEntity {
    @CwtProp({ ref: Company }) company: Company;
    @CwtProp({ ref: Project }) project: Project;
    @CwtProp({ ref: User }) administrators: User[];
    @CwtProp() isOpen: boolean;
    @CwtProp() name: string;
    @CwtProp() budgeted: BudgetAmount;
    @CwtProp() consumed: BudgetAmount;
    @CwtProp() remaining: BudgetAmount;
    @CwtProp({ ref: ReporterConstraintSchema }) canReport: ReporterConstraintSchema[];
    @CwtProp({ ref: ReporterConstraintSchema }) canExceed: ReporterConstraintSchema[];
    @CwtProp() matchingReporters: MatchingReporters;
}