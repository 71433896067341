import { Directive, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { CwtDirectiveView, hasSomeChanges, isset } from '@cawita/core-front';
import { Quote, QuoteStatus } from '@shared/models';

@Directive({ selector: '[cwtCanInvoiceQuote]' })
export class CanInvoiceQuoteDirective implements OnChanges {
    private _view = new CwtDirectiveView(this.vcRef, this.tRef);
    @Input('cwtCanInvoiceQuote') quote: Quote;

    constructor(
        private vcRef: ViewContainerRef,
        private tRef: TemplateRef<void>
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (hasSomeChanges(changes, ['quote'], true)) {
            const hasStatuses = [QuoteStatus.Validated].includes(this.quote?.status);
            const hasRecurringInovice = isset(this.quote.recurringInvoice);
            const canInvoiceQuote = this.quote.remainingGrossAmount > 0;
            this._view.ensureState(hasStatuses && !hasRecurringInovice && canInvoiceQuote);
        }
    }
}