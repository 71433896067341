
// status: { type: String, enum: actionConfig.actionStatuses, default: 'planned' },


import { CwtApiEntity, CwtApiSchema, CwtCrudContract, CwtModel, CwtProp, CwtSchema } from "@cawita/core-front/api";
import { User } from "../common";
import { Company } from "../company";
import { Customer, CustomerContact, CustomerSite } from "../customer";
import { ProspectSheet } from "./prospect-sheet.model";
import { ActionType } from "./action-type.model";

export enum ActionStatus {
    NoFollowUp = 'noFollowUp',
    FollowUp = 'followUp',
    Planned = 'planned',
    Delayed = 'delayed'
}

export enum ActionStatusColor {
    noFollowUp = 'red',
    followUp = 'green',
    planned = 'blue',
    delayed = 'orange'
}
export enum ActionStatusColorHex {
    noFollowUp = '#cf1322',
    followUp = '#389e0d',
    planned = '#096dd9',
    delayed = '#d46b08'
}

export enum ActionStatusBgColorHex {
    noFollowUp = '#ffa39eaa',
    followUp = '#b7eb8faa',
    planned = '#91d5ffaa',
    delayed = '#ffd591aa'
}
export enum ActionStatusBorderColorHex {
    noFollowUp = '#ffa39e',
    followUp = '#b7eb8f',
    planned = '#91d5ff',
    delayed = '#ffd591'
}

export enum ActionEditMode {
    create = 'create',
    edit = 'edit',
    follow = 'follow',
    delay = 'delay',
    close = 'close',
}

@CwtSchema()
export class ActionDone extends CwtApiSchema {
    @CwtProp({ ref: Date }) date: Date;
    @CwtProp({ ref: User }) user: User;
}

@CwtSchema()
export class ActionTransfer extends CwtApiSchema {
    @CwtProp({ ref: User }) originUser: User;
    @CwtProp({ ref: Date }) date: Date;
    @CwtProp({ ref: User }) destinationUser: User;
}



@CwtModel({ name: 'action' })
@CwtCrudContract({ path: 'company/:companyId/action', })
@CwtCrudContract({ path: 'company/:companyId/action/search', identifier: 'action-search', })
export class Action extends CwtApiEntity {
    @CwtProp({ ref: Company }) company: Company;
    @CwtProp({ ref: ProspectSheet }) prospectSheet: ProspectSheet;
    @CwtProp({ ref: Customer }) customer: Customer;
    @CwtProp({ ref: User }) user: User;
    @CwtProp({ ref: CustomerSite }) site: CustomerSite;
    @CwtProp({ ref: ActionType }) type: ActionType;
    @CwtProp({ ref: ActionDone }) done: ActionDone;
    @CwtProp({ ref: CustomerContact }) contact: CustomerContact;
    @CwtProp({ ref: Action }) child: Action;
    @CwtProp({ ref: Action }) parent: Action;
    @CwtProp({ ref: Date }) actionDate: Date;
    @CwtProp({ ref: ActionTransfer }) transfer: ActionTransfer[];
    @CwtProp() status: ActionStatus;
    @CwtProp() comment: string;
    @CwtProp() tags: string[];
    @CwtProp() isHighPriority: boolean;
}