import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp } from "@cawita/core-front/api";
import { User } from "../common";
import { Company } from "./company.model";

export enum InvitationStatus {
    Pending = 'pending',
    Accepted = 'accepted',
    Refused = 'refused',
}

@CwtModel({ name: 'invitation' })
@CwtCrudContract({ path: 'company/:companyId/invitation' })
export class Invitation extends CwtApiEntity {
    @CwtProp({ ref: Company }) company: Company;
    @CwtProp() email: string;
    @CwtProp() permissions: string[];
    @CwtProp() status: InvitationStatus;
    @CwtProp({ ref: User }) host: User;
    @CwtProp({ ref: User }) invitee: User;
    @CwtProp({ ref: Date }) expirationDate: Date;
}