<cwt-mailing-header></cwt-mailing-header>
<nz-divider class="m-0" nzOrientation="horizontal"></nz-divider>
<form id="mail-form" [formGroup]="form" [cwtFormSubmit]="form" (formValid)="send($event)">
    <cwt-mail-recipients-input nzBorderless cwtEmail formControlName="emails" [placeholder]="'field.mail-recipients' | translate" [customer]="customer"></cwt-mail-recipients-input>
    <cwt-mail-template-input nzBorderless required formControlName="template" [placeholder]="'field.mail-template' | translate" [useCases]="useCases" [resolveConstraint]="resolveConstraint"></cwt-mail-template-input>
    <input nz-input nzBorderless required formControlName="subject" [placeholder]="'field.mail-subject' | translate"/>
    <nz-divider class="m-0" nzOrientation="horizontal"></nz-divider>
    <quill-editor borderless required [preserveWhitespace]="true" formControlName="content" [placeholder]="'field.mail-content' | translate"></quill-editor>
</form>
<nz-divider class="m-0" nzOrientation="horizontal"></nz-divider>
<cwt-mailing-footer [loading]="loading" formId="mail-form"></cwt-mailing-footer>