import { Inject, Injectable } from "@angular/core";
import { downloadFile, getId } from "@cawita/core-front";
import { CwtCrudContract, CwtCrudService, CwtSubCrudContract, CwtSubCrudService } from "@cawita/core-front/api";
import { Action, Customer, ProspectSheet, CustomerSite } from "@shared/models";
import { addHours, setHours, setMinutes } from "date-fns";
import { firstValueFrom } from "rxjs";
import { stripHtml } from "string-strip-html";
import { CalendarOptions, ICalendar } from 'datebook';


@Injectable({ providedIn: 'root' })
export class ActionUtilsService {
    constructor(
        @Inject(CwtSubCrudContract.get(CustomerSite, Customer)) private siteCrud: CwtSubCrudService<CustomerSite>,
        @Inject(CwtCrudContract.get(ProspectSheet)) private prospectSheetCrud: CwtCrudService<ProspectSheet>,

    ) { }
    async sendToCalendar(action: Action) {
        const title = this._getTitle(action);
        const prospectSheet = await this._getProspectSheet(action)
        const location = await this._getLocation(prospectSheet);
        const description = stripHtml(action.comment).result;
        let startDate = setHours(action.actionDate, 9);
        startDate = setMinutes(startDate, 0);

        const endDate = addHours(startDate, 1);
        const attendees = [{
            name: `${action?.contact?.firstName} ${action?.contact?.lastName}`,
            email: action?.contact?.email,
            icsOptions: {
                rsvp: true
            }
        }]

        const event = {
            title,
            location,
            description,
            start: startDate,
            end: endDate,
            attendees: attendees
        }

        const icalendar = new ICalendar(event);
        const file = new Blob([icalendar.render()], { type: 'text/calendar' });
        downloadFile(file, `${title}.ical`);

    }

    private _getTitle(action: Action) {
        let title = [];
        if (action?.customer?.name) title.push(action?.customer?.name);
        if (action?.type?.name) title.push(action?.type?.name);
        return title.join(" / ");
    }


    private async _getProspectSheet(action: Action) {
        return firstValueFrom(this.prospectSheetCrud.getOne(getId(action?.prospectSheet)));
    }

    private async _getSites(prospectSheet: ProspectSheet): Promise<CustomerSite[]> {
        return firstValueFrom(this.siteCrud.getArray(getId(prospectSheet?.customer)));
    }

    private async _getLocation(prospectSheet: ProspectSheet) {

        const sites = await this._getSites(prospectSheet);
        const mainSite = sites.find(site => site.isMain) || sites[0];
        const address = [];
        if (mainSite?.info?.line1) address.push(mainSite?.info?.line1);
        if (mainSite?.info?.line2) address.push(mainSite?.info?.line2);
        if (mainSite?.info?.zip) address.push(mainSite?.info?.zip);
        if (mainSite?.info?.city) address.push(mainSite?.info?.city);
        if (mainSite?.info?.country) address.push(mainSite?.info?.country);
        return address.join(' ');

    }



}