import { isset, sameId } from "@cawita/core-front";
import { format, getYear } from "date-fns";
import { CompanyConfig } from "../../config/company.config";
import { Company, CompanyAccess, Customer, BillingReferenceSegment, BillingReferenceSegmentType } from "../../models";

export function getPreferredCompany(companies: Company[], accesses: CompanyAccess[], current?: Company | string): Company {
    if (!companies?.length) return null;
    if (!accesses?.length) return null;
    if (isset(current)) {
        const inCompanies = findCompanyInArray(companies, current);
        if (inCompanies) return inCompanies;
    }

    const ownerAccesses = accesses.filter(access => access.isOwner);
    const ownedCompanies = companies.filter(company => ownerAccesses.some(access => sameId(access.company, company)));
    const nonOwnedCompanies = companies.filter(company => !ownerAccesses.some(access => sameId(access.company, company)));

    if (ownedCompanies.length) return ownedCompanies[0];
    return nonOwnedCompanies[0];
}


export function findCompanyInArray(companies: Company[], company: Company | string) {
    if (!isset(company)) return null;
    return companies?.find(c => sameId(c, company)) ?? null;
}

export function findAccessForCompany(accesses: CompanyAccess[], company: Company | string) {
    if (!isset(company)) return null;
    return accesses?.find(c => sameId(c.company, company)) ?? null;
}

export function findCompanyAndAccessInArrays(companies: Company[], accesses: CompanyAccess[], company: Company | string) {
    const c = findCompanyInArray(companies, company);
    const a = findAccessForCompany(accesses, company);
    if (!isset(c) || !isset(a)) return { company: null, access: null };
    return { company: c, access: a };
}

export function getDefaultReferenceSegments(): BillingReferenceSegment[] {
    return [{
        type: BillingReferenceSegmentType.Increment,
        separator: '-',
    }]
}

export function generateBillingRef(segments: BillingReferenceSegment[], increment?: number, companyRef?: string, customerRef?: string, date = new Date()) {
    if (!segments?.length) return '';
    const variables = generateBillingReferenceVariables(increment, companyRef, customerRef, date);
    return segments.reduce((ref, segment, index, arr) => {
        const isLast = index >= arr.length - 1;
        const segmentValue = generateSegment(segment.type, variables);
        if (isLast) return `${ref}${segmentValue}`;
        return `${ref}${segmentValue}${segment.separator}`;
    }, '');
}

export function generateBillingSegment(type: BillingReferenceSegmentType, increment?: number, companyRef?: string, customerRef?: string, date = new Date()) {
    return generateSegment(type, generateBillingReferenceVariables(increment, companyRef, customerRef, date));
}

function generateBillingReferenceVariables(increment?: number, companyRef?: string, customerRef?: string, date = new Date()): Record<BillingReferenceSegmentType, string | null> {
    return {
        companyRef: companyRef,
        customerRef: customerRef,
        increment: increment?.toString().padStart(5, '0'),
        year: format(date, 'yyyy'),
        shortYear: format(date, 'yy'),
    };
}

function generateSegment(type: BillingReferenceSegmentType, values: Record<BillingReferenceSegmentType, string | null>) {
    const placeholders = CompanyConfig.segmentPlaceholders;
    return values[type] || placeholders[type];
}