import { format } from "date-fns";
import { BillingReferenceSegmentType } from "../models";

export type ICompanyConfig = {
    defaultVatRates: number[];
    permissionsPerCategory: { category: string, permissions: string[] }[];
    segmentColors: Record<BillingReferenceSegmentType, { foreground: string, background: string }>;
    segmentPlaceholders: Record<BillingReferenceSegmentType, string>;
}

export const CompanyConfig: ICompanyConfig = {
    defaultVatRates: [0.2, 0.1, 0.055, 0.021],
    permissionsPerCategory: [
        {
            category: 'settings',
            permissions: ['manageCompanyAccess', 'manageCompanySettings']
        },
        {
            category: 'crm',
            permissions: [
                'seeCustomers', 'manageCustomers',
                // 'crmAccess', 'manageCrm'
            ]
        },
        {
            category: 'billing',
            permissions: [
                'seeQuotes', 'manageQuotes',
                'seeInvoices', 'manageInvoices',
                'billingExport', 'manageMachineMoves',
                'seeMachineMoves', 'manageProducts'
            ]
        },
        {
            category: 'payments',
            permissions: [
                'managePayments', 'seePayments',
                // 'seeSuppliers', 'manageSuppliers',
                // 'seePurchases', 'managePurchases',
                // 'seeBanks', 'manageBanks',
                // 'seeBankTransactions', 'manageBankTransactions'
            ]
        },
        // {
        //     category: 'reporting',
        //     permissions: ['manageProjects', 'manageMyProjects', 'manageReporters', 'seeReporters'],
        // }
    ],
    segmentColors: {
        [BillingReferenceSegmentType.CompanyRef]: {
            foreground: '#c41d7f',
            background: '#fff0f6'
        },
        [BillingReferenceSegmentType.CustomerRef]: {
            foreground: '#d48806',
            background: '#fffbe6'
        },
        [BillingReferenceSegmentType.Increment]: {
            foreground: '#389e0d',
            background: '#f6ffed'
        },
        [BillingReferenceSegmentType.Year]: {
            foreground: '#1d39c4',
            background: '#f0f5ff'
        },
        [BillingReferenceSegmentType.ShortYear]: {
            foreground: '#1d39c4',
            background: '#f0f5ff'
        },
    },
    segmentPlaceholders: {
        [BillingReferenceSegmentType.CompanyRef]: 'XXX',
        [BillingReferenceSegmentType.CustomerRef]: 'ZZZ',
        [BillingReferenceSegmentType.Increment]: '00001',
        [BillingReferenceSegmentType.Year]: format(new Date(), 'yyyy'),
        [BillingReferenceSegmentType.ShortYear]: format(new Date(), 'yy'),
    }
}