import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp, CwtSubCrudContract } from "@cawita/core-front/api";
import { Company } from "../company";
import { Reporter } from "./reporter.model";

@CwtModel({ name: 'reporter-monthly-setting' })
@CwtCrudContract({
    path: '/company/:companyId/reporter-monthly-setting',
    searchMapper: (month) => ({ month: month }),
})

@CwtSubCrudContract({ parent: Reporter, parentPath: 'company/:companyId/reporter/', subPath: 'reporter-monthly-setting' })
export class ReporterMonthlySetting extends CwtApiEntity {
    @CwtProp({ ref: Company }) company: Company
    @CwtProp({ ref: Reporter }) reporter: Reporter;
    @CwtProp() month: number;
    @CwtProp() hourCost: number;
}
