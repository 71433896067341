import { CwtApiEntity, CwtModel, CwtProp } from "@cawita/core-front/api";
import { User } from "../common";
import { Company } from "../company";
import { BookmarkResourceType } from "./bookmark-type.model";
import { InvoiceItem, Observation, QuoteItem } from "../billing";

@CwtModel({ name: 'bookmark' })
export class Bookmark extends CwtApiEntity {
    @CwtProp({ ref: Company }) company: Company;
    @CwtProp() resourceType: BookmarkResourceType;
    @CwtProp({ ref: User }) user: User;
    @CwtProp({ ref: QuoteItem }) quoteItem: QuoteItem;
    @CwtProp({ ref: InvoiceItem }) invoiceItem: InvoiceItem;
    @CwtProp({ ref: Observation }) observation: Observation;
}