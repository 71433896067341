import { CwtApiSchema, CwtProp, CwtSchema } from "@cawita/core-front/api";
import { CompanyProduct, CompanyProductUnit } from "../company-product";

@CwtSchema()
export class BillingProduct extends CwtApiSchema {

    @CwtProp() name: string;
    @CwtProp() unit: CompanyProductUnit;
    @CwtProp() unitPrice: number;
    @CwtProp() quantity: number;
    @CwtProp() sizedQuantity: number;
    @CwtProp({ ref: CompanyProduct }) product: CompanyProduct;
}



export function createBillingProductFromCompanyProduct(product: CompanyProduct, quantity?: number) {
    return new BillingProduct({
        product: product,
        name: product?.name,
        unit: product?.unit,
        unitPrice: product?.effectiveUnitPrice,
        sizedQuantity: (product?.size ?? 0) * (quantity ?? 0),
        quantity: quantity ?? 0
    });
}