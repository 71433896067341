import { ChangeDetectorRef, Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { CwtDirectiveView, getId, isset } from '@cawita/core-front';
import { Subscription } from 'rxjs';
import { Company, Customer } from '../../models';
import { CustomerStore } from './customer.store';

export type ActiveCustomerContext = {
    $implicit: Customer;
    id: string;
    company: Company;
}

@Directive({
    selector: '[cwtActiveCustomer]'
})
export class ActiveCustomerDirective {
    private _sub: Subscription;
    private _ctx: ActiveCustomerContext = { $implicit: null, id: null, company: null };
    private _view = new CwtDirectiveView(this.vcRef, this.tRef, this._ctx);
    private _elseView = new CwtDirectiveView(this.vcRef, null);

    @Input('cwtActiveCustomerElse') public set elseRef(v: TemplateRef<any>) {
        this._elseView.setTemplate(v);
    }

    constructor(
        private tRef: TemplateRef<ActiveCustomerContext>,
        private vcRef: ViewContainerRef,
        private customerStore: CustomerStore
    ) { }

    ngOnDestroy(): void {
        this._sub?.unsubscribe();
    }

    ngOnInit(): void {
        this._sub = this.customerStore.stableState$.subscribe({
            next: (state) => {
                this._ctx.$implicit = state.customer;
                this._ctx.id = getId(state.customer);
                this._ctx.company = state.company;
                this._view.ensureState(isset(state.customer));
                this._elseView.ensureState(!isset(state.customer));
            }
        });
    }

}
