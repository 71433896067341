import { Directive, HostListener, Input, NgZone, Optional } from '@angular/core';
import { isEmpty } from '@hints/utils/data';
import { NzModalComponent, NzModalRef } from 'ng-zorro-antd/modal';

@Directive({
  selector: '[cwtCloseModal]'
})
export class CloseModalDirective {

  @Input('cwtCloseModal') data: any = null;

  constructor(
    @Optional() private modalComponent: NzModalComponent,
    @Optional() private modalRef: NzModalRef
  ) { }

  @HostListener('click', ['$event'])
  public close(event: Event) {
    const data = isEmpty(this.data) ? null : this.data;
    if (!this.modalRef && !this.modalComponent) return;
    event?.stopPropagation();
    event?.preventDefault();
    if (this.modalComponent) {
      this.modalComponent.close(data);
    } else if (this.modalRef) {
      this.modalRef.close(data);
    }
  }
}
