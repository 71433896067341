import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp, CwtSubCrudContract } from "@cawita/core-front/api";
import { Company } from "../company";
import { Reporter } from "./reporter.model";
import { CustomFieldValues } from "../custom-field";

export enum OffDayType {
    Company = 'company',
    Reporter = 'reporter'
}

@CwtModel({ name: 'off-day' })
@CwtCrudContract({ path: '/company/:companyId/off-day', })
@CwtSubCrudContract({ parent: Reporter, parentPath: 'company/:companyId/reporter/', subPath: 'off-day' })
export class OffDay extends CwtApiEntity {
    @CwtProp({ ref: Company }) company: Company;
    @CwtProp() type: OffDayType;
    @CwtProp({ ref: Reporter }) reporter: Reporter;
    @CwtProp() startDate: number;
    @CwtProp() endDate: number;
    @CwtProp() nbHoursWorkedOnStartDay: number;
    @CwtProp() nbHoursWorkedOnEndDay: number;
    @CwtProp() reason: string;
    @CwtProp() customFieldValues: CustomFieldValues;
}