import { AfterViewChecked, ComponentFactoryResolver, ComponentRef, Directive, ElementRef, Host, HostListener, Input, Optional, Renderer2, ViewContainerRef } from '@angular/core';
import { NzNoAnimationDirective } from 'ng-zorro-antd/core/no-animation';
import { NzToolTipComponent, NzTooltipBaseDirective, PropertyMapping } from 'ng-zorro-antd/tooltip';

@Directive({
  selector: '[cwtCropText]',
  exportAs: 'cwtCropText',
  host: {
    'class': 'crop-text',
    '[style.--crop-max-width.px]': 'cropWidth',
    '[style.--crop-max-rows]': 'nbLines',
    '[class.ant-tooltip-open]': 'visible',
  }
})
export class CropTextDirective extends NzTooltipBaseDirective implements AfterViewChecked {
  override visible?: boolean;
  override componentRef: ComponentRef<NzToolTipComponent> = this.hostView.createComponent(NzToolTipComponent);

  private _htmlElement: HTMLElement = this.elementRef.nativeElement;

  @Input('cwtCropText') nbLines: number | string = 2;
  @Input('cwtCropWidth') cropWidth: number | string = 320;

  constructor(
    elementRef: ElementRef,
    hostView: ViewContainerRef,
    resolver: ComponentFactoryResolver,
    renderer: Renderer2,
    @Host() @Optional() noAnimation?: NzNoAnimationDirective,
  ) {
    super(elementRef, hostView, resolver, renderer, noAnimation);
  }

  ngAfterViewChecked(): void {
    this.refreshTrigger();
  }

  @HostListener('window:resize')
  public onResize() {
    this.refreshTrigger();
  }

  protected refreshTrigger() {
    const hasXScroll = this._htmlElement.offsetWidth < this._htmlElement.scrollWidth;
    const hasYScroll = this._htmlElement.offsetHeight < this._htmlElement.scrollHeight;

    const newTrigger = hasXScroll || hasYScroll ? 'hover' : null;
    if (this.trigger !== newTrigger) {
      this.trigger = newTrigger;
      this.registerTriggers();
      this['updatePropertiesByKeys'](['title', 'trigger']);
    }
  }

  protected override getProxyPropertyMap(): PropertyMapping {
    return {
      ...super.getProxyPropertyMap(),
      title: ['nzTitle', () => this._htmlElement.textContent],
      trigger: ['nzTrigger', () => this.trigger]
    };
  }
}