import { CwtApiSchema, CwtCrudContract, CwtModel, CwtProp, CwtSchema } from "@cawita/core-front/api";
import { RecurringConfig, User } from "../common";
import { Company, CompanyTemplate } from "../company";
import { CustomFieldValues } from "../custom-field";
import { Customer, CustomerContact, CustomerSite } from "../customer";
import { BillingProduct } from "./billing-product.model";
import { InvoiceTemplate } from "./invoice-template.model";
import { Invoice, InvoiceItemType } from "./invoice.model";
import type { Quote } from "./quote.model";
import { CompanyProductType } from "../company-product";

@CwtSchema()
export class InvoiceItemSchema extends CwtApiSchema {
    @CwtProp() itemType: InvoiceItemType;
    @CwtProp() vatRate: number;
    @CwtProp() title: string;
    @CwtProp() unitPrice: number;
    @CwtProp() quantity: number;
    @CwtProp() description: string;
    @CwtProp() order: number;
    @CwtProp() productType: CompanyProductType;
    @CwtProp() displayProductsInPdf: boolean;
    @CwtProp() coef: number;
    @CwtProp() sizedQuantity: number;
    @CwtProp({ ref: BillingProduct }) products: BillingProduct[];
}

@CwtSchema()
export class ObservationSchema extends CwtApiSchema {
    @CwtProp() content: string;
    @CwtProp() order: number;
}

@CwtSchema()
export class RecurringInvoiceSchema extends CwtApiSchema {
    @CwtProp() title: string;
    @CwtProp() paymentTermValue: number;
    @CwtProp({ ref: Customer }) customer: Customer;
    @CwtProp({ ref: CustomerSite }) site: CustomerSite;
    @CwtProp({ ref: CustomerContact }) customerContact: CustomerContact;
    @CwtProp({ ref: InvoiceItemSchema }) invoiceItems: InvoiceItemSchema[];
    @CwtProp({ ref: ObservationSchema }) observations: ObservationSchema[];
    @CwtProp() isVisibleToCustomer: boolean;
    @CwtProp() defaultVatRate: number;
    @CwtProp() customFieldValues: CustomFieldValues;
}
@CwtSchema()
export class RecurringInvoiceAutoSend extends CwtApiSchema {
    @CwtProp() isEnabled: boolean;
    @CwtProp() emails: string[];
    @CwtProp({ ref: CompanyTemplate }) template: CompanyTemplate;
    @CwtProp({ ref: User }) sender: User;
}

@CwtSchema()
export class RecurringInvoiceActions extends CwtApiSchema {
    @CwtProp({ ref: RecurringInvoiceAutoSend }) autoSendInvoice: RecurringInvoiceAutoSend
}

@CwtModel({ name: 'recurring-invoice' })
@CwtCrudContract({ path: 'company/:companyId/recurring-invoice', searchMapper: s => ({ 'invoiceSchema.title': { regexp: s } }) })
export class RecurringInvoice extends RecurringConfig {
    @CwtProp({ ref: Company }) company: Company;
    @CwtProp({ ref: InvoiceTemplate }) template: InvoiceTemplate;
    @CwtProp({ ref: RecurringInvoiceSchema }) invoiceSchema: RecurringInvoiceSchema;
    @CwtProp({ ref: Invoice }) generatedInvoices: Invoice[];
    @CwtProp({ ref: RecurringInvoiceActions }) actions: RecurringInvoiceActions;
    @CwtProp({ ref: 'quote' }) quote: Quote;
    @CwtProp() amount: number;
    @CwtProp() vatAmount: number;
}