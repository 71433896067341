import { AfterContentInit, ContentChildren, Directive, EventEmitter, Host, Input, OnChanges, OnInit, Optional, Output, QueryList, SimpleChanges, TemplateRef } from '@angular/core';
import { NzTabSetComponent } from 'ng-zorro-antd/tabs';
import { TabDirective } from './tab.directive';
import { CwtPersistentDirective, hasSomeChanges } from '@cawita/core-front';

@Directive({
  selector: 'nz-tabset[cwtTabset]'
})
export class TabsetDirective implements OnInit, OnChanges, AfterContentInit {
  private _initialized: boolean = false;

  @Input() currentTab: string;
  @Output() currentTabChange = new EventEmitter<string>();

  @ContentChildren(TabDirective) tabs: QueryList<TabDirective>;

  constructor(
    private tabSet: NzTabSetComponent,
    @Host() @Optional() public persistency?: CwtPersistentDirective,
  ) {
    this.tabSet.nzSelectedIndexChange.subscribe(index => this._setCurrentForTabIndex(index));
  }

  ngOnInit(): void {
    const persistentTab = this.persistency.get<string>('tab');
    if (persistentTab) {
      this.currentTab = persistentTab;
      this.currentTabChange.emit(persistentTab);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this._initialized && hasSomeChanges(changes, ['currentTab'])) {
      if (this.currentTab) this._selectForTabId(this.currentTab);
    }
  }

  ngAfterContentInit(): void {
    if (this.currentTab) this._selectForTabId(this.currentTab);
    this._initialized = true;
  }

  private _selectForTabId(tabId: string) {
    const tabToSelect = this.tabs.toArray().findIndex(t => t.tabId === tabId);
    if (tabToSelect >= 0) {
      this.tabSet.nzSelectedIndex = tabToSelect;
      this.persistency?.set('tab', tabId);
    }
  }

  private _setCurrentForTabIndex(index: number) {
    const tabForIndex = this.tabs.toArray().find((t, i) => i === index);
    if (tabForIndex?.tabId) {
      this.currentTab = tabForIndex.tabId;
      this.currentTabChange.emit(this.currentTab);
      this.persistency?.set('tab', this.currentTab);
    }
  }

}
