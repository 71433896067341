import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp, CwtSubCrudContract } from "@cawita/core-front/api";
import { Company } from "../company";
import { Budget } from "./budget.model";
import { Reporter } from "./reporter.model";

@CwtModel({ name: 'activity' })
@CwtCrudContract({ path: 'company/:companyId/activity' })
@CwtSubCrudContract({ parent: Reporter, parentPath: 'company/:companyId/reporter/', subPath: 'activity' })
export class Activity extends CwtApiEntity {
    @CwtProp({ ref: Company }) company: Company;
    @CwtProp() date: number;
    @CwtProp({ ref: Reporter }) reporter: Reporter;
    @CwtProp({ ref: Budget }) budget: Budget;
    @CwtProp() nbHours: number;
    @CwtProp() cost: number;
    @CwtProp() detail: string;
}