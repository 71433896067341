import { Pipe, PipeTransform } from '@angular/core';
import { QuoteItem } from '@shared/models';

@Pipe({
  name: 'quoteOptionItems'
})
export class QuoteOptionItemsPipe implements PipeTransform {

  transform(quoteItems: QuoteItem[]): QuoteItem[] {
    if (!quoteItems?.length) return [];
    return quoteItems.filter(quoteItem => quoteItem?.optional?.isOption);
  }

}
