import { Inject, Injectable, Injector } from '@angular/core';
import { asArray, isset } from '@cawita/core-front';
import { TemplateService } from '@server-shared';
import { MailAction, MailParams, MailPreviewOptions, MailSendOptions } from '../mailing-types';
import { MAILING_CONFIG, MailActionHandler, MailingConfig } from '../providers/mailing-config.provider';

@Injectable({ providedIn: 'root' })
export class MailingService {
    constructor(
        private injector: Injector,
        @Inject(MAILING_CONFIG) private config: MailingConfig,
    ) { }

    public getTemplatesUseCases(action: MailAction, params?: MailParams) {
        const adapter = this.getAdapter(action);
        const useCases = asArray(adapter.getTemplatesUseCases());
        if(isset(params) && isset(adapter.canSelectTemplateUseCase)) {
            return useCases.filter(useCase => adapter.canSelectTemplateUseCase(params, useCase));
        }
        return useCases;
    }

    public getCommonContraints(action: MailAction): string[] {
        const adapter = this.getAdapter(action);
        const useCases = asArray(adapter.getTemplatesUseCases());
        if (!useCases?.length) return [];
        const constraints = useCases.map(useCases => TemplateService.getConstraints(useCases)).filter(constraints => constraints.length > 0);
        if (!constraints.length) return [];
        if (constraints.length === 1) return constraints[0];
        const [mainConstraints, ...otherContraints] = constraints;
        return mainConstraints.filter(constraint => otherContraints.some(c => c.includes(constraint)));
    }

    public preview(action: MailAction, options: MailPreviewOptions) {
        const adapter = this.getAdapter(action);
        return adapter.preview(options);
    }

    public send(action: MailAction, options: MailSendOptions) {
        const adapter = this.getAdapter(action);
        return adapter.send(options);
    }

    public getAdapter(action: MailAction) {
        const adapter = this._getAdapter(action);
        if (!adapter) this._throwAdapterNotFoundError(action);
        return adapter;
    }

    private _getAdapter(action: MailAction) {
        if (!isset(this.config.adapters[action]?.handler)) return null;
        return this.injector.get<MailActionHandler>(this.config.adapters[action].handler, null);
    }

    private _throwAdapterNotFoundError(action: MailAction) {
        throw new Error(`IMailActionAdapter not found for action ${action}`);
    }
}