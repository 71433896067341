import { InjectionToken, Type, inject } from "@angular/core";
import { Observable } from "rxjs";
import { MailAction, MailParams, MailPreviewOptions, MailSendOptions } from "../mailing-types";
import { AlertService } from "../../../services/utils/alert.service";

export const MAILING_CONFIG = new InjectionToken<MailingConfig>('cwt-mailing-config');

export abstract class MailActionHandler {
    abstract getTemplatesUseCases(): string | string[];
    abstract preview(opts: MailPreviewOptions): Observable<any>;
    abstract send(opts: MailSendOptions): Observable<any>;
    canSelectTemplateUseCase(opts: MailParams, template: string): boolean { return true };
}

export type ConstraintOptions<D = any> = {
    constraint: string;
    action: MailAction;
    promptData?: D
}

export type ConstraintPromptResponse<D = any> = boolean | {
    resolve: boolean;
    data: D
}

export abstract class MailConstraintHandler<D = any> {
    protected alert = inject(AlertService);
    public abstract check(params: MailParams, options: ConstraintOptions): boolean | Observable<boolean> | Promise<boolean>;
    public abstract resolve(params: MailParams, options: ConstraintOptions): boolean | Observable<boolean> | Promise<boolean>;
    public prompt(params: MailParams, options: ConstraintOptions): ConstraintPromptResponse<D> | Observable<ConstraintPromptResponse<D>> | Promise<ConstraintPromptResponse<D>> {
        return this.alert.confirm({
            title: `mail-constraint.${options.constraint}.title`,
            message: `mail-constraint.${options.constraint}.message`,
            confirmText: 'action.resolve-now',
            cancelText: 'action.cancel-resolve'
        });
    }
}

export type MailingConfig = {
    adapters: {
        [A in MailAction]: {
            handler: Type<MailActionHandler>,
            constraints?: {
                [key: string]: Type<MailConstraintHandler>
            },
        };
    }
}