import { inject } from "@angular/core";
import { ResolveFn } from "@angular/router";
import { UserStore } from "../user.store";
import { User } from "@shared/models";
import { filter, map, take } from "rxjs";
import { isset } from "@cawita/core-front";

export function ResolveLoggedUser(): ResolveFn<User> {
    return (route, state) => {
        const userStore = inject(UserStore);
        return userStore.stableState$.pipe(
            map(state => state.user),
            filter(user => isset(user)),
            take(1)
        );
    }
}