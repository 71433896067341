import { Location } from '@angular/common';
import { Directive, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { isset } from '@hints/utils/data';
import { LocationService } from '../services/location.service';

@Directive({
  selector: '[cwtGoBack]'
})
export class GoBackDirective {

  @Input() defaultUrl: string[] = ['/'];

  constructor(
    private router: Router,
    private location: LocationService
  ) { }

  @HostListener('click', ['$event'])
  public click($event: Event) {
    $event?.stopPropagation();
    $event?.preventDefault();
    this.location.goToPrevious(this.defaultUrl);
  }
}
