import { Directive, Host, Input } from "@angular/core";
import { RouterLink } from "@angular/router";
import { asArray, isset } from "@cawita/core-front";
import { Subscription, distinctUntilChanged, map } from "rxjs";
import { CustomerStore } from "./customer.store";

@Directive({
  selector: '[cwtCustomerRouterLink]',
  hostDirectives: [RouterLink]
})
export class CustomerRouterLinkDirective {
  private subscription?: Subscription;

  constructor(@Host() private _routerLink: RouterLink, private store: CustomerStore) { }

  @Input('cwtCustomerRouterLink')
  set routerLink(commands: any[] | string | null | undefined) {
    this.subscription?.unsubscribe();
    this.subscription = null;
    this._watchCustomerAndUpdateRouterLink(commands)
  }

  private _watchCustomerAndUpdateRouterLink(commands?: string | string[]) {
    this.subscription = this.store.stableState$.pipe(
      distinctUntilChanged((a, b) => a.token === b.token),
      map((s) => s.token)
    ).subscribe(token => {
      this._routerLink.routerLink = this._formatRedirectUrl(token, commands);
    })
  }

  private _formatRedirectUrl(token: string, commands?: string | string[]) {
    if (!isset(token) || !isset(commands)) return null;
    commands = asArray(commands);
    const saneCommands = commands.map(res => {
      const command = String(res);
      if (command.startsWith('/')) return command.replace('/', '');
      return command;
    })
    return ['/', token, ...saneCommands];
  }
}