import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { isset } from '@cawita/core-front';
import { ActiveCustomerDirective } from './active-customer.directive';
import { CustomerRouterLinkDirective } from './customer-router-link.directive';
import { CustomerTokenInterceptor } from './customer-token-interceptor';
import { CUSTOMER_CONFIG, ICustomerConfig } from './customer.config';
import { OpenCustomerSpaceDirective } from './open-customer-space.directive';



@NgModule({
  declarations: [
    ActiveCustomerDirective,
    CustomerRouterLinkDirective,
    OpenCustomerSpaceDirective,
  ],
  exports: [
    ActiveCustomerDirective,
    CustomerRouterLinkDirective,
    OpenCustomerSpaceDirective
  ],
  imports: [
  ]
})
export class SharedCustomerModule {
  static forRoot(config: ICustomerConfig): ModuleWithProviders<SharedCustomerModule> {
    return {
      ngModule: SharedCustomerModule,
      providers: [
        { provide: CUSTOMER_CONFIG, useValue: config },
        config.useTokenInterceptor ? { provide: HTTP_INTERCEPTORS, useClass: CustomerTokenInterceptor, multi: true } : null,
      ].filter(isset)
    }
  }
}
