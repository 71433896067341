const { addYears, addMonths, addDays, subYears, subMonths, subDays } = require('date-fns');
const { format, utcToZonedTime } = require('date-fns-tz');
const { fr: localeFR } = require('date-fns/locale');
const { TemplateConfiguration, getTemplateVariables } = require('./template-utils');
const { isEmptyOrNotSet, isEmpty, isset } = require('@hints/utils/data');
const { isArray, isFunction } = require('@hints/utils/types');

const config = new TemplateConfiguration({
    recurringInvoice: {
        constraints: ['title', 'description'],
        variables: {
            year: { type: 'string', label: 'recurring-variables.year' },
            previousYear: { type: 'string', label: 'recurring-variables.previousYear' },
            nextYear: { type: 'string', label: 'recurring-variables.nextYear' },
            month: { type: 'string', label: 'recurring-variables.month' },
            previousMonth: { type: 'string', label: 'recurring-variables.previousMonth' },
            nextMonth: { type: 'string', label: 'recurring-variables.nextMonth' },
            day: { type: 'string', label: 'recurring-variables.day' },
            previousDay: { type: 'string', label: 'recurring-variables.previousDay' },
            nextDay: { type: 'string', label: 'recurring-variables.nextDay' },
            monthInLetter: { type: 'string', label: 'recurring-variables.monthInLetter' },
            previousMonthInLetter: { type: 'string', label: 'recurring-variables.previousMonthInLetter' },
            nextMonthInLetter: { type: 'string', label: 'recurring-variables.nextMonthInLetter' },
            dayInLetter: { type: 'string', label: 'recurring-variables.dayInLetter' },
            previousDayInLetter: { type: 'string', label: 'recurring-variables.previousDayInLetter' },
            nextDayInLetter: { type: 'string', label: 'recurring-variables.nextDayInLetter' },
        }
    }
}, {
});

const invoiceVarFields = ['title'];
const itemVarFields = ['title', 'description'];
const observationVarFields = ['content'];

function _formatDynamicFieldsFromObj(useCase, variables, obj = {}, varz) { 
    if (isEmptyOrNotSet(variables)) return;
    variables.forEach((v) => {
        if (isEmptyOrNotSet(obj[v])) return;
        const { content } = config.parseContent(useCase, obj[v], varz);
        obj[v] = content;
    });
}
function _getDynamicFieldsFromObj(variables, obj = {}) {
    if (isEmptyOrNotSet(variables)) return;
    return variables.map(v => obj[v]).filter(v => !isEmptyOrNotSet(v));
}

module.exports = {
    config,
    formatRecurringInvoiceSchema: (useCase, invoiceSchema, varz) => {
        _formatDynamicFieldsFromObj(useCase, invoiceVarFields, invoiceSchema, varz);
        for (const item of invoiceSchema.invoiceItems || []) _formatDynamicFieldsFromObj(useCase, itemVarFields, item, varz);
        for (const item of invoiceSchema.observations || []) _formatDynamicFieldsFromObj(useCase, observationVarFields, item, varz);
    },
    parseRecurringInvoiceSchemaVariables: (invoiceSchema) => {
        const valuesToCheck = [..._getDynamicFieldsFromObj(invoiceVarFields, invoiceSchema)];
        for (const item of invoiceSchema.invoiceItems || []) valuesToCheck.push(..._getDynamicFieldsFromObj(itemVarFields, item));
        for (const item of invoiceSchema.observations || []) valuesToCheck.push(..._getDynamicFieldsFromObj(observationVarFields, item));
        return valuesToCheck.reduce((arr, value) => arr.concat(getTemplateVariables(value)), []);
    },
    getRecurringInvoiceVariables: (date = new Date(), fieldWhiteList = null) => {
        const dates = _formatDatesForReplacers(date);
        if (isEmptyOrNotSet(fieldWhiteList) || !isArray(fieldWhiteList)) return _replaceVariables(Object.entries(RecurringVariablesReplacers), dates);
        const replacers = fieldWhiteList.map(field => [field, RecurringVariablesReplacers[field]]);
        return _replaceVariables(replacers, dates);
    }
};

function _formatDatesForReplacers(date) {
    if (!isset(date)) date = new Date();
    date = utcToZonedTime(date, 'Europe/Paris');
    return {
        date,
        previousMonth: utcToZonedTime(subMonths(date, 1), 'Europe/Paris'),
        nextMonth: utcToZonedTime(addMonths(date, 1), 'Europe/Paris'),
        previousDay: utcToZonedTime(subDays(date, 1), 'Europe/Paris'),
        nextDay: utcToZonedTime(addDays(date, 1), 'Europe/Paris'),
    };
}

/**
 * @type Record<string, (dates: { date: Date, previousMonth: Date, nextMonth: Date, previousDay: Date, nextDay: Date, }) => string>
 */
const RecurringVariablesReplacers = {
    year: ({ date }) => format(date, 'yyyy'),
    previousYear: ({ date }) => format(subYears(date, 1), 'yyyy', { locale: localeFR }),
    nextYear: ({ date }) => format(addYears(date, 1), 'yyyy', { locale: localeFR }),
    month: ({ date }) => format(date, 'MM/yyyy'),
    previousMonth: ({ previousMonth }) => format(previousMonth, 'MM/yyyy', { locale: localeFR }),
    nextMonth: ({ nextMonth }) => format(nextMonth, 'MM/yyyy', { locale: localeFR }),
    day: ({ date }) => format(date, 'dd/MM/yyyy', { locale: localeFR }),
    previousDay: ({ previousDay }) => format(previousDay, 'dd/MM/yyyy', { locale: localeFR }),
    nextDay: ({ nextDay }) => format(nextDay, 'dd/MM/yyyy', { locale: localeFR }),
    monthInLetter: ({ date }) => format(date, 'MMMM yyyy', { locale: localeFR }),
    previousMonthInLetter: ({ previousMonth }) => format(previousMonth, 'MMMM yyyy', { locale: localeFR }),
    nextMonthInLetter: ({ nextMonth }) => format(nextMonth, 'MMMM yyyy', { locale: localeFR }),
    dayInLetter: ({ date }) => format(date, 'd MMMM yyyy', { locale: localeFR }),
    previousDayInLetter: ({ previousDay }) => format(previousDay, 'd MMMM yyyy', { locale: localeFR }),
    nextDayInLetter: ({ nextDay }) => format(nextDay, 'd MMMM yyyy', { locale: localeFR }),
};

function _replaceVariables(generatorsArray, dates) {
    return generatorsArray.reduce((variables, [field, replacer]) => {
        if (isEmpty(field) || !isset(replacer) || !isFunction(replacer)) return;
        variables[field] = replacer(dates);
        return variables;
    }, {});
}