import { Directive, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { hasSomeChanges } from '@cawita/core-front';

@Directive({
  selector: '[cwtHighlightDiff]',
})
export class HighlightDiffDirective implements OnChanges {
  @HostBinding('class') public get class(): string {
    if (this.highlightPositive) return this.positiveClass;
    if (this.highlightNegative) return this.negativeClass;
    return this.neutralClass;
  }


  @Input('cwtHighlightDiff') diff: number;
  @Input() threshold: number;
  @Input() positiveClass = 'text-success';
  @Input() negativeClass = 'text-danger';
  @Input() neutralClass = '';

  public highlightNegative = true;
  public highlightPositive = true;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (hasSomeChanges(changes, ['diff', 'threshold'], true)) {
      const absDiff = Math.abs(this.diff ?? 0);
      const absThreshold = Math.abs(this.threshold);

      this.highlightNegative = false;
      this.highlightPositive = false;
      if (absDiff > absThreshold) {
        if (Math.sign(this.diff) >= 0) this.highlightPositive = true;
        else this.highlightNegative = true;
      }
    }
  }

}
