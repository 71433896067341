import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import '@shared/config/mingo.config';
import { provideNgxMask } from 'ngx-mask';

if (environment.production) enableProdMode();

platformBrowserDynamic().bootstrapModule(AppModule, {
  providers: [provideNgxMask()]
}).catch(err => console.error(err));
