import { Directive, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { CwtDirectiveView, hasSomeChanges, isset } from '@cawita/core-front';
import { Quote } from '@shared/models';

@Directive({ selector: '[cwtHasQuoteRecurringInvoice]' })
export class HasQuoteRecurringInvoiceDirective implements OnChanges {
    private _view = new CwtDirectiveView(this.vcRef, this.tRef);
    private _elseView = new CwtDirectiveView(this.vcRef, null);

    @Input('cwtHasQuoteRecurringInvoice') quote: Quote;
    @Input('cwtHasQuoteRecurringInvoiceElse') elseTemplate: TemplateRef<void>;

    constructor(
        private vcRef: ViewContainerRef,
        private tRef: TemplateRef<void>
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (hasSomeChanges(changes, ['elseTemplate'], true)) this._elseView.setTemplate(this.elseTemplate);
        if (hasSomeChanges(changes, ['quote'], true)) {
            const hasQuoteRecurringInvoice = isset(this.quote?.recurringInvoice);
            this._view.ensureState(hasQuoteRecurringInvoice);
            this._elseView.ensureState(!hasQuoteRecurringInvoice);
        }
    }
}