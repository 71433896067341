import { CwtValueAccessor, findValueInObject, isset } from "@cawita/core-front";
import { isEmpty } from "@hints/utils/data";

export type ArrayElement<T> = T extends Array<infer I> ? I : never;

export function replaceOrInsert<T>(arr: T[], item: T, comparator: (a: T, b: T) => boolean = (a, b) => a === b): T[] {
    if (!isset(arr) || isEmpty(arr)) return [item];
    const index = arr.findIndex((value) => comparator(value, item));
    if (index < 0) return [...arr, item];
    const newArray = [...arr];
    newArray[index] = item;
    return newArray;
}

export function replaceInlineItem<T>(arr: T[], item: T, comparator: (a: T, b: T) => boolean = (a, b) => a === b): void {
    const index = arr.findIndex((value) => comparator(value, item));
    if (index < 0) return;
    arr[index] = item;
}

export function removeFromArray<T>(arr: T[], item: T, comparator: (a: T, b: T) => boolean = (a, b) => a === b): T[] {
    return arr.filter((value) => !comparator(value, item));
}

export function sortAsc<T>(arr: T[], path: CwtValueAccessor<T, number>) {
    if (!isset(arr) || isEmpty(arr)) return [];
    return arr.sort((a, b) => (findValueInObject(a, path) || 0) - (findValueInObject(b, path) || 0));
}


export function getNewItemOrder<T>(
    items: T[],
    fromIndex: number,
    toIndex: number,
    orderAccessor: CwtValueAccessor<T, number>,
    step: number = 1
): number {
    const isGoingUp = fromIndex > toIndex;
    const maxIndex = items.length - 1;
    fromIndex = Math.min(Math.max(fromIndex, 0), maxIndex);
    toIndex = Math.min(Math.max(toIndex, 0), maxIndex);
    const order = (i: T) => findValueInObject(i, orderAccessor);
    if (isGoingUp) {
        const itemAfter = items[toIndex];
        if (toIndex <= 0) return order(itemAfter) - step;
        const itemBefore = items[toIndex - 1];
        return (order(itemAfter) + order(itemBefore)) / 2;
    } else {
        const itemBefore = items[toIndex];
        if (toIndex >= maxIndex) return order(itemBefore) + step;
        const itemAfter = items[toIndex + 1];
        return (order(itemAfter) + order(itemBefore)) / 2;
    }
}