import { Directive, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { CwtDirectiveView, hasSomeChanges } from '@cawita/core-front';
import { Quote, QuoteStatus } from '@shared/models';

@Directive({ selector: '[cwtIfQuoteInvoiceActive]' })
export class IfQuoteInvoiceActiveDirective implements OnChanges {
  private _view = new CwtDirectiveView(this.vcRef, this.tRef);
  @Input('cwtIfQuoteInvoiceActive') quote: Quote;

  constructor(
    private vcRef: ViewContainerRef,
    private tRef: TemplateRef<void>
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (hasSomeChanges(changes, ['quote'], true)) {
      const hasStatuses = [QuoteStatus.Finalized, QuoteStatus.Validated, QuoteStatus.Declined].includes(this.quote?.status);
      this._view.ensureState(hasStatuses);
    }
  }
}