import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp, CwtSubCrudContract } from "@cawita/core-front/api";
import { User } from "../common";
import { Company } from "./company.model";
import { Invitation } from "./invitation.model";

@CwtModel({ name: 'company-access' })
@CwtSubCrudContract({ parent: User, parentPath: 'user', subPath: 'access' })
@CwtCrudContract({ path: 'company/:companyId/access' })
export class CompanyAccess extends CwtApiEntity {
    @CwtProp({ ref: Company }) company: Company;
    @CwtProp() isOwner: boolean;
    @CwtProp() permissions: string[];
    @CwtProp({ ref: User }) user: User;
    @CwtProp({ ref: Invitation }) invitation: Invitation;
}