export enum CustomFieldType {
    Number = 'number',
    String = 'string',
    Enum = 'enum',
    RichText = 'richText',
    LongText = 'longText'
};

export const CustomFieldFilterTypes = [
    CustomFieldType.Number,
    CustomFieldType.String,
    CustomFieldType.Enum,
];