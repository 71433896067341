import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CrmRouterLinkDirective } from './directives';
import { HighlightLateActionDirective } from './directives/highlight-late-action.directive';



@NgModule({
  declarations: [
    CrmRouterLinkDirective
  ],
  exports: [
    CrmRouterLinkDirective,
    HighlightLateActionDirective
  ],
  imports: [
    CommonModule,
    HighlightLateActionDirective
  ]
})
export class SharedCrmModule { }
