<nz-select [nzBorderless]="nzBorderless" nzSelectOnTab
    [ngModel]="value"
    [nzDisabled]="disabled"
    [compareWith]="CompareIds"
    (ngModelChange)="onTemplateChange($event)"
    [nzPlaceHolder]="placeholder">
    <nz-option *ngFor="let template of templates$ | async"
        [nzValue]="template"
        [nzLabel]="template.name || ('template-use-case.' + template.useCase | translate)">
    </nz-option>
</nz-select>