const { isset } = require('@hints/utils/data');
const { isNumber, isString } = require('@hints/utils/types');
const { round_to_decimal_place, parse_formatted_number } = require('./intl-utils');

/**
 * Decimal constant to round values
 */
const DECIMAL_PLACES = 2;

/**
 * Rounds a number value
 * @param {number | null | undefined} value value to round
 * @return {number} rounded value
 */
function round_number(value) {
    if (!isset(value)) return 0;
    if (!isNumber(value)) return 0;
    return round_to_decimal_place(value, DECIMAL_PLACES);
}

/**
 * Formats a number value
 * @param {number | null | undefined} value value to format
 * @param {string} locale locale in which to format the value (defaults to fr-FR)
 * @param {string} number number in which to format the value (defaults to EUR)
 * @return {string} formatted number
 */
function format_number(value, locale = 'fr-FR') {
    const formatter = create_number_formatter(locale);
    return formatter.format(round_number(value));
}

function parse_number(value, locale = 'fr-FR') {
    if (!isset(value)) return undefined;
    if (isNumber(value)) return value;
    if (!isString(value)) return undefined;
    const formatter = create_number_formatter(locale);
    const parsed = parse_formatted_number(formatter, value, 1111.1111, {
        group: '',
        number: '',
        minusSign: '-',
        plusSign: '',
        unit: '',
        symbol: '',
        percentSign: '',
        decimal: '.'
    });

    return round_number(parsed);
}

module.exports = {
    round: round_number,
    format: format_number,
    parse: parse_number
};

function create_number_formatter(locale = 'fr-FR') {
    return new Intl.NumberFormat(locale, {
        maximumFractionDigits: DECIMAL_PLACES,
        minimumFractionDigits: DECIMAL_PLACES,
        minimumIntegerDigits: 1,
    });
}