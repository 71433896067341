import { CwtApiEntity, CwtApiSchema, CwtModel, CwtProp, CwtSchema } from "@cawita/core-front/api";
import { Company } from "./company.model";

export enum CompanyConfigType {
    Default = 'default',
    System = 'system',
    Admin = 'admin'
};
export enum CompanyConfigSubType {
    Default = 'default',
    System = 'system',
    Free = 'free',
    Stripe = 'stripe',
    Apple = 'apple',
    Google = 'google'
};

export type BestConfigValue<T> = { value: T, from: CompanyConfigSubType };

@CwtSchema()
export class CompayConfigFeatures<BoolType> extends CwtApiSchema {
    @CwtProp() isSubscribed: BoolType;
    @CwtProp() hasAppAccess: BoolType;
    @CwtProp() hasCrmAccess: BoolType;
    @CwtProp() hasReportingAccess: BoolType;
    @CwtProp() hasAccountingAccess: boolean;
}

@CwtSchema()
export class CompanyConfigData extends CompayConfigFeatures<boolean> {
    @CwtProp() type: CompanyConfigType;
    @CwtProp() subType: CompanyConfigSubType;
}

@CwtModel({ name: 'company-config' })
export class CompanyConfig extends CwtApiEntity {
    @CwtProp({ ref: Company }) company: Company;
    @CwtProp({ ref: CompanyConfigData }) config: CompanyConfigData;
}

@CwtSchema()
export class BestCompanyConfig extends CompayConfigFeatures<BestConfigValue<boolean>> { }