import { Directive, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { CwtDirectiveView, hasSomeChanges } from '@cawita/core-front';
import { Invoice } from '@shared/models';

@Directive({ selector: '[cwtHasInvoicePayments]' })
export class HasInvoicePaymentDirective implements OnChanges {
    private _view = new CwtDirectiveView(this.vcRef, this.tRef);
    @Input('cwtHasInvoicePayments') invoice: Invoice;

    constructor(
        private vcRef: ViewContainerRef,
        private tRef: TemplateRef<void>
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (hasSomeChanges(changes, ['invoice'], true)) {
            const hasInvoiceInvoices = (this.invoice?.totalPaid ?? 0) > 0;
            this._view.ensureState(hasInvoiceInvoices);
        }
    }
}