// @index(['./!(*.spec).ts', './*/index.ts'], f => `export * from '${f.path.replace(/\/index$/, '')}';`)
export * from './analytics';
export * from './banking';
export * from './billing';
export * from './bookmark';
export * from './common';
export * from './company-product';
export * from './company';
export * from './contract.model';
export * from './crm';
export * from './custom-field';
export * from './customer';
export * from './machine-move.model';
export * from './product.model';
export * from './rental.model';
export * from './reporting';
// @endindex