import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp } from "@cawita/core-front/api";
import { Address } from "../common";
import { Company } from "../company";

@CwtModel({ name: 'supplier' })
@CwtCrudContract({ path: 'company/:companyId/supplier' })
export class Supplier extends CwtApiEntity {
    @CwtProp({ ref: Company }) company: Company;
    @CwtProp() name: string;
    @CwtProp() technicalNames: string[];
    @CwtProp({ ref: Address }) address: Address;

}