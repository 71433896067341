import { Pipe, PipeTransform } from '@angular/core';
import { QuoteItem } from '@shared/models';
import { Accounting } from '@server-shared';
import { asArray } from '@cawita/core-front';

@Pipe({
  name: 'billingItemTotal'
})
export class BillingItemTotalPipe implements PipeTransform {

  transform(item: QuoteItem | QuoteItem[]): { amount: number, vatAmount: number } {
    return Accounting.getTotalsForBillingItems(asArray(item));
  }

}
