import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'cwt-media-text-addon',
  template: `
    <span>{{text}}</span>
  `,
  styles: [`
    :host {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      user-select: none;
      transition: inherit;
      font-size: calc(var(--media-width, var(--media-height)) * 0.4);
    }
    span { transition: inherit; }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaTextAddonComponent implements OnInit {
  @Input() text: string;

  constructor() { }

  ngOnInit(): void {
  }

}
