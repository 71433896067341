import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { CwtCoreModule } from '@cawita/core-front';
import { CwtZorroModule } from '@cawita/core-front/zorro';
import { EChartTheme } from '@shared/config/chart.config';
import { CustomFieldsConfig } from '@shared/config/custom-fields.config';
import { CwtMailingConfig } from '@shared/config/mailing.config';
import { QuillConfig } from '@shared/config/quill.config';
import { SharedCommonModule } from '@shared/modules';
import { SharedCompanyModule } from '@shared/modules/company';
import { SharedCustomFieldsModule } from '@shared/modules/custom-fields';
import { SharedMailingModule } from '@shared/modules/mailing';
import { SharedReportingModule } from '@shared/modules/reporting/reporting.module';
import { SharedUserModule } from '@shared/modules/user';
import { CompanyRouteReuseStrategy } from '@shared/services/utils/company-route-reuse.strategy';
import { RecaptchaModule } from '@shared/widgets/features/common/recaptcha';
import { NgxEchartsModule } from 'ngx-echarts';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { QuillModule } from 'ngx-quill';
import { SharedCustomerModule } from '../../../frontend-shared/src/lib/modules/customer';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutes } from './app.routes';
import { UserCoreConfig } from './configs/core-front.config';
import { UserCustomerConfig } from './configs/customer.config';
import { UserZorroConfig } from './configs/ng-zorro.config';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedCommonModule.forRoot(),
    SharedUserModule.forRoot(),
    SharedCompanyModule.forRoot(),
    SharedCustomerModule.forRoot(UserCustomerConfig),
    SharedReportingModule.forRoot(),
    SharedMailingModule.forRoot(CwtMailingConfig),
    CwtCoreModule.forRoot(UserCoreConfig),
    QuillModule.forRoot(QuillConfig),
    CwtZorroModule.forRoot(UserZorroConfig),
    RecaptchaModule.forRoot(environment.recaptchaSiteKey),
    SharedCustomFieldsModule.forRoot(CustomFieldsConfig),
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts').then(e => {
        e.registerTheme('walden', EChartTheme);
        return e;
      })
    }),
    RouterModule.forRoot(AppRoutes, {
      paramsInheritanceStrategy: 'always',
      onSameUrlNavigation: 'reload',
    })
  ],
  providers: [
    provideEnvironmentNgxMask(),
    { provide: RouteReuseStrategy, useClass: CompanyRouteReuseStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
