
// status: { type: String, enum: actionConfig.actionStatuses, default: 'planned' },


import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp } from "@cawita/core-front/api";

@CwtModel({ name: 'action-type' })
@CwtCrudContract({ path: 'company/:companyId/action-type', searchMapper: (s) => ({ 'name': { regexp: s } }), })
export class ActionType extends CwtApiEntity {
    @CwtProp() name: string;
}