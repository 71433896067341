const { isset } = require('@hints/utils/data');

/**
 * Rounds value to decimal place
 * @param {number | null | undefined} value 
 * @param {number} place nth place to round value to
 * @returns rounded value to nth place
 */
function round_to_decimal_place(value, place) {
    if (!isset(value)) return undefined;
    const factor = Math.pow(10, place);
    const toPlaces = value * factor;
    const rounded = Math.round(toPlaces);
    return rounded / factor;
}

/**
 * Replaces number parts from value using formatter
 * @param {Intl.NumberFormat} formatter 
 * @param {string} formattedValue
 * @param {number} refValue
 * @param {Partial<Record<Intl.NumberFormatPartTypes, string>} replacers
 * @return {number} parsed number
 */
function parse_formatted_number(formatter, formattedValue, refValue, replacers) {
    const parts = formatter.formatToParts(refValue);
    let formatted = formattedValue;
    parts.forEach((part, i) => {
        const replacer = replacers[part.type];
        if (!isset(replacer)) return;
        formatted = formatted.replace(new RegExp(`\\${part.value}`, 'g'), replacer);
    });
    return parseFloat(formatted);
}

module.exports = {
    round_to_decimal_place,
    parse_formatted_number
};