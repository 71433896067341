import { HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CwtApiFlags, CwtApiService } from '@cawita/core-front/api';
import { Company, Customer } from '@shared/models';
import { Observable, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ForCustomerApi {
    constructor(private api: CwtApiService) { }

    public getCompany(token: string): Observable<Company> {
        return this.api.get(`for-customer/${token}/company`, {}, {
            context: new HttpContext().set(CwtApiFlags.UseErrorHandler, false)
        }).pipe(
            map(res => new Company(res))
        );
    }

    public getCustomer(token: string): Observable<Customer> {
        return this.api.get(`for-customer/${token}/customer`, {}, {
            context: new HttpContext().set(CwtApiFlags.UseErrorHandler, false)
        }).pipe(
            map(res => new Customer(res))
        );
    }
}