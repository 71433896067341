const { isset } = require('@hints/utils/data');
const { getInt8AsDate, getDateAsInt8 } = require('@hints/utils/date');
const { isAfter, isBefore, isSameDay, addMinutes } = require('date-fns');

function _actualRecurringMinDate(recurringObj) {
    if (isset(recurringObj.lastRanDate)) return toUTCTime(getInt8AsDate(getDateAsInt8(new Date(recurringObj.lastRanDate))));
    return null;
}

function _actualRecurringMaxDate(recurringObj) {
    if (isset(recurringObj.endDate)) return toUTCTime(getInt8AsDate(recurringObj.endDate));
    return null;
}


function getRecurringStartDate(recurringObj) {
    if (isset(recurringObj.startDate)) return toUTCTime(getInt8AsDate(recurringObj.startDate));
    return toUTCTime(new Date());

}
function getRecurringEndDate(recurringObj) {
    if (isset(recurringObj.endDate)) return toUTCTime(getInt8AsDate(recurringObj.endDate));
    return null;
}

function getRecurringMinDate(recurringObj, limit = null) {
    const min = _actualRecurringMinDate(recurringObj);
    if (isset(limit) && (!isset(min) || isBefore(min, limit))) return toUTCTime(limit);
    return min;
}

function getRecurringMaxDate(recurringObj, limit = null) {
    const max = _actualRecurringMaxDate(recurringObj);
    if (isset(limit) && (!isset(max) || isAfter(max, limit))) return toUTCTime(limit);
    return max;
}

function isGenerationDateBetween(date, startDate, endDate, minDate, maxDate) {
    return isGenerationDateAfterStart(date, startDate, minDate) && isGenerationDateBeforeEnd(date, endDate, maxDate);
}

function isGenerationDateAfterStart(date, startDate, minDate) {
    if (!isset(date)) return false;
    if (isset(startDate) && !isSameDay(date, startDate) && !isAfter(date, startDate)) return false;
    if (isset(minDate) && !isSameDay(date, minDate) && !isAfter(date, minDate)) return false;
    return true;
}

function isGenerationDateBeforeEnd(date, endDate, maxDate) {
    if (!isset(date)) return false;
    if (isset(endDate) && !isSameDay(date, endDate) && !isBefore(date, endDate)) return false;
    if (isset(maxDate) && !isSameDay(date, maxDate) && !isBefore(date, maxDate)) return false;
    return true;
}

/**
 * 
 * @param {Date} date 
 * @returns Date
 */
function toUTCTime(date) {
    if (!isset(date)) return null;
    return addMinutes(date, date.getTimezoneOffset());
}

module.exports = {
    getRecurringMinDate,
    getRecurringMaxDate,
    getRecurringStartDate,
    getRecurringEndDate,
    isGenerationDateBetween,
    isGenerationDateAfterStart,
    isGenerationDateBeforeEnd,
    toUTCTime,
};