import { Injectable } from '@angular/core';
import { CwtPercentService } from '@cawita/core-front';
import { isNumber, isString } from '@hints/utils/types';
import { Formatting } from '@server-shared';

@Injectable()
export class CustomPercentService extends CwtPercentService {

    format(value: string | number, digitsInfo?: string): string;
    format(value: null, digitsInfo?: string): null;
    format(value: any, digitsInfo: any = '1.2-2'): string {
        if (isNumber(value)) value = Formatting.percent.round(value);
        if (isString(value)) value = Formatting.percent.round(Number(value));
        return super.format(value, digitsInfo);
    }

}