import { CwtApiSchema, CwtCrudContract, CwtModel, CwtProp, CwtSchema, CwtSubCrudContract } from "@cawita/core-front/api";
import { InvoiceStatus, QuoteStatus } from "../billing";
import { UploadFile } from "../common";
import { Company } from "../company/company.model";
import { CompanyProductAccessory } from "./company-product-accessory.model";
import { CompanyProductType, CompanyProductUnit } from "./company-product-type.model";
import type { CustomerNegoPrice } from "./customer-nego-price.model";
import { Customer } from "../customer";
import { isDevMode } from "@angular/core";
import { isset } from "@cawita/core-front";
import { throwErrorIfFieldNotSet } from "@shared/utils/error.utils";

export function CompanyProductSearchMapper(search: string) { return { name: { regexp: search } }; }

export class _CompanyProductStatProxy {
    constructor(values: any) {
        return Object.keys(values).reduce((map, status) => {
            map[status] = new CompanyProductStatEntry(values[status]);
            return map;
        }, {});
    }
}

@CwtSchema()
export class CompanyProductStatEntry extends CwtApiSchema {
    nb: number;
    totalPrice: number;
    totalCost: number;
}

export type CompanyProductStats<T extends string | number> = { [key in T]: CompanyProductStatEntry; }

@CwtSchema()
export class Accessory extends CwtApiSchema {
    @CwtProp({ ref: CompanyProductAccessory }) accessory: CompanyProductAccessory;
    @CwtProp() qty: number;
    @CwtProp() isVisibleInContract: boolean;
    @CwtProp() isVisibleInInvoice: boolean;
}

@CwtModel({ name: 'company-product' })
@CwtCrudContract({ path: 'company/:companyId/company-product', searchMapper: CompanyProductSearchMapper, })
@CwtSubCrudContract({ parent: Customer, parentPath: 'company/:companyId/customer', subPath: 'company-product', searchMapper: CompanyProductSearchMapper })
export class CompanyProduct extends CwtApiSchema {
    @CwtProp({ ref: Company }) company: Company;
    @CwtProp() name: string;
    @CwtProp() isActive: boolean;
    @CwtProp() unit: CompanyProductUnit;
    @CwtProp({ ref: _CompanyProductStatProxy }) quoteStat: CompanyProductStats<QuoteStatus>;
    @CwtProp({ ref: _CompanyProductStatProxy }) invoiceStat: CompanyProductStats<InvoiceStatus>;
    @CwtProp({ ref: CompanyProductType })productType: CompanyProductType;
    @CwtProp({ ref: UploadFile}) picture: UploadFile;
    @CwtProp() width: number;
    @CwtProp() length: number;
    @CwtProp() surface: number;
    @CwtProp() stock: number;
    @CwtProp() totalRented: number;
    @CwtProp() totalAvailable: number
    @CwtProp({ ref: Accessory }) accessories: Accessory[];
    @CwtProp() unitPrice: number;

    @CwtProp({ ref: 'customer-nego-price' }) negoPrice?: CustomerNegoPrice;

    public get size(): number {
        if(this.unit === CompanyProductUnit.m2) return this.surface ?? 0;
        if(this.unit === CompanyProductUnit.ml) return this.length ?? 0;
        return 1;
    }

    public get hasUnitPrice(): boolean { return this.unit !== CompanyProductUnit.unit; }
    public get effectiveUnitPrice(): number { return this.negoPrice?.unitPrice ?? this.unitPrice ?? 0; }
}