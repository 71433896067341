import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp } from "@cawita/core-front/api";
import { RecurringInvoice } from "./billing";
import { Company } from "./company";
import { Customer, CustomerSite } from "./customer";
import { MachineMove } from "./machine-move.model";
import { Product } from "./product.model";

@CwtModel({ name: 'rental' })
@CwtCrudContract({ path: 'company/:companyId/rental' })
export class Rental extends CwtApiEntity {
    @CwtProp({ ref: Company }) company: Company;
    @CwtProp({ ref: Customer }) customer: Customer;
    @CwtProp({ ref: CustomerSite }) site: CustomerSite;
    @CwtProp({ ref: RecurringInvoice }) recurringInvoice: RecurringInvoice;
    @CwtProp({ ref: MachineMove }) machineMove: MachineMove;
    @CwtProp() startDate: number;
    @CwtProp() endDate: number;
    @CwtProp({ ref: Product }) products: Product[]
}