import { Accounting } from "@server-shared";
import { Invoice, InvoiceItem, Payment } from "@shared/models";

export function getInvoiceTotalPaidAfterPaymentCreated(invoice: Invoice, payment: Payment): number {
    const totalPaid = invoice.totalPaid || 0;
    const amountAdded = payment.amount || 0;
    return Accounting.sumAmounts(totalPaid, amountAdded);
}
export function getInvoiceTotalPaidAfterPaymentUpdated(invoice: Invoice, paymentBefore: Payment, paymentAfter: Payment): number {
    const totalPaid = invoice.totalPaid || 0;
    const amountBefore = paymentBefore.amount || 0;
    const amountAfter = paymentAfter.amount || 0;
    return Accounting.sumAmounts(totalPaid, -amountBefore, amountAfter);
}
export function getInvoiceTotalPaidAfterPaymentDeleted(invoice: Invoice, paymentBefore: Payment) {
    const totalPaid = invoice.totalPaid || 0;
    const amountBefore = paymentBefore.amount || 0;
    return Accounting.sumAmounts(totalPaid, -amountBefore);
}

export function getUpdatedInvoiceOnInvoiceItemUpdate(invoice: Invoice, itemBefore: InvoiceItem, itemAfter: InvoiceItem) {
    invoice = new Invoice(invoice);
    removeInvoiceItemValue(invoice, itemBefore);
    addInvoiceItemValue(invoice, itemAfter);
    return invoice;
}

export function getUpdatedInvoiceOnInvoiceItemCreated(invoice: Invoice, itemAfter: InvoiceItem) {
    invoice = new Invoice(invoice);
    addInvoiceItemValue(invoice, itemAfter);
    return invoice;
}

export function getUpdatedInvoiceOnInvoiceItemDeleted(invoice: Invoice, itemBefore: InvoiceItem) {
    invoice = new Invoice(invoice);
    removeInvoiceItemValue(invoice, itemBefore);
    return invoice;
}


function removeInvoiceItemValue(invoice: Invoice, invoiceItem: InvoiceItem) {
    const { amount, vatAmount } = Accounting.getTotalsForBillingItem(invoiceItem);
    invoice.amount = (invoice.amount ?? 0) - (amount ?? 0);
    invoice.vatAmount = (invoice.vatAmount ?? 0) - (vatAmount ?? 0);
}

function addInvoiceItemValue(invoice: Invoice, invoiceItem: InvoiceItem) {
    const { amount, vatAmount } = Accounting.getTotalsForBillingItem(invoiceItem);
    invoice.amount = (invoice.amount ?? 0) + (amount ?? 0);
    invoice.vatAmount = (invoice.vatAmount ?? 0) + (vatAmount ?? 0);
}